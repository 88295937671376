import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import { useAppSelector } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_FILTERS_CHANGED_STATE } from "redux/actions/actionTypes";
import { OptionType } from "types/expenses/Expense";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type StatusFilterProps = {
  expenseStatus: OptionType[];
};

const StatusFilter = ({ expenseStatus }: StatusFilterProps) => {
  const dispatch = useDispatch();
  const expensesFilters = useAppSelector((state: RootState) => state.expensesState);
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    dispatch({
      type: EXPENSE_FILTERS_CHANGED_STATE,
      states: typeof value === "string" ? value.split(",") : value,
    });
  };

  return (
    <FormControl sx={{ mr: 1, width: 250 }} size="small">
      <InputLabel id="status-select-label">Status</InputLabel>
      <Select
        labelId="status-select-label"
        id="status-select"
        multiple
        value={expensesFilters.states ?? []}
        label="Status"
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}>
        {expenseStatus?.map((item) => (
          <MenuItem value={item.name} key={item.id}>
            <Checkbox checked={expensesFilters.states.indexOf(item.name) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
