import React from "react";
import PropTypes from "prop-types";
import FileUpdateAndDownload from "../shared/components/FileUpdateAndDownload";

function TimecardFile(props) {
  let downloadUrl = null;

  if (props.timecardUploadId) {
    downloadUrl = `${process.env.REACT_APP_API_URL}/timesheet/timecard/${props.timecardUploadId}`;
  }

  return (
    <FileUpdateAndDownload
      downloadUrl={downloadUrl}
      noFileMessage="No timecard file has been uploaded"
      fileName="Timecard File"
      icon="description"
      {...props}
    />
  );
}

TimecardFile.propTypes = {
  timecardUploadId: PropTypes.string,
};

export default TimecardFile;
