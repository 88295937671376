import React, { Fragment } from "react";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";

function Note({ note }) {
  const m = moment.utc(note.createdDateUtc).local().format("MM/DD/YY h:mm:ss a");

  return (
    <div className="note-container">
      <ListItemText
        primary={note.contents}
        secondary={
          <Fragment>
            {note.createdByFirstName} {note.createdByLastName}
            {" - "}
            <span>{m}</span>
          </Fragment>
        }
      />
    </div>
  );
}

export default Note;
