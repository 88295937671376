import { FeatureFlag } from "types/featureFlag";
import { FEATURE_FLAGS_CHANGED } from "../actions/actionTypes";

type FeatureFlagsAction = {
  type: string;
  featuresFlags: FeatureFlag[];
};

const initialState: FeatureFlag[]|null = null;

export default function featureFlagsReducer(state = initialState, action: FeatureFlagsAction): FeatureFlag[]|null {
  switch (action.type) {
    case FEATURE_FLAGS_CHANGED:
      return action.featuresFlags;
    default:
      return state;
  }
}
