import React from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import BillingHours from "./BillingHours";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoteTypeResolver from "../notes/NoteTypeResolver";
import TimecardFile from "../timecards/TimecardFile";
import { TimesheetFileTypes } from "../../commons/Enums";
import BillingHoursReadonly from "./BillingHoursReadonly";
import TimesheetApi from "../../api/TimesheetApi";
import TimecardNotes from "components/notes/TimecardNotes";

const BillingEntry = (props) => {
  const {
    jobAssignment,
    onBillingEntriesChange,
    onTimecardFileUploaded,
    onNotesRetrieved,
    onNoteChange,
    onNotifyTravelerChange,
    readOnly,
  } = props;

  const billingHours = readOnly ? (
    <BillingHoursReadonly
      rows={jobAssignment.billingEntries}
      isFirstWeek={jobAssignment.isFirstWeek}
    />
  ) : (
    <BillingHours
      onBillingEntriesChange={(entries) => onBillingEntriesChange(entries)}
      rows={jobAssignment.billingEntries}
      isFirstWeek={jobAssignment.isFirstWeek}
    />
  );

  const getNotes = () => {
    if (!jobAssignment.notes) {
      TimesheetApi.getNotesByAssigment(jobAssignment.id).then((data) => {
        onNotesRetrieved(data);
      });
    }
    return jobAssignment.notes;
  };

  return (
    <div className="timecard-timeentry">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Billing</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: "column" }}>{billingHours}</AccordionDetails>
      </Accordion>

      <TimecardFile
        timesheetId={jobAssignment.id}
        timecardUploadId={jobAssignment.timecardUploadId}
        onFileUploaded={onTimecardFileUploaded}
        uploading={jobAssignment.uploading & TimesheetFileTypes.TIMECARD}
        readonly={readOnly}
        isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
      />

      <TimecardNotes
        notes={getNotes()}
        note={jobAssignment.note}
        noteNotifyTraveler={jobAssignment.notifyTraveler}
        onNoteChange={onNoteChange}
        onNotifyTravelerChange={onNotifyTravelerChange}
        noteTypeFilter={NoteTypeResolver.getNoteTypeFilterFromStatus(jobAssignment.status)}
      />
    </div>
  );
};

BillingEntry.propTypes = {
  jobAssignment: PropTypes.object.isRequired,
  onBillingEntriesChange: PropTypes.func.isRequired,
  onTimecardFileUploaded: PropTypes.func.isRequired,
  onNotesRetrieved: PropTypes.func.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onNotifyTravelerChange: PropTypes.func.isRequired,
};

export default BillingEntry;
