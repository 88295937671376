import React from "react";
import { Box, Button, Typography } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/CheckCircle";
import TimecardNotes from "components/notes/TimecardNotes";
import NoteTypeResolver from "components/notes/NoteTypeResolver";
import { usePermissions } from "hooks";

type TimecardDetailContentEditRequestedProps = {
    jobAssignment: any;
    onEditRequest: (approved: boolean) => void;
    onNoteChange: () => any;
    onNotifyTravelerChange: () => any;
    };

const TimecardDetailContentEditRequested = ({ jobAssignment, onEditRequest, onNoteChange, onNotifyTravelerChange }: TimecardDetailContentEditRequestedProps) => {
  const { canApproveDeclineEditRequest } = usePermissions();

  return (
    <>
      {
        canApproveDeclineEditRequest ? 
          (
            <>
              <Typography paragraph style={{ marginTop: 10 }}>An edit request has been made on this timecard.</Typography>
              <Typography paragraph>APPROVE to send timecard back to draft or DECLINE the edit request and continue with the process</Typography>
              <Box width={"80%"} mx={"auto"}>
                <TimecardNotes
                  showNotes={false}
                  note={jobAssignment.note}
                  noteNotifyTraveler={jobAssignment.notifyTraveler}
                  onNoteChange={onNoteChange}
                  onNotifyTravelerChange={onNotifyTravelerChange}
                  noteTypeFilter={NoteTypeResolver.getNoteTypeFilterFromStatus(jobAssignment.status)}
                />
              </Box>
  
              <Box pb={2}>
                <Button
                  disabled={jobAssignment.saving || jobAssignment.savingNote || false}
                  onClick={() => onEditRequest(false)}
                  color="primary"
                  size="small"
                  className={"timecard-detail-bottombar-button"}>
                  <BlockIcon className={"timecard-detail-bottombar-icon"} />
                  Decline
                </Button>
                <Button
                  disabled={jobAssignment.saving || jobAssignment.savingNote || false}
                  onClick={() => onEditRequest(true)}
                  color="primary"
                  size="small"
                  className={"timecard-detail-bottombar-button"}>
                  <CheckIcon className={"timecard-detail-bottombar-icon"} />
                  Approve
                </Button>
              </Box>
            </>
          )
          : 
          (<Typography paragraph style={{ marginTop: 10 }}>An edit request has been made on this timecard. An Administrator user must approve or decline the request to unlock the timecard.</Typography>)
      }
    </>
  );
};

export default TimecardDetailContentEditRequested;