export const HISTORY_TABLE_CHANGED = "HISTORY_TABLE_CHANGED";
export const FEATURE_FLAGS_CHANGED = "FEATURE_FLAGS_CHANGED";
export const PERMISSIONS_CHANGED = "PERMISSIONS_CHANGED";
export const MISSED_SHIFT_CALCULATION_MADE = "MISSED_SHIFT_CALCULATION_MADE";
export const MISSED_SHIFT_CALCULATION_RESULTS = "MISSED_SHIFT_CALCULATION_RESULTS";
export const TIMESHEET_SELECTED = "TIMESHEET_SELECTED";
export const MISSED_SHIFT_DATE_CONFIG_UPDATED = "MISSED_SHIFT_DATE_CONFIG_UPDATED";
export const PROFILE_TABLE_CHANGED = "PROFILE_TABLE_CHANGED";
export const PROFILE_TABLE_SWITCH_NETSUITE_CHANGED = "PROFILE_TABLE_SWITCH_NETSUITE_CHANGED";
export const TITLE_CHANGED = "TITLE_CHANGED";
export const DATES_CHANGED = "DATES_CHANGED";
export const APP_SETTINGS_UPDATED = "APP_SETTINGS_UPDATED";
export const REFERRALS_TABLE_CHANGED = "REFERRALS_TABLE_CHANGED";
export const DTUSERS_TABLE_CHANGED = "DTUSERS_TABLE_CHANGED";
export const DTUSERS_NAME_CHANGED = "DTUSERS_NAME_CHANGED";
export const DTUSERS_EMAIL_CHANGED = "DTUSERS_EMAIL_CHANGED";
export const DTUSERS_CHBXPAYPERIOD_CHANGED = "DTUSERS_CHBXPAYPERIOD_CHANGED";
export const BPPUSERS_TABLE_CHANGED = "BPPUSERS_TABLE_CHANGED";
export const ADVANCED_SEARCH_UPDATE_PAYPERIOD = "ADVANCED_SEARCH_UPDATE_PAYPERIOD";
export const ADVANCED_SEARCH_UPDATE_TRAVELER = "ADVANCED_SEARCH_UPDATE_TRAVELER";
export const ADVANCED_SEARCH_UPDATE_RECRUITER = "ADVANCED_SEARCH_UPDATE_RECRUITER";
export const ADVANCED_SEARCH_UPDATE_FACILITIES = "ADVANCED_SEARCH_UPDATE_FACILITIES";
export const ADVANCED_SEARCH_UPDATE_DETAILS = "ADVANCED_SEARCH_UPDATE_DETAILS";
export const ADVANCED_SEARCH_UPDATE_STATES = "ADVANCED_SEARCH_UPDATE_STATES";
export const ADVANCED_SEARCH_CLEAR_SELECTS = "ADVANCED_SEARCH_CLEAR_SELECTS";
export const EXPENSE_FILTERS_CHANGED_DATES = "EXPENSE_FILTERS_CHANGED_DATES";
export const EXPENSE_FILTERS_CHANGED_STATE = "EXPENSE_FILTERS_CHANGED_STATE";
export const EXPENSE_FILTERS_CHANGED_RECRUITER = "EXPENSE_FILTERS_CHANGED_RECRUITER";
export const EXPENSE_FILTERS_CHANGED_TRAVELER = "EXPENSE_FILTERS_CHANGED_TRAVELER";
export const EXPENSE_FILTERS_CHANGED_TYPES = "EXPENSE_FILTERS_CHANGED_TYPES";
export const EXPENSE_FILTERS_CHANGED_AMOUNT = "EXPENSE_FILTERS_CHANGED_AMOUNT";
export const EXPENSE_FILTERS_CHANGED_RESET = "EXPENSE_FILTERS_CHANGED_RESET";
