import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import usePermission from "../../hooks/usePermissions";
import { ExpenseStatus } from "../../commons/Enums";

const ExpensesPanelTableRow = (props) => {
  const { expense, expenseStates } = props;
  const { canSeeLinkToExpenseSectionPanel } = usePermission();
  const linkText = "Expense Report";

  const isEmpty = useMemo(() => {
    return expense.id.includes("empty-row-");
  }, [expense]);

  const isExpensePaid = expense.expenseState === expenseStates.get(ExpenseStatus.PAID);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {expense.expenseType}
      </TableCell>
      <TableCell className="expense__link">
        {isEmpty ? (
          " "
        ) : canSeeLinkToExpenseSectionPanel ? (
          <Link
            sx={{
              pointerEvents: isExpensePaid ? "none" : "inherit",
              color: isExpensePaid ? "black" : "inherit",
              textDecoration: isExpensePaid ? "none" : "inherit",
            }}
            to={"/expenseDetails/" + expense.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </Link>
        ) : (
          linkText
        )}
      </TableCell>
      <TableCell className="expense__amount" align="right">
        <NumberFormat
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={"$ "}
          value={expense.amount}
          thousandSeparator={true}
          displayType={"text"}
        />
      </TableCell>
    </TableRow>
  );
};

ExpensesPanelTableRow.propTypes = {
  expense: PropTypes.object.isRequired,
  expenseStates: PropTypes.object.isRequired,
};

export default ExpensesPanelTableRow;
