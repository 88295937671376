import { useSelector } from "react-redux";
import { FeatureFlag } from "types/featureFlag";

export default {
  featureIsOn(feature: string, features: FeatureFlag[]|null) {
    if (!features) return false;
    for (let i = 0; i < features.length; i++) {
      if (features[i].name === feature) {
        return features[i].isActive;
      }
    }
    return false;
  },
  BillingIsOn(features: FeatureFlag[]|null) {
    return this.featureIsOn("billing", features);
  },
};

const isFeatureOn = (state: RootState, featureName: string) => {
  if (!state.featureFlags) return false;
  for (let i = 0; i < state.featureFlags.length; i++) {
    if (state.featureFlags[i].name === featureName) {
      return state.featureFlags[i].isActive;
    }
  }
  return false;
};

/**
 * @typedef {('isExpensesOn'|'isBillingOn'|'isFacilitiesOn'|'isVmsOn'|'isAdjustmentsOn'|'isTravelerHistoryAdvancedSearchOn'|'isHistoryOn'|'isNextPayPeriodViewOn'|'isReferralsOn' | 'isNextPayPeriodViewRejectOn')} BPPFeatureFlags
 */
export function useFeatureFlags() {
  const featureFlags = useSelector((state: RootState) => state.featureFlags);

  const isExpensesOn = useSelector((state: RootState) => isFeatureOn(state, "expenses"));
  
  const isBillingOn = useSelector((state: RootState) => isFeatureOn(state, "billing"));
  
  const isFacilitiesOn = useSelector((state: RootState) => isFeatureOn(state, "facilities"));
  
  const isVmsOn = useSelector((state: RootState) => isFeatureOn(state, "vms"));

  const missedShiftFeeDate: string|Date = useSelector(
    (state: RootState) => state.missedShiftCalculation.missedShiftFeeDate
  );

  const firstTimesheetPeriodStartDate = featureFlags?.find(
    (o) => o.name === "firstTimesheetPeriod"
  )?.configDate;

  const missedShiftDeductVisible: boolean = useSelector(
    (state: RootState) => state.missedShiftCalculation.missedShiftDeductVisible
  );

  const isAdjustmentsOn = useSelector((state: RootState) => isFeatureOn(state, "timecardAdjustment"));

  const isTravelerHistoryAdvancedSearchOn = useSelector((state: RootState) =>
    isFeatureOn(state, "travelerHistoryAdvancedSearch")
  );

  const isHistoryOn = useSelector((state: RootState) => isFeatureOn(state, "isHistoryOn"));

  const isNextPayPeriodViewOn = useSelector((state: RootState) => isFeatureOn(state, "nextPayPeriodView"));

  const isReferralsOn = useSelector((state: RootState) => isFeatureOn(state, "referrals"));

  const isNextPayPeriodViewRejectOn = useSelector((state: RootState) =>
    isFeatureOn(state, "nextPayPeriodViewReject")
  );

  return {
    featureFlags,
    isExpensesOn,
    isBillingOn,
    isFacilitiesOn,
    isVmsOn,
    missedShiftFeeDate,
    firstTimesheetPeriodStartDate,
    missedShiftDeductVisible,
    isAdjustmentsOn,
    isTravelerHistoryAdvancedSearchOn,
    isHistoryOn,
    isNextPayPeriodViewOn,
    isReferralsOn,
    isNextPayPeriodViewRejectOn,
  };
}
