import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { Input } from "@mui/material";

type NonTaxableRow = {
  originalAmount: number;
  amount: number;
  gross: number;
  isLocked: boolean;
  name: string;
  paid: number;
  prorated?: number;
  rate?: number;
  title: string;
  totalWorkedHrs?: number;
};

type NonTaxableMileageReimbursementProps = {
  row: NonTaxableRow;
  handlePaidChange: (
    name: string,
    calculationResult: number,
    mileageReimbursementRate: number
  ) => void;
  isRateReadOnly?: boolean;
  canEditAmount: boolean;
  handleOnExpenseChange: (row: string, value: number) => void;
};

const NonTaxableMileageReimbursement = ({
  row,
  handlePaidChange,
  isRateReadOnly = false,
  canEditAmount = false,
  handleOnExpenseChange,
}: NonTaxableMileageReimbursementProps): JSX.Element => {
  const [mileageReimbursementRate, setMileageReimbursementRate] = useState(row.rate ?? 0);

  const calculatetotalMileageReimbursement = (): void => {
    const calculationResult = mileageReimbursementRate * row.amount;
    handlePaidChange(row.name, calculationResult, mileageReimbursementRate);
  };

  const onBlurNewAmount = (newAmount: number) => {
    handleOnExpenseChange(row.name, newAmount);
  };

  return (
    <div
      style={{
        padding: "5px",
        paddingRight: "0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div style={{ minWidth: "33%" }}>
        <label>Rate: </label>
        <NumberFormat
          disabled={isRateReadOnly}
          customInput={Input}
          fixedDecimalScale={true}
          decimalScale={2}
          value={mileageReimbursementRate}
          onValueChange={(value): void => setMileageReimbursementRate(value.floatValue!)}
          thousandSeparator={true}
          prefix={"$"}
          onClick={(e: any): void => {
            const inputElement = e.target as HTMLInputElement;
            inputElement.select();
          }}
          onBlur={(): void => {
            calculatetotalMileageReimbursement();
          }}
          defaultValue={0}
          allowEmptyFormatting={false}
          style={{ width: "50%" }}
        />
      </div>
      <div style={{ minWidth: "33%" }}>
        <label>Value: </label>
        <NumberFormat
          customInput={Input}
          value={row.amount}
          thousandSeparator={true}
          disabled={!canEditAmount}
          onValueChange={(value): void => onBlurNewAmount(value.floatValue!)}
          onBlur={(): void => {
            calculatetotalMileageReimbursement();
          }}
          defaultValue={0}
          style={{ width: "50%" }}
        />
      </div>
      <div style={{ minWidth: "33%" }}>
        <label>Total: </label>
        <NumberFormat
          fixedDecimalScale={true}
          decimalScale={2}
          value={row.paid}
          prefix={"$"}
          thousandSeparator={true}
          readOnly={true}
          displayType={"text"}
        />
      </div>
    </div>
  );
};

export default NonTaxableMileageReimbursement;
