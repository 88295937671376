export function getInnerHeight(el: Element) {
  const { clientHeight } = el;
  const { paddingTop, paddingBottom } = getComputedStyle(el);
  return clientHeight - parseFloat(paddingTop) - parseFloat(paddingBottom);
}

export function getInnerWidth(el: Element) {
  const { clientWidth } = el;
  const { paddingLeft, paddingRight } = getComputedStyle(el);
  return clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);
}

let scrollbarWidth = 0;

export function getScrollbarWidth() {
  if (scrollbarWidth !== 0) return scrollbarWidth;
  /* istanbul ignore else */
  if (typeof document !== "undefined") {
    const div = document.createElement("div");
    div.style.setProperty("width", "100px");
    div.style.setProperty("height", "100px");
    div.style.setProperty("position", "absolute");
    div.style.setProperty("top", "-9999px");
    div.style.setProperty("overflow", "scroll");

    document.body.appendChild(div);
    scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
  } else {
    scrollbarWidth = 0;
  }
  return Math.max(scrollbarWidth, 0);
}
