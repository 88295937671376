import React, { Fragment } from "react";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { Note } from "types/notes";

type JobAssignmentNoteProps = {
  note: Note;
}

export const JobAssignmentNote = ({ note }: JobAssignmentNoteProps) => {
  const m = moment
    .utc(note.createdDateUtc)
    .local()
    .format("MM/DD/YY h:mm:ss a");

  return (
    <div className="note-container">
      <ListItemText
        primary={note.contents}
        secondary={
          <Fragment>
            {note.createdByFirstName} {note.createdByLastName}
            {" - "}
            <span>{m}</span>
          </Fragment>
        }
      />
    </div>
  );
};
