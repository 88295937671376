import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import "./DragAndDrop.css";
import { CircularProgress, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

type FileDragAndDropProps = {
  expenseId?: string;
  onFileUploaded: (files: FileList) => Promise<void>;
  disabledReason: string;
  fileIds: string[];
  acceptedFiles: string[];
};

export const FileDragAndDrop = ({
  expenseId,
  onFileUploaded,
  disabledReason,
  fileIds,
  acceptedFiles,
}: FileDragAndDropProps) => {
  const [filesUploading, setFilesUploading] = useState(false);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const disabled = !!disabledReason;

  const handleUpload = async (files: FileList) => {
    setFilesUploading(true);
    try {
      await onFileUploaded(files);
    } finally {
      setFilesUploading(false);
    }
  };

  useEffect(() => {
    if (inputRef.current && expenseId) {
      inputRef.current.oninput = (e) => {
        const files = inputRef.current?.files;
        if (files && files.length + fileIds.length > 5) {
          setMaxLengthError(true);
        } else {
          setMaxLengthError(false);
          handleUpload(files!);
        }
      };
    }
  }, [expenseId, fileIds]);

  useEffect(() => {
    if (expenseId) {
      const dropzone = document.getElementById("dropzone");

      dropzone?.addEventListener("dragover", (e) => {
        e.preventDefault();
        dropzone.style.backgroundColor = "#f0f0f0";
      });

      dropzone?.addEventListener("dragleave", () => {
        dropzone.style.backgroundColor = "#fff";
      });

      dropzone?.addEventListener("drop", (e) => {
        dropzone.style.backgroundColor = "#fff";
      });

      return () => {
        dropzone?.removeEventListener("dragover", (e) => {
          e.preventDefault();
          dropzone.style.backgroundColor = "#f0f0f0";
        });
        dropzone?.removeEventListener("dragleave", () => {
          dropzone.style.backgroundColor = "#fff";
        });
        dropzone?.removeEventListener("drop", () => {
          dropzone.style.backgroundColor = "#fff";
        });
      };
    }
  }, [expenseId]);

  return (
    <Box id="dropzone">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {filesUploading ? (
          <>
            <Typography>Uploading files...</Typography>
            <CircularProgress />
          </>
        ) : (
          <>
            <Typography color={disabled ? "#a3a3a3" : "#414141"}>
              Drag and drop up to 5 files or{" "}
              <span style={{ color: disabled ? "#a3a3a3" : "#0ba0dc", fontWeight: 600 }}>
                click here
              </span>
            </Typography>
            <CloudUploadIcon
              sx={{
                marginTop: 1,
                fontSize: 30,
                color: "#8C85B1",
              }}
            />
            {maxLengthError && (
              <Typography color="error" fontSize={11} mt={1}>
                Maximum limit is 5. You can upload up to 5 files.
              </Typography>
            )}
            {disabled && <Typography fontWeight={600}>{disabledReason}</Typography>}
          </>
        )}
      </Box>
      <input
        type="file"
        ref={inputRef}
        multiple
        disabled={filesUploading || disabled}
        style={{
          cursor: filesUploading || disabled ? "default" : "pointer",
        }}
        accept={acceptedFiles.join(",")}
      />
    </Box>
  );
};
