import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

type HomeTabsProps = {
  setNewTabIndex: (index: number) => void;
  state: any;
  billingIsOn: boolean;
};

const TabLabel = ({ label, badge }: { label: string; badge: number }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography sx={{ textTransform: "capitalize" }} fontWeight="semibold" fontSize={13}>
        {label}
      </Typography>
      <Box className="timecard-tab-title-badge">
        <Typography fontSize={10} fontWeight="bold">
          {badge}
        </Typography>
      </Box>
    </Box>
  );
};

export const HomeTabs = ({ setNewTabIndex, state, billingIsOn }: HomeTabsProps) => {
  return (
    <Tabs
      value={state.selectedTabIndex}
      onChange={(_, idx) => {
        setNewTabIndex(idx);
      }}
      indicatorColor="primary"
      textColor="inherit">
      <Tab label={<TabLabel label="Pending" badge={state.myAssignments?.length ?? 0} />} />
      <Tab
        label={
          <TabLabel
            label={billingIsOn ? "Payroll" : "For Review"}
            badge={state.forReviewAssignments?.length ?? 0}
          />
        }
      />
      {billingIsOn && (
        <Tab label={<TabLabel label="Billing" badge={state.billingAssignments?.length ?? 0} />} />
      )}
      <Tab label={<TabLabel label="Approved" badge={state.approvedAssignments?.length ?? 0} />} />
    </Tabs>
  );
};
