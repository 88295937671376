import * as actionTypes from "../actions/actionTypes";

export default function referralsTableReducer(state = null, action: any) {
  switch (action.type) {
    case actionTypes.REFERRALS_TABLE_CHANGED:
      return action.referrals;
    default:
      return state;
  }
}
