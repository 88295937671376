import React from "react";
import { Box, Typography } from "@mui/material";

import maintenanceImage from "./maintenanceIcon.png";

export const Maintenance = () => {
  const headerText = "We'll Be Right Back!";
  const bodyText1 =
    "We're giving our site a little love to make your experience even better. Hang tight!";
  const bodyText2 = "We'll be up and running again soon. Thanks for your patience!";

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5rem",
      }}>
      <Box style={{ color: "#514689", textAlign: "center" }}>
        <Typography style={{ fontSize: "2rem", fontWeight: "bold" }}>{headerText}</Typography>
        <Typography style={{ fontSize: "1.1rem", marginTop: 10, marginBottom: 15 }}>
          {bodyText1}
        </Typography>
        <Box padding={2}>
          <img
            style={{ maxWidth: "17rem", maxHeight: "17rem" }}
            src={maintenanceImage}
            alt="logo"
          />
        </Box>
        <Typography style={{ fontSize: "1.1rem", marginTop: 15 }}>{bodyText2}</Typography>
      </Box>
    </Box>
  );
};
