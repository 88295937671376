import { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";

/**
 * @param getFromAPI API call to get data
 * @param comparer to check if option is selected
 * @param selectedOptions to set previously selected options
 */
export default function useFilterAdvancedSearch(
  getFromAPI: (queryString: string) => Promise<any>,
  comparer: (option: any, value: any) => boolean,
  selectedOptions?: any[]
): [any[], boolean, (event: any, newInputValue: string, reason: string) => void, (option: any, value: any) => boolean] {
  const [entities, setEntities] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState<any[] | undefined>([]);

  useEffect(() => {
    setOptionsSelected(selectedOptions);
  }, [selectedOptions]);

  /**
   * @param {Array} [previouslySelected]
   */
  const getResults = (queryString: string, previouslySelected?: any[]) => {
    setLoading(true);
    getFromAPI(queryString)
      .then((response) => {
        if (selectedOptions && selectedOptions.length > 0) {
          const combined = [...(previouslySelected ?? []), ...response];
          const combinedString = combined.map((c) => JSON.stringify(c));

          const combinedStringUnique = Array.from(new Set(combinedString));

          const uniqueOptions = combinedStringUnique.map((c) => JSON.parse(c));

          setEntities(uniqueOptions);
        } else {
          setEntities(response as any[]);
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = useCallback(
    debounce((_event, newInputValue, reason) => {
      if (reason !== "reset" && newInputValue.length >= 3) {
        getResults(newInputValue, optionsSelected);
      } else {
        setEntities([]);
      }
    }, 400),
    [optionsSelected]
  );

  const isOptionSelected = useCallback((option: any, value: any) => comparer(option, value), []);

  return [entities, loading, handleInputChange, isOptionSelected];
}
