import { useFeatureFlags } from "commons/Features";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useAppSelector } from "./redux";

export default function useTimesheetAdjustments(row: any, isAdjustment: boolean, adjustments: any[]) {
  const { canAdjustCurrentTimecard } = usePermissions();
  const { isAdjustmentsOn } = useFeatureFlags();
  const timesheetSelected = useAppSelector((state) => state.missedShiftCalculation.timesheetSelected);
  const [canAddAdjustmentLine, setCanAddAdjustmentLine] = useState(false);
  const [canRemoveAdjustmentLine, setCanRemoveAdjustmentLine] = useState(false);

  const [styleVisibilityAddButton, setStyleVisibilityAddButton] = useState({ display: "none" });

  useEffect(() => {
    const hasLessThanTwoAdjustments = (rowName: string) => {
      return adjustments.filter((t) => t.name === rowName).length < 2;
    };

    setCanAddAdjustmentLine(
      !row.isLocked &&
        isAdjustmentsOn &&
        !isAdjustment &&
        canAdjustCurrentTimecard &&
        hasLessThanTwoAdjustments(row.name) &&
        (!row.isLocked || timesheetSelected.isAdjustment)
    );

    setCanRemoveAdjustmentLine(
      !row.isLocked &&
        isAdjustmentsOn &&
        isAdjustment &&
        canAdjustCurrentTimecard &&
        (!row.isLocked || timesheetSelected.isAdjustment)
    );
  }, [
    row,
    isAdjustmentsOn,
    isAdjustment,
    canAdjustCurrentTimecard,
    timesheetSelected,
    adjustments,
  ]);

  const updateVisibilityAddButton = (show: boolean) => {
    setStyleVisibilityAddButton({ display: show ? "inline-flex" : "none" });
  };

  return {
    canAddAdjustmentLine,
    canRemoveAdjustmentLine,
    styleVisibilityAddButton,
    updateVisibilityAddButton,
  };
}
