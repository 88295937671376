import { useAppSelector } from "hooks";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { PayrollLockStatusTimecardsWithExpenses } from "types/payPeriod/PayrollLockStatusTimecardsWithExpenses";
import BPPTable, { Column } from "./bppTable/BPPTable";

type LockPayrollTimecardsWithExpensesDialogContentProps = {
    timecardsWithExpenses: PayrollLockStatusTimecardsWithExpenses[];
};

type PayrollLockStatusTimecardsWithExpensesIds = 
| "TimesheetId"
| "RecruiterId"
| "TravelerName";

const LockPayrollTimecardsWithExpensesDialogContent = ({
    timecardsWithExpenses,
}: LockPayrollTimecardsWithExpensesDialogContentProps) => {

    const headerState = useAppSelector((state) => state.header);

    const formatDate = (date: string) => {
        return moment(new Date(date)).format("MM/DD/YYYY");
      };
      
    const columns: Column<PayrollLockStatusTimecardsWithExpensesIds, PayrollLockStatusTimecardsWithExpenses>[] = [
            {
              label: "Traveler",
              id: "TravelerName",
              format(value, rowData) {
                return (
                    <Link
                    to={{
                      pathname: `/home/${rowData.RecruiterId}/${rowData.TimesheetId}/${formatDate(
                        headerState.startDate
                      )}/${formatDate(headerState.endDate)}`,
                    }}
                    target="_blank">
                    {rowData.TravelerName}
                </Link>
                );
              },
            },
          ];

return (
    <>
    <Box my={2}>
            <BPPTable<PayrollLockStatusTimecardsWithExpenses, PayrollLockStatusTimecardsWithExpensesIds>
            data={timecardsWithExpenses}
            columns={columns}
            showFilters={false}
          />
        </Box>
    </>
);    
};

export default LockPayrollTimecardsWithExpensesDialogContent;