import React from "react";
import PropTypes from "prop-types";
import FileUpdateAndDownload from "../shared/components/FileUpdateAndDownload";

function ConfirmationPageFile(props) {
  let downloadUrl = null;
  if (props.confirmationPageUploadId) {
    downloadUrl = `${process.env.REACT_APP_API_URL}/jobAssignments/confirmationPage/${props.confirmationPageUploadId}`;
  }

  return (
    <FileUpdateAndDownload
      downloadUrl={downloadUrl}
      noFileMessage="No confirmation page has been uploaded"
      fileName="Confirmation Page File"
      icon="description"
      {...props}
    />
  );
}

ConfirmationPageFile.propTypes = {
  confirmationPageUploadId: PropTypes.string,
  jobId: PropTypes.string,
  onFileUploaded: PropTypes.func,
  uploading: PropTypes.number,
  isReadOnly: PropTypes.bool,
};

export default ConfirmationPageFile;
