import React, { useCallback } from "react";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import PayrollApi from "api/PayrollApi";
import { downloadBlob } from "commons/utils";
import { API_URL } from "commons/environment";

type ConfirmationPageFileIconProps = {
  confirmationPageUploadId?: string;
};

const ConfirmationPageFileIcon = (props: ConfirmationPageFileIconProps) => {
  const { confirmationPageUploadId } = props;

  const onIconClick = useCallback(() => {
    if (confirmationPageUploadId) {
      const url = `${API_URL}/jobAssignments/confirmationPage/${confirmationPageUploadId}`;

      PayrollApi.downloadFile(url)
        .then((blob) => {
          const extention = blob.type.split("/")[1];
          downloadBlob(new Blob([blob]), `Confirmation Page File.${extention}`);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [confirmationPageUploadId]);

  return (
    <IconButton
      disabled={!confirmationPageUploadId}
      sx={{ paddingLeft: "10px" }}
      onClick={onIconClick}
      size="large"
      data-testid="click-to-download">
      <Tooltip title={"Click to Download"} placement="top">
        <Avatar
          sx={{
            width: 35,
            height: 35,
          }}
          className={`${
            confirmationPageUploadId ? "file-attached-indicator filled" : "file-attached-indicator"
          }`}>
          <i className="material-icons-outlined">description</i>
        </Avatar>
      </Tooltip>
    </IconButton>
  );
};

export default ConfirmationPageFileIcon;
