import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { parseErrorResponse } from "commons/utils";
import { useSnackbar } from "notistack";
import { getDocuments, updateDocuments, validateUpdateDocuments } from "api/FacilitiesApi";
import DeleteIcon from "@mui/icons-material/Delete";

type AdditionalDocumentsProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const AdditionalDocumentsManageDialog = ({
  isOpen,
  handleClose,
}: AdditionalDocumentsProps) => {
  const [documents, setDocuments] = useState<{ id: string; name: string }[]>([]);
  const [counter, setCounter] = useState(1);
  const snackbar = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number | null>
  ) {
    if (event.target.name) {
      const document = documents.find((doc) => doc.id === event.target.name);
      if (document) {
        document.name = event.target.value as string;
        setDocuments([...documents]);
      }
    }
  }

  function onClose() {
    handleClose();
    setDocuments([]);
    setCounter(1);
  }

  async function onConfirm() {
    try {
      setSaving(true);
      const emptyDocuments = documents.filter((doc) => doc.name === "");
      if (emptyDocuments.length > 0) {
        snackbar.enqueueSnackbar("Document name cannot be empty", { variant: "error" });
        setSaving(false);
        return;
      }

      const duplicates = documents.filter(
        (doc, index) => documents.findIndex((d) => d.name === doc.name) !== index
      );

      if (duplicates.length > 0) {
        snackbar.enqueueSnackbar("Document names must be unique", { variant: "error" });
        setSaving(false);
        return;
      }
      const docs = documents.map((doc) => ({
        id: doc.id.startsWith("fake") ? null : doc.id,
        name: doc.name,
      }));

      const { isValid, errors } = await validateUpdateDocuments(docs);

      if (!isValid) {
        if (errors.length > 0) {
          snackbar.enqueueSnackbar(errors.join("\n"), { variant: "error" });
        } else {
          snackbar.enqueueSnackbar("An error occurred", { variant: "error" });
        }

        setSaving(false);
        return;
      }

      await updateDocuments(docs);
      snackbar.enqueueSnackbar("Documents have been updated", { variant: "success" });
    } catch (error: any) {
      const errorMessage = await parseErrorResponse(error);
      snackbar.enqueueSnackbar(errorMessage, { variant: "error" });
    }

    setSaving(false);
    onClose();
  }

  function addDocument() {
    setDocuments([...documents, { id: `fake${counter.toString()}`, name: "" }]);
    setCounter(counter + 1);
  }

  function removeDocument(index: number) {
    setDocuments(documents.filter((_, i) => i !== index));
  }

  async function loadDocuments() {
    setLoading(true);

    try {
      const response = await getDocuments();
      setDocuments(response);
      setLoading(false);
    } catch (error: any) {
      const errorMessage = parseErrorResponse(error);
      snackbar.enqueueSnackbar(errorMessage, { variant: "error" });
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen) {
      loadDocuments();
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Manage documents</DialogTitle>
      <DialogContent sx={{ minWidth: "350px", px: 5 }}>
        <Box justifyContent={"center"} display={"flex"} flexDirection={"column"}>
          {documents.map((document, index) => (
            <Box
              key={document.id}
              display={"flex"}
              justifyContent={"space-between"}
              width={"100%"}
              mb={2}>
              <TextField
                variant="outlined"
                fullWidth
                data-testid={"documentName" + document.id}
                name={document.id}
                value={document.name}
                onChange={handleChange}
                placeholder="Name"></TextField>
              <Box display={"flex"} flexDirection={"row"} alignItems={"end"}>
              <IconButton
                data-testid={"documentDelete" + document.id}
                onClick={() => removeDocument(index)}
                aria-label="delete"
                title="Delete"
                disabled={saving}>
                <DeleteIcon />
              </IconButton>

                {index === documents.length - 1 && (
                  <IconButton
                    data-testid={"documentAdd" + document.id}
                    onClick={addDocument}
                    aria-label="add"
                    title="Add"
                    disabled={saving}>
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 5 }}>
        {loading ? (
          <>
            <Skeleton width={"100%"} height={75}></Skeleton>
            <Skeleton width={"100%"} height={75}></Skeleton>
          </>
        ) : (
          <>
            <Button onClick={onClose} variant="outlined" fullWidth disabled={saving}>
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              color="primary"
              variant="contained"
              fullWidth
              disabled={saving}>
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
