import { Typography } from "@mui/material";
import AdfApi from "api/AdfApi";
import BPPTable, { Column } from "components/shared/components/bppTable/BPPTable";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { AdfRunData } from "types/adf/adfRunData";

type AdfRunsDataIds =
  | "id"
  | "dataFlow"
  | "externalEntityId"
  | "externalEntityDescription"
  | "entityId"
  | "error"
  | "activeJobAssignment";

const AdfRuns = () => {
  const { id } = useParams<{ id: string }>();
  const [loadingData, setLoadingData] = useState(false);
  const [dateRun, setDateRun] = useState<string | null>(null);
  const [data, setData] = useState<AdfRunData[]>([]);

  const columns: Column<AdfRunsDataIds, AdfRunData>[] = [
    {
      id: "dataFlow",
      label: "Data Flow",
    },
    {
      id: "externalEntityId",
      label: "External Id",
    },
    {
      id: "externalEntityDescription",
      label: "Entity Description",
    },
    {
      id: "entityId",
      label: "Entity Id",
    },
    {
      id: "error",
      label: "Error",
    },
    {
      id: "activeJobAssignment",
      label: "Active Assignment",
      format: (value, data) => (value ? "Yes" : "No"),
      lookup: {
        Yes: "Yes",
        No: "No",
      },
      defaultFilter: ["Yes"],
    },
  ];
  const dispatch = useDispatch();

  const getInfo = async () => {
    setLoadingData(true);

    AdfApi.getAdfRunLogs(id).then((data) => {
      if (data.runDateTime) {
        setDateRun(format(new Date(data.runDateTime), "MM/dd/yyyy - h:mm a"));
      }

      if (data.data) {
        setData(data.data);
      }
      setLoadingData(false);
    });
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "ADF Run" });

    getInfo();
  }, []);

  return (
    <div className="view-container">
      <BPPTable<AdfRunData, AdfRunsDataIds>
        data={data}
        columns={columns}
        loading={loadingData}
        emptyMessage="No errors in this run"
        customSearch={
          <Typography px={2} fontSize={16} display={"flex"} alignItems={"center"}>
            Run at:
            <Typography mx={0.5} fontWeight={500} component={"span"}>
              {dateRun}
            </Typography>
          </Typography>
        }
      />
    </div>
  );
};

export default AdfRuns;
