import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import SwitchPayPeriodApi from "api/SwitchPayPeriodApi";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Typography } from "@mui/material";
import { DATES_CHANGED } from "redux/actions/actionTypes";
import { useHistory } from "react-router";
import { getRoles, msalInstance } from "commons/authConfig";
import { checkNextYearHolidays } from "api/HolidaysApi";
import CanRenderChildren from "../functions/CanRenderChildren";
import { usePermissions } from "hooks";

const Header = () => {
  const dispatch = useDispatch();
  const router = useHistory();

  const title = useSelector((state: RootState) => state.header.title);
  const startDate = useSelector((state: RootState) => state.header.startDate);
  const endDate = useSelector((state: RootState) => state.header.endDate);
  const dateToCompare = useSelector((state: RootState) => state.header.dateToCompare);

  const { canCheckHolidays } = usePermissions();

  const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState(false);

  useEffect(() => {
    const roles = getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles);
    if (roles.length === 0) {
      router.replace("/unauthorized");
    }

    SwitchPayPeriodApi.GetActivePayPeriod().then((response) => {
      dispatch({
        type: DATES_CHANGED,
        startDate: response.periodStartDate,
        endDate: response.periodEndDate,
      });
    });
    canCheckHolidays && checkTooltipHolidays();
  }, []);

  const showIcon = () => {
    return new Date(dateToCompare) < new Date(endDate);
  };

  const checkTooltipHolidays = async () => {
    try {
      const response = await checkNextYearHolidays();
      setShouldDisplayTooltip(response);
    } catch (e) {
      console.error("Error on getting Holidays");
    }
  };

  return (
    <AppBar sx={{ zIndex: 51 }} position="static" color="transparent">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography variant="h5" sx={{ margin: 0, fontWeight: "bold" }}>
            {title}
          </Typography>
          {shouldDisplayTooltip && (
            <CanRenderChildren permissionName="canCheckHolidays">
              <Tooltip
                open={true}
                title="Setup next years holidays before the end of the current year"
                placement="right">
                <i
                  style={{
                    color: "orange",
                  }}
                  className={"material-icons-outlined "}>
                  warning
                </i>
              </Tooltip>
            </CanRenderChildren>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          {showIcon() && (
            <Tooltip title="The pay period shown is for next week" placement="top">
              <i
                style={{
                  color: "red",
                  marginRight: "5px",
                }}
                className={"material-icons-outlined "}>
                error
              </i>
            </Tooltip>
          )}
          {startDate && endDate && (
            <Typography
              sx={{
                fontWeight: 200,
                margin: 0,
              }}>
              CURRENT PAY PERIOD: <strong>{moment(startDate).format("MM/DD/YYYY")}</strong> to{" "}
              <strong>{moment(endDate).format("MM/DD/YYYY")}</strong>
            </Typography>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
