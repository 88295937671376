import React from "react";
import PropTypes from "prop-types";
import { Avatar, Tooltip } from "@mui/material";

const TimecardIsLateIcon = (props) => {
  const { isLate } = props;

  return isLate ? (
    <Tooltip title={"This timecard was marked late."} placement="top">
      <Avatar className={`file-attached-indicator filled`}>
        <i className="material-icons-outlined">watch_later</i>
      </Avatar>
    </Tooltip>
  ) : (
    <Avatar className={`file-attached-indicator`}>
      <i className="material-icons-outlined">watch_later</i>
    </Avatar>
  );
};

TimecardIsLateIcon.propTypes = {
  isLate: PropTypes.bool,
};

export default TimecardIsLateIcon;
