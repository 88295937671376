import {
  EXPENSE_FILTERS_CHANGED_AMOUNT,
  EXPENSE_FILTERS_CHANGED_DATES,
  EXPENSE_FILTERS_CHANGED_RECRUITER,
  EXPENSE_FILTERS_CHANGED_RESET,
  EXPENSE_FILTERS_CHANGED_STATE,
  EXPENSE_FILTERS_CHANGED_TRAVELER,
  EXPENSE_FILTERS_CHANGED_TYPES,
} from "redux/actions/actionTypes";
import { OptionType } from "types/expenses/Expense";
import { RecruiterState } from "types/recruiter";
import { TravelerState } from "types/traveler";

type ExpenseState = {
  dateFrom: string;
  dateTo: string;
  states: string[];
  expenseTypes: OptionType[];
  recruiters?: RecruiterState;
  travelers?: TravelerState;
  amountFrom?: string;
  amountTo?: string;
};

export const expenseEmptyRequest: ExpenseState = {
  dateFrom: "",
  dateTo: "",
  states: ["Rejected", "Draft", "Approved", "In Review", "To be Paid"],
  expenseTypes: [],
  recruiters: undefined,
  travelers: undefined,
  amountFrom: "",
  amountTo: "",
};

type ExpenseAction = {
  dateFrom: string;
  dateTo: string;
  states: string[];
  expenseTypes: OptionType[];
  recruiters: RecruiterState;
  travelers: TravelerState;
  // actual action type
  type: string;
  amountFrom?: string;
  amountTo?: string;
};

export function expenseTableReducer(state = expenseEmptyRequest, action: ExpenseAction) {
  switch (action.type) {
    case EXPENSE_FILTERS_CHANGED_DATES:
      return { ...state, dateFrom: action.dateFrom, dateTo: action.dateTo };
    case EXPENSE_FILTERS_CHANGED_STATE:
      return { ...state, states: action.states };
    case EXPENSE_FILTERS_CHANGED_RECRUITER:
      return { ...state, recruiters: action.recruiters };
    case EXPENSE_FILTERS_CHANGED_TYPES:
      return { ...state, expenseTypes: action.expenseTypes };
    case EXPENSE_FILTERS_CHANGED_TRAVELER:
      return { ...state, travelers: action.travelers };
    case EXPENSE_FILTERS_CHANGED_AMOUNT:
      return { ...state, amountFrom: action.amountFrom, amountTo: action.amountTo };
    case EXPENSE_FILTERS_CHANGED_RESET:
      return { ...state, ...expenseEmptyRequest };
    default:
      return state;
  }
}
