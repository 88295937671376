import BPPTable, { Column, SaveType } from "components/shared/components/bppTable/BPPTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DTUSERS_CHBXPAYPERIOD_CHANGED,
  DTUSERS_EMAIL_CHANGED,
  DTUSERS_NAME_CHANGED,
  DTUSERS_TABLE_CHANGED,
} from "redux/actions/actionTypes";
import { UsersTableItem } from "types/users";
import { UserSearch } from "./UserSearch";
import { getDTUsers, resetDTUser } from "api/UsersApi";
import { actionsFormat, bppEmailFormat, dateFormat } from "./tableFormatters";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

type UsersDataIds = "name" | "email" | "bppEmails" | "createdOrModifiedDate" | "actions";

type UsersData = UsersTableItem;

export const DTUsers = () => {
  const [data, setData] = useState<UsersData[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [resetShow, setResetShow] = useState<{ open: boolean; user: UsersData | undefined }>({
    open: false,
    user: undefined,
  });
  const [resetLoading, setResetLoading] = useState(false);
  const dispatch = useDispatch();
  const dtUsers = useSelector((state: RootState) => state.dtusers);
  const { enqueueSnackbar } = useSnackbar();

  const handleResetToggle = (user?: UsersData) => {
    if (resetShow.open) {
      setResetShow({ open: false, user: undefined });
    } else {
      setResetShow({ open: true, user });
    }
  };

  useEffect(() => {
    dispatch({ type: DTUSERS_CHBXPAYPERIOD_CHANGED, value: true });
  }, []);

  const handleCancelName = () => {
    dispatch({ type: DTUSERS_NAME_CHANGED, value: "" });
  };

  const handleCancelEmail = () => {
    dispatch({ type: DTUSERS_EMAIL_CHANGED, value: "" });
  };

  const handleSearch = async () => {
    try {
      if (!dtUsers?.name && !dtUsers?.email && !dtUsers?.searchByPayPeriod) {
        enqueueSnackbar("Please, add a filter", { variant: "error" });
        return;
      }

      setLoadingData(true);
      const users = await getDTUsers(dtUsers?.name, dtUsers?.email, dtUsers?.searchByPayPeriod);
      setData(users.map((x) => ({ ...x, id: x.candidateId })));
    } catch (error) {
      enqueueSnackbar("Error fetching users");
    } finally {
      setLoadingData(false);
    }
  };

  const handleChangedName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: DTUSERS_NAME_CHANGED, value: e.target.value });
  };

  const handleChangedEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: DTUSERS_EMAIL_CHANGED, value: e.target.value });
  };

  const handleChangedPayPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: DTUSERS_CHBXPAYPERIOD_CHANGED, value: e.target.checked });
  };

  const handleChange = (newValue: SaveType<UsersDataIds>): void => {
    dispatch({ type: DTUSERS_TABLE_CHANGED, filters: newValue });
  };

  const columns: Column<UsersDataIds, UsersData>[] = [
    {
      id: "name",
      label: "Name",
      format(value) {
        return (
          <Typography fontSize={13} sx={{ textTransform: "capitalize" }}>
            {(value as string).toLowerCase()}
          </Typography>
        );
      },
    },
    { id: "email", label: "Email", format: bppEmailFormat },
    {
      id: "createdOrModifiedDate",
      label: "Date",
      format: dateFormat,
    },
    {
      id: "actions",
      label: "Actions",
      disableFilter: true,
      format: actionsFormat(handleResetToggle),
    },
  ];

  const handleConfirmReset = async () => {
    try {
      setResetLoading(true);
      await resetDTUser(resetShow.user!.externalId!);

      enqueueSnackbar("User reset successfully");
      handleSearch();
    } catch (error) {
      enqueueSnackbar("Error resetting user");
    } finally {
      handleResetToggle();
      setResetLoading(false);
    }
  };

  const handleClear = () => {
    handleCancelName();
    handleCancelEmail();
  };

  const textFieldsProps = [
    {
      label: "Traveler Name",
      name: "travelerName",
      handleChangeValue: handleChangedName,
      handleClearValue: handleCancelName,
      value: dtUsers?.name ?? ""
    },
    {
      label: "Traveler Email",
      name: "travelerEmail",
      handleChangeValue: handleChangedEmail,
      handleClearValue: handleCancelEmail,
      value: dtUsers?.email ?? ""
    },
  ];

  return (
    <>
      <BPPTable<UsersData, UsersDataIds>
        columns={columns}
        data={data}
        loading={loadingData}
        handleSave={handleChange}
        handleClear={handleClear}
        savedData={dtUsers?.filters}
        defaultOrderingBy="name"
        customSearch={
          <UserSearch
            handleSearch={handleSearch}
            disabled={loadingData}
            textFieldsProps={textFieldsProps}
            handleChangePayPeriod={handleChangedPayPeriod}
          />
        }
      />
      <Dialog open={resetShow.open} onClose={() => handleResetToggle()}>
        <DialogTitle sx={{ textAlign: "center", textTransform: "capitalize" }}>
          Reset {resetShow.user?.name.toLowerCase()}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText fontSize={16} textAlign="center">
            You are about to reset a Digital Timecard user. This process will clear all credentials
            and information from our system and <strong>cannot be reverted</strong>.
          </DialogContentText>
          <DialogActions>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-around"}
              paddingTop={2}
              px={6}
              width="100%">
              <Button
                size="medium"
                fullWidth
                variant="outlined"
                style={{ marginRight: 20 }}
                onClick={() => handleResetToggle()}>
                Cancel
              </Button>
              <Button
                size="medium"
                fullWidth
                variant="contained"
                color="primary"
                disabled={resetLoading}
                onClick={handleConfirmReset}>
                Confirm
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
