import React from "react";
import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";

// add width on next iteration (when used)
type LoadingButtonProps = {
  loading?: boolean;
  children: React.ReactNode;
};

const baseLoadingStyles: React.CSSProperties = {
  backgroundColor: "#50478b",
  color: "white",
  paddingRight: 25,
  paddingLeft: 25,
};

export const LoadingButton = ({
  loading,
  children,
  ...props
}: LoadingButtonProps & ButtonProps) => {
  return (
    <Button
      style={props.variant !== "text" ? { ...baseLoadingStyles } : { color: "#50478b" }}
      {...props}>
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1.5px 6.75px 1.5px 6.75px",
          }}>
          <CircularProgress color="inherit" size={20} />
        </Box>
      ) : (
        children
      )}
    </Button>
  );
};
