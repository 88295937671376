import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import NonTaxableExpenses from "./NonTaxableExpenses";

const NonTaxableExpensesPanel = (props) => {
  const { legend, jobAssignment } = props;

  if (legend) {
    return (
      <Paper className="timecard-readonly-paper">
        <Typography
          sx={{
            padding: "10px",
          }}
        >
          {legend}
        </Typography>
        <NonTaxableExpenses
          expenses={jobAssignment.nonTaxableExpenses}
          isFirstWeek={jobAssignment.isFirstWeek}
          isLastWeek={jobAssignment.isLastWeek}
          isReadOnly={true}
          nonTaxableAdjustments={jobAssignment.nonTaxableAdjustmentTypes}
          isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
        ></NonTaxableExpenses>
      </Paper>
    );
  } else {
    return (
      <NonTaxableExpenses
        expenses={jobAssignment.nonTaxableExpenses}
        isFirstWeek={jobAssignment.isFirstWeek}
        isLastWeek={jobAssignment.isLastWeek}
        nonTaxableAdjustments={jobAssignment.nonTaxableAdjustmentTypes}
        isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
      ></NonTaxableExpenses>
    );
  }
};

NonTaxableExpensesPanel.propTypes = {
  jobAssignment: PropTypes.object,
  legend: PropTypes.string,
};

export default NonTaxableExpensesPanel;
