import { TableRow, TableCell, Button } from "@mui/material";
import { fetchHolidays, saveHolidays } from "api/HolidaysApi";
import moment from "moment";
import React, { useState, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { useSelector } from "react-redux";
import { Holiday, HolidayType } from "types/holidays";

type HolidaysRowProps = {
  callbackSnackBar: (msg: string) => void;
};

const HolidaysRow = ({ callbackSnackBar }: HolidaysRowProps) => {
  const startDate = useSelector((state: RootState) => state.header.startDate);
  const endDate = useSelector((state: RootState) => moment(state.header.endDate));

  const getHolidays = async () => {
    try {
      const response = await fetchHolidays();
      setHolidays(response.filter((holiday) => new Date(holiday.date).getFullYear() === new Date().getFullYear()));
    } catch (e) {
      callbackSnackBar("Error on getting Holidays");
    }
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const [holidays, setHolidays] = useState<Holiday[]>([]);

  const handleOnSave = async (holidayId: string) => {
    try {
      const holidayToUpdate = holidays.find((holiday) => {
        return holiday.id === holidayId;
      });

      if (!holidayToUpdate) return;

      await saveHolidays(holidayId, holidayToUpdate.date);
      await getHolidays();
      callbackSnackBar("Changes saved!");
    } catch (e) {
      callbackSnackBar("Error on save Holidays");
    }
  };

  const handleDayChange = (date: Date, name: string) => {
    if (!holidays) return;

    const normalizedDate  = new Date(moment(date).format("MM/DD/YYYY"));

    const updatedHolidays = holidays.map((holiday) =>
      holiday.name === name ? { ...holiday, date: normalizedDate  } : holiday
    );

    setHolidays(updatedHolidays);
  };

  return (
    <TableRow>
      <TableCell>
        <h2>Holidays</h2>
      </TableCell>
      <TableCell>
        <h4>Static holidays</h4>
        {holidays
          .filter((holiday) => holiday.type === HolidayType.Static)
          .sort((a, b) => {
            const dateA = moment(a.date);
            const dateB = moment(b.date);
            
            const monthDiff = dateA.month() - dateB.month();
            const dayDiff = dateA.date() - dateB.date();
            
            if (monthDiff !== 0) {
              return monthDiff;
            }
            
            return dayDiff;
          })
          .map((holiday, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
              <label>{holiday.description}</label>
              <label>{moment(holiday.date).format("MMM Do YY")}</label>
            </div>
          ))}

        <h4>Dynamic holidays</h4>
        {holidays
          .filter((holiday) => holiday.type === HolidayType.Dynamic)
          .map((holiday, index) => {
            const disableSaveButton =
              holiday.date &&
              (moment(holiday.date).startOf("day").isBetween(startDate, endDate) ||
                moment(new Date())
                  .startOf("day")
                  .isSameOrAfter(moment(holiday.date).startOf("day")));

            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ minWidth: 130 }}>
                  <label>{holiday.description}</label>
                </div>
                <div style={{ padding: 2 }}>
                  <DayPickerInput
                    value={holiday.date ? moment(holiday.date).format("MM/DD/YYYY") : undefined}
                    onDayChange={(date: Date) => handleDayChange(date, holiday.name)}
                    keepFocus={false}
                    inputProps={{ style: { width: 100, textAlign: "center" } }}
                    placeholder="MM/DD/YYYY"
                  />
                </div>
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <Button
                    variant="contained"
                    onClick={() => handleOnSave(holiday.id)}
                    disabled={disableSaveButton}>
                    Save
                  </Button>
                </div>
              </div>
            );
          })}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default HolidaysRow;
