import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "dataexports";

export default {
  getDataExport(query, contentType) {
    const url = `${baseUrl}/${endpoint}`;
    return msalApiFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: contentType,
      },
      body: JSON.stringify(query),
    }).then((response) => response.blob());
  },
};
