import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { FacilityDetail } from "types/facility/facilityDetail";

type FacilityDetailTextProps = {
  facility: FacilityDetail;
};

const Field = ({ label, text }: { label: string; text: string }) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography style={{ fontWeight: 600, color: "#7D7D7D", fontSize: "0.75rem" }}>
        {label}
      </Typography>
      <Typography style={{ fontWeight: 500, color: "#414141", fontSize: "0.95rem" }}>
        {text}
      </Typography>
    </Box>
  );
};

export const FacilityDetailText = ({ facility }: FacilityDetailTextProps) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Typography style={{ fontWeight: 500, fontSize: "1.3rem", textAlign: "center" }}>
        {facility.name}
      </Typography>

      <Box style={{ width: "80%", marginTop: 10, marginBottom: 10 }}>
        <Divider />
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Field label="City" text={`${facility.city}, ${facility.state}`} />
      </Box>
    </Box>
  );
};
