import { useState, useEffect } from "react";
import { TimesheetStatus } from "commons/Enums";

function getHighlightedText(status: string|undefined|null, hasEditRequest: boolean) {
  if (hasEditRequest) {
    return "requested for editing";
  }

  return status?.toLowerCase();
}

const useHighlightIcon = (timesheet: any)=>{
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState<string|undefined>(undefined);
  const [iconColor, setIconColor] = useState<string|undefined>(undefined);

  useEffect(() => {
    if (timesheet) {
      setShowTooltip(timesheet.status === TimesheetStatus.RETURNED ||
            timesheet.status === TimesheetStatus.REJECTED ||
            timesheet.hasEditRequest);
    
      setTooltipText(`This timecard has been ${getHighlightedText(timesheet.status, timesheet.hasEditRequest)}`);

      setIconColor(timesheet.hasEditRequest ? "#FFA44F" : undefined);
    } else {
      setShowTooltip(false);
      setTooltipText(undefined);
      setIconColor(undefined);
    }
  }, [timesheet]);

  return { showTooltip, tooltipText, iconColor };
};

export default useHighlightIcon;
