import { createStore } from "redux";
import rootReducer from "./reducers";

const store = createStore(
  rootReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

declare global {
  // eslint-disable-next-line no-undef
  type RootState = ReturnType<typeof store.getState>;
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  type AppDispatch = typeof store.dispatch
}

export default store;
