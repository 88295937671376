import { UpdateUserInfo, UserRoleInformation, UsersTableItem } from "types/users";
import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "getmvpusers";

export const getMvpUsersApi = async () => {
  const url = `${baseUrl}/${endpoint}`;
  return msalApiFetch(url).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

// TODO: modify when the endpoint is ready
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDTUsers = async (
  name = "",
  email = "",
  searchByPayPeriod = false
): Promise<UsersTableItem[]> => {
  const url = `${baseUrl}/users/digitalTimecard`;

  return msalApiFetch(url, {
    body: JSON.stringify({ name: name, email: email, searchByPayPeriod: searchByPayPeriod }),
    method: "POST",
  }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const getBPPUsers = async (): Promise<UsersTableItem[]> => {
  const url = `${baseUrl}/users/billAndPayPortal`;
  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const getUserInfo = async (email: string): Promise<UserRoleInformation> => {
  const url = `${baseUrl}/users/${email}/info`;
  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

export const updateUserRole = async (request: UpdateUserInfo) => {
  const url = `${baseUrl}/users/billAndPayPortal/changeInfo`;

  return msalApiFetch(url, {
    method: "POST",
    body: JSON.stringify({ ...request }),
  });
};

export const resetDTUser = async (externalId: string) => {
  const url = `${baseUrl}/users/digitalTimecard/${externalId}/reset`;
  return await msalApiFetch(url, { method: "POST" });
};
