import { createTheme, responsiveFontSizes } from "@mui/material";

const baseTheme = createTheme({
  palette: {
    primary: {
      light: "#7569b2",
      main: "#514689",
      dark: "#332c56",
    },
    secondary: {
      main: "#00A3E1",
    },
    error: {
      main: "#A63E29",
    },
    success: {
      main: "#86C34E",
    },
    //gray
    action: { active: "#53565A" },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 13,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

const theme = responsiveFontSizes(baseTheme);
export default theme;
