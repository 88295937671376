import { sendDocumentsToReferrer, sendEmailReminderToReferrer } from "api/ReferralsApi";
import { useSnackbar } from "notistack";
import { useState } from "react";

export default function useReferalsDocument(fixedDealId?: string) {
  const [isDocumentsDialogOpen, setIsDocumentsDialogOpen] = useState({
    open: false,
    dealId: fixedDealId ?? "",
    referrerEmail: "",
  });
  const [isEmailReminderDialogOpen, setIsEmailReminderDialogOpen] = useState({
    open: false,
    dealId: fixedDealId ?? "",
    referrerEmail: "",
  });
  const snackbar = useSnackbar();

  const toggleDocumentsDialogOn = (dealId = fixedDealId, referrerEmail: string) => {
    if (!dealId) {
      throw new Error("dealId is required");
    }

    setIsDocumentsDialogOpen({ open: true, dealId, referrerEmail });
  };

  const toggleEmailReminderDialogOff = () => {
    setIsEmailReminderDialogOpen({ open: false, dealId: "", referrerEmail: "" });
  };

  const toggleEmailReminderDialogOn = (dealId = fixedDealId, referrerEmail: string) => {
    if (!dealId) {
      throw new Error("dealId is required");
    }

    setIsEmailReminderDialogOpen({ open: true, dealId, referrerEmail });
  };

  const toggleDocumentsDialogOff = () => {
    setIsDocumentsDialogOpen({ open: false, dealId: "", referrerEmail: "" });
  };

  const handleSendDocumentsToReferrer = async (email: string) => {
    try {
      await sendDocumentsToReferrer(isDocumentsDialogOpen.dealId, email);
      snackbar.enqueueSnackbar("Documents sent to referrer", { variant: "success" });
    } catch (error) {
      snackbar.enqueueSnackbar("Error sending documents to referrer", { variant: "error" });
      throw error;
    }
  };

  const handleEmailReminderToReferrer = async (email: string) => {
    try {
      await sendEmailReminderToReferrer(isEmailReminderDialogOpen.dealId, email);
      snackbar.enqueueSnackbar("Email reminder sent to referrer", { variant: "success" });
    } catch (error) {
      snackbar.enqueueSnackbar("Error sending email reminder to referrer", { variant: "error" });
      throw error;
    }
  };

  return {
    toggleDocumentsDialogOff,
    toggleDocumentsDialogOn,
    isDocumentsDialogOpen,
    handleSendDocumentsToReferrer,
    isEmailReminderDialogOpen,
    toggleEmailReminderDialogOff,
    toggleEmailReminderDialogOn,
    handleEmailReminderToReferrer,
  };
}
