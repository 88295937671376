import PropTypes from "prop-types";
import usePermissions from "../../../hooks/usePermissions";
import { useFeatureFlags } from "../../../commons/Features";
import React from "react";

/**
 * @param {import("../../../types/bppFeatureFlag").BppFeatureFlags} featureFlagName
 * @param {import("../../../types/bppPermission").BppPermission} permissionName
 * @param {boolean} permissionFounded
 * @param {boolean} featureFlagFounded
 */
const AllowToRender = (
  permissionName,
  featureFlagName,
  permissionFounded,
  featureFlagFounded,
  children
) => {
  if (permissionName && featureFlagName && permissionFounded && featureFlagFounded) return children;

  if (!permissionName && featureFlagName && featureFlagFounded) return children;

  if (!featureFlagName && permissionName && permissionFounded) return children;

  return null;
};

/**
 * Allows to render a child node if the provided parameters Permission or Feature Flag are on.
 * @param {object} props
 * @param {import("../../../types/bppFeatureFlag").BppFeatureFlags} [props.featureFlagName]
 * @param {import("../../../types/bppPermission").BppPermission} [props.permissionName]
 */
const CanRenderChildren = ({ permissionName, featureFlagName, children }) => {
  const permissionFounded = usePermissions()[permissionName];

  const featureFlagFounded = useFeatureFlags()[featureFlagName];

  if (Array.isArray(children) && children.length > 0) {
    const childrensAllowedToRender = children.every(
      (item) =>
        AllowToRender(
          permissionName,
          featureFlagName,
          permissionFounded,
          featureFlagFounded,
          item
        ) !== null
    );

    if (!childrensAllowedToRender) {
      return null;
    }

    return children;
  } else if (!children || !React.isValidElement(children)) {
    return null;
  } else {
    return AllowToRender(
      permissionName,
      featureFlagName,
      permissionFounded,
      featureFlagFounded,
      children
    );
  }
};

export default CanRenderChildren;

CanRenderChildren.propTypes = {
  permissionName: PropTypes.string,
  featureFlagName: PropTypes.string,
  children: PropTypes.node,
};
