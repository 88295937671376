import { TablePagination } from "@mui/material";
import React from "react";

type TableFooterProps = {
  data: unknown[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const BPPTableFooter = ({
  data,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: TableFooterProps) => {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      showFirstButton
      showLastButton
    />
  );
};

export default BPPTableFooter;
