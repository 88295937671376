import PayrollLocksApi from "api/PayrollLocksApi";
import LoggedUser from "commons/LoggedUser";
import { parseErrorResponse } from "commons/utils";
import { LockType } from "types/payPeriod/lockType";

const ERROR_LOCKING = "An error occurred while locking the payroll.";

export async function lockTimecardsPayrollReport(lockType: LockType, alertFn: (msg: string) => void): Promise<void> {
  if (
    LoggedUser.isRecruiter() ||
    LoggedUser.isSalesRep() ||
    LoggedUser.isRecruiterTeamLead() ||
    LoggedUser.isAssistant()
  )
    return;

  try {
    await PayrollLocksApi.UpdatePayrollLockStatus(lockType, "locked");
    alertFn("Payroll locked.");
  }
  catch (error: any) {
    const errorMessage = await parseErrorResponse(error, ERROR_LOCKING);
    alertFn(errorMessage);
  }
}
