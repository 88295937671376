import React from "react";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./TimecardForwardingDialog.css";

const TimecardForwardingDialog = (props) => {
  const { open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  const fordwardInfo =
    props.forwardingHistory != null ? (
      props.forwardingHistory.map((h, index) => {
        const modalContent = h.sentTo.split(",").map((email) => {
          if (email) {
            return (
              <div className="forward-detail" key={index}>
                <div className="forward-email">{email}</div>
                <div className="forward-date">
                  {moment.utc(h.sentDate).local().format("MM/DD/YYYY hh:mm:ss a")}
                </div>
                <div className="forward-detail-status-icon">
                  <Tooltip title={h.status} placement="top-start">
                    <Avatar
                      className={
                        h.status === "Late"
                          ? "file-forwared-indicator late"
                          : "file-forwared-indicator on-time"
                      }
                    >
                      <i className="material-icons-outlined">forward_to_inbox</i>
                    </Avatar>
                  </Tooltip>
                </div>
              </div>
            );
          }
        });
        return modalContent;
      })
    ) : (
      <div>No info</div>
    );
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">{"Forward History"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={"span"}>
            {fordwardInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default TimecardForwardingDialog;
