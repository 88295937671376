import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";

type TimecardRowInformationProps = {
  informationText: string;
  informationValue: string;
};
const TimecardRowInformation = ({
  informationText,
  informationValue,
}: TimecardRowInformationProps): JSX.Element => {
  return (
    <>
      <InfoOutlinedIcon
        htmlColor="#50478b"
        style={{
          display: "inline-flex",
          marginRight: "5px",
          verticalAlign: "middle",
          marginLeft: "5px",
          width: "16px",
          height: "16px",
        }}
      />
      <Typography variant="caption" component="span">
        {informationText}
      </Typography>
      <Typography
        variant="caption"
        component="span"
        style={{
          color: "#50478b",
          fontWeight: "bold",
        }}>
        {informationValue}
      </Typography>
    </>
  );
};

export default TimecardRowInformation;
