import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { ReferralsDownloadOptions } from "types/referrals";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import { ReferralsDownloadType, ReferralsPaymentType } from "commons/Enums";

interface DropDownButtonProps {
  options: ReferralsDownloadOptions[];
  buttonText: string;
  disabled?: boolean;
  paymentType: ReferralsPaymentType;
}

export default function DropdownButton({
  options,
  buttonText,
  disabled = false,
  paymentType,
}: DropDownButtonProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  function openModal(): void {
    setIsModalOpen(true);
  }

  function closeModal(): void {
    setIsModalOpen(false);
  }

  function onConfirmModal(): void {
    if (selectedIndex !== undefined) {
      options[selectedIndex]
        .onDownload(
          selectedIndex === 0
            ? ReferralsDownloadType.DownloadAll
            : ReferralsDownloadType.DownloadSelected,
          true,
          paymentType
        )
        .catch((e) => console.log(e));
    }
  }

  function onCancelModal(): void {
    closeModal();
    if (selectedIndex !== undefined) {
      options[selectedIndex]
        .onDownload(
          selectedIndex === 0
            ? ReferralsDownloadType.DownloadAll
            : ReferralsDownloadType.DownloadSelected,
          false,
          paymentType
        )
        .catch((e) => console.log(e));
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number): void => {
    setOpen(false);
    openModal();
    setSelectedIndex(index);
  };

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent): void => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        dialogTitle={buttonText}
        content="Do you want to mark these travelers as paid?"
        isOpen={isModalOpen}
        onConfirm={onConfirmModal}
        onClose={closeModal}
        onCancel={onCancelModal}></ConfirmDialog>
      <div style={{ paddingRight: 10 }}>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="button">
          <Button
            onClick={handleToggle}
            variant="contained"
            disabled={disabled}
            style={{
              color: disabled ? "inherit" : "white",
              backgroundColor: disabled ? "#E0E0E0" : "#514689",
            }}>
            {buttonText}
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          style={{ zIndex: 50 }}>
          {({ TransitionProps, placement }): JSX.Element => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        disabled={option.disabled}
                        onClick={(event) => handleClick(event, index)}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
}
