import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { Note } from "types/notes";

type NotesPanelContentProps = {
  notesList: Note[][];
};

const NotesPanelContent = ({ notesList }: NotesPanelContentProps): JSX.Element => {
  return (
    <>
      {notesList.length === 0 ? (
        <Typography>No notes to display</Typography>
      ) : (
        notesList.map((notesGroup, index) => (
          <Box key={index} display={"flex"}>
            <Box display={"flex"} flexDirection={"column"} minWidth={"100px"}>
              <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                {notesGroup[0].userType}
              </Typography>
              {notesGroup[0].createdByFirstName && notesGroup[0].createdByLastName && (
                <Typography style={{ fontSize: "12px", color: "#53565A" }}>
                  {`${notesGroup[0].createdByFirstName} ${notesGroup[0].createdByLastName}`}
                </Typography>
              )}
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"100%"} paddingLeft={5}>
              {notesGroup.map((note, index) => (
                <Box key={note.noteId + index} display={"flex"} alignItems={"baseline"} pb={2}>
                  <Box>
                    <Typography style={{ fontSize: "15px" }}>{note.contents}</Typography>
                    {note.subContents && (
                      <Box display={"flex"} alignItems={"baseline"} pb={1}>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#414141",
                          }}>
                          &#9495; {note.subContents}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box style={{ marginLeft: "auto" }}>
                    <Typography
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingRight: "10px",
                        color: "#53565A",
                      }}>
                      {moment.utc(note.createdDateUtc).local().format("MM/DD/YY h:mm a")}
                    </Typography>
                  </Box>
                </Box>
              ))}
              {index !== notesList.length - 1 && (
                <Divider variant="middle" style={{ marginBottom: 15, width: "75%" }} />
              )}
            </Box>
          </Box>
        ))
      )}
    </>
  );
};

export default NotesPanelContent;
