import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import moment from "moment";
import CandidateSelector from "./CandidateSelector";
import AssignmentsList from "./AssignmentsList";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/material/LinearProgress";
import TimesheetApi from "../../../api/TimesheetApi";
import PayrollApi from "../../../api/PayrollApi";
import { ErrorMessages } from "../../../commons/Enums";

type NewTimecardDialogProps = {
  isAdjustment: boolean;
  currentRecruiterId: string;
  onClose: (_: boolean, id?: string) => void;
  open: boolean;
};

type Candidate = {
  label: string;
  value: string;
};

type NewTimecardDialogState = {
  selectedCandidate: Candidate | null;
  selectedAssignmentId: string | null;
  timecardId: string | null;
};

const NewTimecardDialog = (props: NewTimecardDialogProps) => {
  const [newTimecardState, setNewTimecardState] = useState<NewTimecardDialogState>({
    selectedCandidate: null,
    selectedAssignmentId: null,
    timecardId: null,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const snackbar = useSnackbar();

  const onCandidateSelected = (candidate: Candidate) => {
    setNewTimecardState((curr) => ({
      ...curr,
      selectedCandidate: candidate,
      selectedAssignmentId: null,
    }));
  };

  const onAssignmentSelected = (assignmentId: string, timecardId: string) => {
    setNewTimecardState((curr) => ({ ...curr, selectedAssignmentId: assignmentId, timecardId }));
  };

  const createNewTimecard = () => {
    const curr = new Date();
    const startDate = moment(new Date(curr.setDate(curr.getDate() - curr.getDay()))).subtract(
      7,
      "days"
    );
    const endDate = moment(new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))).subtract(
      7,
      "days"
    );

    if (props.isAdjustment === true) {
      TimesheetApi.createNewAdjustmentTimecard(newTimecardState.selectedAssignmentId)
        .then((result) => {
          props.onClose(true, result.timeSheetId.toUpperCase());
        })
        .catch(() => {
          setSaving(false);
          snackbar.enqueueSnackbar("An error occurred adding the new traveler.");
        });
    } else {
      TimesheetApi.createNewTimecard(newTimecardState.selectedAssignmentId, startDate, endDate)
        .then((result) => {
          props.onClose(true, result.timeSheetId.toUpperCase());
        })
        .catch(() => {
          setSaving(false);
          snackbar.enqueueSnackbar("An error occurred adding the new traveler.");
        });
    }
  };

  const validateAndCreateTimecard = () => {
    setSaving(true);
    if (props.isAdjustment === false && newTimecardState.selectedAssignmentId) {
      PayrollApi.validateLockRecruiterPayroll(newTimecardState.selectedAssignmentId).then(
        (isLocked) => {
          if (isLocked) {
            snackbar.enqueueSnackbar(ErrorMessages.LockedPayrollMessage);
            setSaving(false);
            return;
          }
          createNewTimecard();
        }
      );
    } else {
      TimesheetApi.valdiateNewAdjustmentTimecard(newTimecardState.selectedAssignmentId)
        .then((isValid) => {
          if (!isValid) {
            snackbar.enqueueSnackbar(ErrorMessages.DuplicatedOrInvalidTimecarAdjustment);
            setSaving(false);
            return;
          }
          createNewTimecard();
        })
        .catch(() => {
          snackbar.enqueueSnackbar(ErrorMessages.DuplicatedOrInvalidTimecarAdjustment);
          setSaving(false);
        });
    }
  };

  const close = () => {
    props.onClose(false);
  };

  useEffect(() => {
    if (props.open) {
      setNewTimecardState({
        selectedCandidate: null,
        selectedAssignmentId: null,
        timecardId: null,
      });
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      className={"new-timecard-dialog"}>
      <DialogTitle id="form-dialog-title">
        {props.isAdjustment ? "Add Adjustment" : "Add Traveler"}
      </DialogTitle>
      <DialogContent>
        {saving && <LinearProgress variant="query" />}
        <DialogContentText>Select a traveler and an assignment.</DialogContentText>
        <CandidateSelector
          onSelected={onCandidateSelected}
          isAdjustment={props.isAdjustment}
          currentRecruiterId={props.currentRecruiterId}
        />
        <div className="new-timecard-assignments-list-container">
          {newTimecardState.selectedCandidate && (
            <AssignmentsList
              candidateId={newTimecardState.selectedCandidate.value}
              candidateName={newTimecardState.selectedCandidate.label}
              onAssignmentSelected={onAssignmentSelected}
              selectedAssignmentId={newTimecardState.selectedAssignmentId}
              isAdjustment={props.isAdjustment}
              currentRecruiterId={props.currentRecruiterId}></AssignmentsList>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!newTimecardState.selectedAssignmentId || saving}
          onClick={validateAndCreateTimecard}
          color="primary">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewTimecardDialog;
