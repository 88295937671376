import React, { SyntheticEvent, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useFilterAdvancedSearch, usePermissions } from "hooks";
import { msalInstance } from "commons/authConfig";
import { getRecruiters } from "api/advancedSearchApi";
import { RecruiterState } from "types/recruiter";

type RecruiterSearchProps = {
  currentRecruiter?: RecruiterState;
  handleChange: (event: SyntheticEvent<Element, Event>, newValue: RecruiterState) => void;
};

const RecruiterSearch = ({ currentRecruiter, handleChange }: RecruiterSearchProps) => {
  const [entities, loading, handleInputChange, isOptionSelected] = useFilterAdvancedSearch(
    getRecruiters,
    (option, value) =>
      `${option.firstName}|${option.lastName}|${option.recruiterIds?.join("|")}` ===
      `${value.firstName}|${option.lastName}|${value.recruiterId?.join("|")}`
  );

  const { canFilterByRecruiterAdvancedSearch } = usePermissions();

  const getOptionLabel = useCallback((option: RecruiterState) => {
    return option ? `${option.firstName} ${option.lastName}` : "";
  }, []);

  const label = canFilterByRecruiterAdvancedSearch
    ? "Recruiter"
    : msalInstance.getActiveAccount()?.idTokenClaims?.name;

  const renderSearchInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label={label}
        variant="outlined"
        disabled={!canFilterByRecruiterAdvancedSearch}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [loading, canFilterByRecruiterAdvancedSearch, label]
  );

  return (
    <Autocomplete
      id="recruiterSearch"
      size="small"
      value={currentRecruiter ?? null}
      getOptionLabel={getOptionLabel}
      options={entities}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleChange}
      disabled={!canFilterByRecruiterAdvancedSearch}
      renderInput={renderSearchInput}
      isOptionEqualToValue={isOptionSelected}
    />
  );
};

export default RecruiterSearch;
