import React from "react";
import FileUpdateAndDownload from "../shared/components/FileUpdateAndDownload";

type FacilityReportFileProps = {
  facilityReportUploadId: string;
  timesheetId: string;
  readonly: boolean;
  isDigitalTimecardEntry: boolean;
  onFileUploaded: (file: FileList) => void;
  onFilesAdded: (file: FileList) => void;
  uploading: number;
};

function FacilityReportFile(props: FacilityReportFileProps) {
  let downloadUrl = null;

  if (props.facilityReportUploadId) {
    downloadUrl = `${process.env.REACT_APP_API_URL}/timesheet/facilityReport/${props.timesheetId}`;
  }

  return (
    <FileUpdateAndDownload
      downloadUrl={downloadUrl}
      noFileMessage="No facility report has been uploaded"
      fileName="Facility Report File"
      icon="local_hospital"
      isFacilityReport={true}
      {...props}
    />
  );
}

export default FacilityReportFile;
