import {
  PROFILE_TABLE_CHANGED,
  PROFILE_TABLE_SWITCH_NETSUITE_CHANGED,
} from "../actions/actionTypes";

export default function expenseTableReducer(state = { profile: null, switchState: false }, action) {
  switch (action.type) {
    case PROFILE_TABLE_CHANGED: {
      const newState = { ...state };
      newState.profile = action.profile;

      return newState;
    }
    case PROFILE_TABLE_SWITCH_NETSUITE_CHANGED: {
      const newState = { ...state };
      newState.switchState = action.switchState;

      return newState;
    }
    default:
      return state;
  }
}
