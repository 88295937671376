import React from "react";
import Note from "./Note";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";

function NotesList({ notes, noteTypeFilter }) {
  return (
    <List>
      {notes &&
        notes
          .filter((note) => !noteTypeFilter || note.noteType === noteTypeFilter)
          .map((note) => (
            <div key={"div" + (note.noteId ?? note.id)}>
              <ListItem key={"item" + (note.noteId ?? note.id)}>
                <ListItemAvatar>
                  <Avatar alt={note.createdByFirstName + " " + note.createdByLastName}>
                    {(note.createdByFirstName + " " + note.createdByLastName)
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </Avatar>
                </ListItemAvatar>
                <Note note={note} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          ))}
    </List>
  );
}

export default NotesList;
