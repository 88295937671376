import { msalApiFetch } from "../commons/authConfig";
import moment from "moment";
import { TimesheetSubStatusMap } from "commons/Enums";
import { FacilitiesState } from "redux/reducers/advancedSearchReducer";
import { RecruiterState } from "types/recruiter";
import { TravelerState } from "types/traveler";

const baseUrl = process.env.REACT_APP_API_URL;

export type AdvancedSearchQuery = {
  pageSize: number;
  page: number;
  orderBy?: { field: string; title: string };
  orderDirection: string;
  filters: {
    startDate: string;
    endDate: string;
    traveler?: TravelerState;
    recruiter?: RecruiterState;
    facilities?: FacilitiesState[];
    details?: string[];
    states?: string[];
  };
};

function createSearchBody(query: AdvancedSearchQuery) {
  const filters = query.filters;

  const defaultSearch = {
    startDate: null,
    endDate: null,
    travelerIds: "",
    recruiterIds: "",
    facilitiesIds: [],
    details: [],
    states: [],
  };

  // substatus are managed 0,1,... in bpp api (enum typed in ui)
  const mappedStates = query.filters.states?.map((st) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (Object.values(TimesheetSubStatusMap).includes(st as any)) {
      return Object.keys(TimesheetSubStatusMap).find((key) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (TimesheetSubStatusMap as any)[key].includes(st)
      );
    }
    return st;
  });

  const userFilters = Object.assign(defaultSearch, {
    startDate: moment(new Date(filters.startDate)).format("YYYY-MM-DD"),
    endDate: moment(new Date(filters.endDate)).format("YYYY-MM-DD"),
    travelerIds: filters.traveler?.travelerIds ?? [],
    recruiterIds: filters.recruiter?.recruiterIds ?? [],
    facilitiesIds: filters.facilities?.flatMap((f) => f.facilitiesId) ?? [],
    details: filters.details ?? [],
    states: mappedStates,
  });

  const body = {
    pagination: {
      pageSize: query.pageSize,
      pageNumber: query.page,
    },
    sort: {
      orderBy: query.orderBy?.field ?? null,
      orderByDirection: query.orderDirection !== "" ? query.orderDirection : null,
    },
    filters: userFilters,
  };
  return body;
}

export const makeSearch = async (query: AdvancedSearchQuery) => {
  const body = createSearchBody(query);
  const url = `${baseUrl}/advanced-search`;

  return msalApiFetch(url, { body: JSON.stringify(body), method: "POST" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

/**
 * @param {string} travelerName
 */
export const getTravelers = async (travelerName: string) => {
  const url = `${baseUrl}/advanced-search/travelers/${travelerName}`;

  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

/**
 * @param {string} recruiterName
 */
export const getRecruiters = async (recruiterName: string) => {
  const url = `${baseUrl}/advanced-search/recruiters/${recruiterName}`;

  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

/**
 * @param {string} facilityName
 */
export const getFacilities = async (facilityName: string) => {
  const url = `${baseUrl}/advanced-search/facilities/${facilityName}`;

  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};

/**
 * @param {import("material-table").Query} query
 */
export const downloadCsv = async (query: AdvancedSearchQuery) => {
  const body = createSearchBody(query);
  const url = `${baseUrl}/advanced-search/report`;

  const response = await msalApiFetch(url, { method: "POST", body: JSON.stringify(body) });
  if (!response.ok) throw response;
  return await response.blob();
};

export const getTimesheetStates = async () => {
  const url = `${baseUrl}/timesheet-states`;

  return msalApiFetch(url, { method: "GET" }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
};
