import {
  TextField,
  InputAdornment,
  Tooltip,
  FormControl,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
} from "@mui/material";
import React from "react";
import { DefaultFilterProps, LookupFilterProps, OnChangeFilterType, Column } from "./Types";
import FilterIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";

export const DefaultFilter = <U extends string>({
  id,
  value,
  handleChange,
}: DefaultFilterProps<U>) => {
  return (
    <TextField
      key={id}
      variant="standard"
      value={value || ""}
      onChange={(event) => {
        handleChange(id, event.target.value);
      }}
      inputProps={{ "aria-label": `filter data by ${id}` }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title="Filter">
              <FilterIcon />
            </Tooltip>
          </InputAdornment>
        ),
        endAdornment: !!value && (
          <IconButton onClick={() => handleChange(id, "")} style={{ padding: 0 }} size="large">
            <ClearIcon style={{ width: 15, height: 15, padding: 0 }} />
          </IconButton>
        ),
      }}
    />
  );
};

export const LookupFilter = <U extends string>({
  id,
  value,
  lookup,
  onFilterChanged,
}: LookupFilterProps<U>) => {
  const lookupKeys = Object.keys(lookup);
  const lookupValues = Object.values(lookup);

  return (
    <FormControl style={{ width: "100%" }}>
      <Select
        multiple
        value={value || []}
        onChange={(event) => {
          onFilterChanged(
            id,
            (event.target.value as string[]).map((x) => lookupValues[lookupKeys.indexOf(x)])
          );
        }}
        input={<Input id={"select-multiple-checkbox" + id} />}
        renderValue={(selecteds) =>
          (selecteds as string[]).length === 1
            ? (selecteds as string[])[0]
            : `${(selecteds as string[]).length} selected`
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 225,
              width: 250,
            },
          },
        }}
        style={{ marginTop: 0 }}>
        {lookupKeys.map((key) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={(value || []).indexOf(key.toString()) > -1} />
            <ListItemText primary={key} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const getFilter = <U extends string>(
  column: Column<U, never>,
  filters: string | string[],
  handleChangeFilter: OnChangeFilterType<U>
) => {
  if (column.lookup !== undefined) {
    return (
      <LookupFilter
        id={column.id}
        value={filters as string[]}
        lookup={column.lookup}
        onFilterChanged={handleChangeFilter}
      />
    );
  }

  return (
    <DefaultFilter id={column.id} value={filters as string} handleChange={handleChangeFilter} />
  );
};
