import { APP_SETTINGS_UPDATED } from "../actions/actionTypes";

export type AppSettings = {
  advancedSearchMaxRowsExport: number;
};

type AppSettingsUpdatedAction = {
  type: string;
  appSettings: AppSettings;
};

const initialState: AppSettings = {
  advancedSearchMaxRowsExport: 10000,
};

export default function appSettingsReducer(
  state = initialState,
  action: AppSettingsUpdatedAction
): AppSettings {
  switch (action.type) {
    case APP_SETTINGS_UPDATED: {
      return action.appSettings;
    }

    default:
      return state;
  }
}
