import React from "react";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import PayrollApi from "api/PayrollApi";
import { downloadBlob } from "commons/utils";

type FileAttachedIconProps = {
  filled: boolean;
  timesheetId: string;
};

export default function FacilityReportAttachedIcon({ timesheetId, filled }: FileAttachedIconProps) {
  function downloadFile(url: string, fileName: string) {
    PayrollApi.downloadFile(url)
      .then((blob) => {
        const extension = blob.type.split("/")[1];
        downloadBlob(new Blob([blob]), `${fileName}.${extension}`).catch((e) => console.error(e));
      })
      .catch((error) => {
        alert(error);
      });
  }

  function downloadFacilityReport() {
    const url = `${process.env.REACT_APP_API_URL}/timesheet/facilityReport/${timesheetId}`;

    downloadFile(url, "Facility Report File");
  }

  return (
    <IconButton
      disabled={!filled}
      sx={{
        padding: "0!important",
        margin: "0",
      }}
      onClick={downloadFacilityReport}
      size="large"
      data-testid="facility-report-attached-icon">
      <Tooltip title="A facility report has been uploaded" placement="top">
        <Avatar
          className={`file-attached-indicator ${filled ? "filled" : ""}`}>
          <i className="material-icons-outlined">local_hospital</i>
        </Avatar>
      </Tooltip>
    </IconButton>
  );
}
