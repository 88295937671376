import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "billing";

export default {
  billingUpload(billingUploadData) {
    const url = `${baseUrl}/${endpoint}/uploads`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(billingUploadData),
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },
};
