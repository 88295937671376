import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "recruiter";

export default {
  getRecruiters() {
    const url = `${baseUrl}/${endpoint}`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },
};
