import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FacilityDetail } from "types/facility/facilityDetail";
import { FormErrors } from "types/facility/facilityFormErrors";

type AdditionalDocumentsProps = {
  formErrors: FormErrors;
  facility: FacilityDetail;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => void;
};

export const AdditionalDocuments = ({
  formErrors,
  facility,
  handleChange,
}: AdditionalDocumentsProps) => {
  return (
    <FormControl
      sx={{ mt: 1 }}
      variant="outlined"
      error={formErrors.documentTypeHasError}
      style={{ width: "30%" }}>
      <InputLabel id="document-required-day-label">Document Required</InputLabel>
      <Select
        labelId="document-required-day-label"
        id="document-required"
        value={facility.documentType}
        onChange={handleChange}
        label="Document Required"
        fullWidth
        name="documentType">
        {facility.documentTypes.map((documentType) => (
          <MenuItem key={documentType.id} value={documentType.id}>
            {documentType.name}
          </MenuItem>
        ))}
      </Select>

      {formErrors.documentTypeHasError && <FormHelperText>Document is required.</FormHelperText>}
    </FormControl>
  );
};
