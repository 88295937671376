import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "analytics";

export default {
  getData() {
    const url = `${baseUrl}/${endpoint}/data`;
    return msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getDataItem(itemName) {
    const url = `${baseUrl}/${endpoint}/data/${itemName}`;
    return msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
};
