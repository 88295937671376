import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getRoles, msalInstance } from "../../../commons/authConfig";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, roles: allowedRoles, featureFlags, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = msalInstance.getActiveAccount();
      const userRoles = getRoles(user.idTokenClaims.roles);
      if (userRoles.length === 0) {
        return <Redirect to={{ pathname: "/unauthorized" }} />;
      }

      allowedRoles = getRoles(allowedRoles);

      if (allowedRoles.length === 0) {
        return <Component featureFlags={featureFlags} {...props} {...rest} />;
      } else {
        const matchRole = allowedRoles.filter((allowedRole) =>
          getRoles(userRoles).includes(allowedRole)
        );

        if (matchRole.length === 0) {
          // role not authorized so redirect to unauthorized
          return <Redirect to={{ pathname: "/unauthorized" }} />;
        }

        // authorized so return component
        return <Component featureFlags={featureFlags} {...props} {...rest} />;
      }
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  roles: PropTypes.array,
  featureFlags: PropTypes.array,
};

export default PrivateRoute;
