import { msalApiFetch } from "commons/authConfig";
import { Manager } from "types/facility/managers";
import { API_URL } from "commons/environment";
import { ManagerForTimesheet } from "types/timesheet/timesheetManager";

const endpoint = "managers";

export function getManagersByFacilityId(facilityId: string): Promise<Manager[]> {
  const url = `${API_URL}/${endpoint}/${facilityId}`;
  return msalApiFetch(url, {
    method: "GET",
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}

export async function createManager(facilityId: string, manager: Manager) {
  const url = `${API_URL}/${endpoint}/${facilityId}/addManager`;
  return await msalApiFetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...manager }),
  });
}

export async function updateManager(facilityId: string, manager: Manager, oldManagerEmail: string) {
  const url = `${API_URL}/${endpoint}/${facilityId}/updateManager`;
  return await msalApiFetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ manager, email: oldManagerEmail }),
  });
}

export function getManagersByFacilityAndTimesheetId(
  facilityId: string,
  timesheetId: string
): Promise<ManagerForTimesheet[]> {
  const url = `${API_URL}/${endpoint}/${facilityId}/timesheet/${timesheetId}`;
  return msalApiFetch(url, {
    method: "GET",
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}
