import { ReferralTableItem, ReferralsUKGResponse } from "types/referrals";
import { msalApiFetch } from "../commons/authConfig";
import { API_URL } from "commons/environment";

const ukgEndpoint = "referrals/ukg-export";
const netSuiteEndpoint = "referrals/netsuite-export";

function getDataExport(
  endpoint: string,
  referrals: ReferralTableItem[],
  isFinalDownload: boolean,
  contentType: string
): Promise<ReferralsUKGResponse> {
  const url = `${API_URL}/${endpoint}`;
  const obj = { referrals, isFinalDownload };
  return msalApiFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: contentType,
    },
    body: JSON.stringify(obj),
  }).then((response) => {
    if (!response.ok) throw response;
    return response.json();
  });
}

export function getNetSuiteDataExport(
  referrals: ReferralTableItem[],
  isFinalDownload: boolean,
  contentType: string
): Promise<ReferralsUKGResponse> {
  return getDataExport(netSuiteEndpoint, referrals, isFinalDownload, contentType);
}

export function getUkgDataExport(
  referrals: ReferralTableItem[],
  isFinalDownload: boolean,
  contentType: string
): Promise<ReferralsUKGResponse> {
  return getDataExport(ukgEndpoint, referrals, isFinalDownload, contentType);
}
