import { API_URL } from "commons/environment";
import { msalApiFetch } from "../commons/authConfig";

const baseUrl = API_URL;
const endpoint = "settings";

export default {
  async getSettings() {
    const url = `${baseUrl}/${endpoint}`;
    const result = await msalApiFetch(url);

    if (!result.ok) throw result;

    return result.json();
  },
};
