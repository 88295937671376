import React, { SyntheticEvent, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector, useFilterAdvancedSearch } from "hooks";
import { useDispatch } from "react-redux";
import { ADVANCED_SEARCH_UPDATE_FACILITIES } from "redux/actions/actionTypes";
import { getFacilities } from "api/advancedSearchApi";

export default function FacilitySearch() {
  const dispatch = useDispatch();

  const facilities = useAppSelector((state) => state.advancedSearch.facilities);

  const [entities, loading, handleInputChange, isOptionSelected] = useFilterAdvancedSearch(
    getFacilities,
    (option, value) =>
      `${option.name}|${option.facilitiesId.join("|")}` ===
      `${value.name}|${value.facilitiesId.join("|")}`,
    facilities
  );

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: string[]) => {
    dispatch({
      type: ADVANCED_SEARCH_UPDATE_FACILITIES,
      facilities: newValue,
    });
  };

  const getOptionLabel = useCallback((option) => option.name, []);

  const renderSearchInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label="Facility"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [loading]
  );

  return (
    <Autocomplete
      id="facilitySearch"
      size="small"
      value={facilities}
      getOptionLabel={getOptionLabel}
      options={entities}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleChange}
      multiple={true}
      renderInput={renderSearchInput}
      filterSelectedOptions={true}
      isOptionEqualToValue={isOptionSelected}
    />
  );
}
