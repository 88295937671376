import { TimesheetStatus, NoteType, NoteSource } from "../../commons/Enums";

export default {
  getNoteTypeFromStatus(status) {
    switch (status) {
      case TimesheetStatus.BILLING:
      case TimesheetStatus.APPROVED:
      case TimesheetStatus.FINAL:
        return NoteType.Billing;
      default:
        return NoteType.Payroll;
    }
  },
  getNoteSourceFromNoteType(noteType) {
    switch (noteType) {
      case NoteType.Billing:
        return NoteSource.Billing;
      case NoteType.Expenses:
        return NoteSource.Expenses;
      case NoteType.Adjustment:
        return NoteSource.Adjustment;
      default:
        return NoteSource.Payroll;
    }
  },
  getNoteTypeFilterFromStatus(status) {
    switch (status) {
      case TimesheetStatus.FOR_REVIEW:
      case TimesheetStatus.DRAFT:
      case TimesheetStatus.RETURNED:
      case TimesheetStatus.REJECTED:
      case TimesheetStatus.APPROVED:
        return [NoteType.Payroll, NoteType.Billing, NoteType.Adjustment];
      case TimesheetStatus.BILLING:
        return [NoteType.Payroll, NoteType.Billing];
      default:
        return [NoteType.Payroll, NoteType.Billing];
    }
  },
};
