import React, { useEffect, useState } from "react";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import JobAssignmentApi from "../../api/JobAssignmentApi";
import JobAssignmentHeader from "./JobAssignmentHeader";
import TravelerHistoryTimecards from "./TravelerHistoryTimecards";
import { JobAssignmentSearchHistory } from "types/history/JobAssignmentCardHistory";
import { Box } from "@mui/system";

type TravelerHistoryParams = {
  jobId: string;
};

const TravelerHistory = () => {
  const [jobAssignment, setJobAssignment] = useState<JobAssignmentSearchHistory>();
  const dispatch = useDispatch();
  const { jobId } = useParams<TravelerHistoryParams>();

  const loadJobAssignment = () => {
    JobAssignmentApi.getJobAssignmentById(jobId)
      .then((response) => {
        setJobAssignment(response);
      })
      .catch((error) => {
        console.error({ error });
      });
  };

  useEffect(() => {
    loadJobAssignment();
  }, [jobId]);

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Traveler History" });
  }, []);

  return (
    <Box>
      {!!jobAssignment && (
        <>
          <Box sx={{ backgroundColor: "white" }}>
            <JobAssignmentHeader jobAssignment={jobAssignment} />
          </Box>
          <TravelerHistoryTimecards
            timecards={jobAssignment.jobAssignmentTimecardsHistory}
            refreshJobAssignment={loadJobAssignment}
          />
        </>
      )}
    </Box>
  );
};

export default TravelerHistory;
