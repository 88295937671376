import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;

export default {
  // Api to Send email
  getVmsProfiles() {
    const url = `${baseUrl}/vms/profile`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
};
