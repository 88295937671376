import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "",
    connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    disableFetchTracking: true,
    enableResponseHeaderTracking: true,
  },
});

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags["ai.cloud.role"] = "bpp-ui";
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
