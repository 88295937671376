import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type LateTimecardDialogProps = {
  open: boolean;
  onClose: () => void;
  onLateTimecard: () => void;
};

const LateTimecardDialog = (props: LateTimecardDialogProps) => {
  const {
    open,
    onClose,
    onLateTimecard,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const handleLateTimecard = () => {    
    onLateTimecard();
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Late Timecard?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Did this Traveler have a Late Timecard?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleLateTimecard} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LateTimecardDialog;
