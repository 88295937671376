import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import BillingCommons from "../billing/BillingCommons";
import InvoiceDeliveryMethodLabel from "./InvoiceDeliveryMethodLabel";
import usePermissions from "../../hooks/usePermissions";
import TimeCardHoursTableRow from "./TimecardHoursTableRow";
import { useFeatureFlags } from "../../commons/Features";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import { isExceptionState } from "types/constants";
import { Box } from "@mui/system";
import { AcknowledgeRateChanges } from "./AcknowledgeRateChanges";

type TimecardHoursProps = {
  onTimecardEntriesChange?: () => void;
  onTimecardAdjustmentEntriesChange?: () => void;
  onAddAdjustment?: () => void;
  onRemoveAdjustment?: () => void;
  types?: any[];
  adjustments: any[];
  status?: string;
  invoiceDelivery?: string;
  missedLunchHours?: number;
  isDigitalTimecardEntry?: boolean;
  clientState?: string;
  timesheetId: string;
  hasToAcknowledgeRateChange?: boolean;
  onRateChangesAcknowledged: () => void;
};

const TimecardHours = (props: TimecardHoursProps) => {
  const { canSeeTotalInTimecardHours } = usePermissions();
  const { isAdjustmentsOn } = useFeatureFlags();

  if (!props.types) return <></>;

  const labelProps = {
    value: props.invoiceDelivery,
    disabled: true,
  };

  return (
    <>
      {/* Show Invoice field */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mb={2}>
        {props.hasToAcknowledgeRateChange ? (
          <AcknowledgeRateChanges
            timesheetId={props.timesheetId}
            onRateChangesAcknowledged={props.onRateChangesAcknowledged}
          />
        ) : (
          <Box />
        )}
        <div style={{ textAlign: "right", paddingBottom: "10px" }}>
          <InvoiceDeliveryMethodLabel {...labelProps} />
        </div>
      </Box>
      {/* Show General table */}
      <div>
        <Table
          sx={{
            minWidth: 650,
          }}
          className={" timecard-hours-entry-table"}
          size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">Rate</TableCell>
              <TableCell align="right">Hours</TableCell>
              {canSeeTotalInTimecardHours && (
                <>
                  <TableCell align="right">Total</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.types.map((row) => (
              <Fragment key={"Fragment" + row.name}>
                <TimeCardHoursTableRow
                  key={row.name}
                  row={row}
                  onTimecardEntriesChange={props.onTimecardEntriesChange}
                  onAddAdjustment={props.onAddAdjustment}
                  status={props.status}
                  types={props.types}
                  adjustments={props.adjustments}
                  isAdjustment={false}
                  missedLunchHours={props.missedLunchHours}
                  isDigitalTimecardEntry={props.isDigitalTimecardEntry}
                  isExceptionState={isExceptionState(props.clientState ?? "")}
                />
                <CanRenderChildren featureFlagName="isAdjustmentsOn">
                  {props.adjustments
                    .filter((a) => a.name == row.name)
                    .map((a, index) => (
                      <TimeCardHoursTableRow
                        key={`${row.name}_adjustment_${index}`}
                        row={a}
                        onTimecardAdjustmentEntriesChange={props.onTimecardAdjustmentEntriesChange}
                        onRemoveAdjustment={props.onRemoveAdjustment}
                        status={props.status}
                        adjustments={props.adjustments}
                        isAdjustment={true}
                      />
                    ))}
                </CanRenderChildren>
              </Fragment>
            ))}
            {canSeeTotalInTimecardHours && (
              <>
                <TableRow>
                  <TableCell
                    align="right"
                    colSpan={3}
                    style={{ height: "50px", fontWeight: "bold" }}>
                    Total
                  </TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      prefix={"$ "}
                      value={
                        isAdjustmentsOn
                          ? BillingCommons.getInvoiceSubTotalForPayroll(
                              props.types,
                              props.adjustments
                            )
                          : BillingCommons.getInvoiceSubTotalForPayroll(props.types)
                      }
                      thousandSeparator={true}
                      displayType={"text"}
                      style={{ fontWeight: "bold" }}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default TimecardHours;
