import React, { useEffect, useState } from "react";
import { Box, DialogContentText, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { questionAndAnswer } from "types/referrals/questionAndAnswer";

export const ReferralsFAQDialogContent = () => {
  const questions: questionAndAnswer[] = [
    {
      question: "When will referrers be paid?",
      answer: "Payments are made on Fridays if payments are entered before Wednesday.",
    },
    {
      question: "When are the hours updated?",
      answer: "Hours are refreshed once a timecard is approved.",
    },
  ];

  const [filteredQuestions, setFilteredQuestions] = useState<questionAndAnswer[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setFilteredQuestions(
      questions.filter((item) => {
        return `${item.question.toLowerCase()} ${item.answer.toLowerCase()}`.includes(
          search.toLowerCase()
        );
      })
    );
  }, [search]);

  return (
    <>
      <Box marginBottom={3}>
        <TextField
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
          placeholder="Search..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {filteredQuestions.length === 0 ? (
        <DialogContentText style={{ textAlign: "center" }}>No results found.</DialogContentText>
      ) : (
        filteredQuestions.map((question, index) => (
          <div key={index}>
            <DialogContentText style={{ marginBottom: 0 }}>
              <b>{question.question}</b>
            </DialogContentText>
            <DialogContentText>{question.answer}</DialogContentText>
          </div>
        ))
      )}
    </>
  );
};
