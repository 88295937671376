import React from "react";
import { Manager } from "types/facility/managers";
import "components/shared/components/managers/Managers.css";
import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/CheckCircle";

type ManagerCardProps = {
  manager: Manager;
  handleOnEdit: (manager: Manager) => void;
  handleToggleManager: (manager: Manager) => void;
};

export const ManagerCard = ({ manager, handleOnEdit, handleToggleManager }: ManagerCardProps) => {
  const { name, email, lastSelectedDate, enabled } = manager;

  return (
    <div className="card-container">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        position="relative">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip title={enabled ? "Active" : "Inactive"} placement="top" data-testid = "manager-tooltip-active-inactive">
            <div
              className="enabled-circle"
              style={{ backgroundColor: enabled ? "#009B00" : "#7D7D7D" }}
            />
          </Tooltip>
          <Box display="flex" flexDirection="column">
            <span className="manager-name">{name}</span>
            <span className="manager-email">{email}</span>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="absolute"
          left={"43.5%"}>
          <span className="last-selected">Last selected on</span>
          <span className="last-selected">
            {moment(new Date(lastSelectedDate)).utc().format("MM/DD/YYYY")}
          </span>
        </Box>
        <Box display="flex" flexDirection="row">
          <Tooltip title={enabled ? "Deactivate" : "Activate"} placement="top">
            <IconButton
              onClick={() => {
                handleToggleManager(manager);
              }}
              size="large">
              {enabled ? <BlockIcon /> : <CheckIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <IconButton
              onClick={() => {
                handleOnEdit(manager);
              }}
              size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
};
