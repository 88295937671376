import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpensesPanelTableRow from "./ExpensesPanelTableRow";
import NumberFormat from "react-number-format";
import ExpensesApi from "../../api/ExpensesApi";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import { OptionType } from 'types/expenses/Expense';

const MAX_ROWS = 5;

type ExpensesPanelTable = {
  expenses: any[];
};

const ExpensesPanelTable = ({ expenses = [] }: ExpensesPanelTable): JSX.Element => {
  const [emptyRows, setEmptyRows] = useState<{ id: string; amount: string; expenseType: string }[]>(
    []
  );
  const [expensesTotal, setExpensesTotal] = useState(0);
  const [expenseStates, setExpenseStates] = useState(new Map());

  useEffect(() => {
    setEmptyRows([]);
    setExpensesTotal(0);

    const expensesLength = expenses.length ?? 0;
    if (expensesLength < MAX_ROWS) {
      for (let index = 0; index < MAX_ROWS - expensesLength; index++) {
        setEmptyRows((oldArray) => [
          ...oldArray,
          { id: `empty-row-${index}`, amount: " ", expenseType: " " },
        ]);
      }
    }

    if (expenses) {
      const total = expenses.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.amount;
      }, 0);

      setExpensesTotal(total);
    }
  }, [expenses]);

  useEffect(() => {
    let tempStatesMap = new Map();
    ExpensesApi.getExpenseStates()
      .then((result) => {
        result.expenseStates.forEach((state: OptionType) => {
          tempStatesMap = new Map(tempStatesMap.set(state.name, state.id));
        });
        setExpenseStates(tempStatesMap);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Table
      sx={{
        minWidth: 650,
      }}
      className={" timecard-hours-entry-table"}
      size="small">
      <TableHead>
        <TableRow>
          <TableCell>Expense Type</TableCell>
          <TableCell>Expense Report</TableCell>
          <TableCell align="right">Expense Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {expenses.map((expense) => {
          return (
            <ExpensesPanelTableRow
              expense={expense}
              key={expense.id}
              expenseStates={expenseStates}></ExpensesPanelTableRow>
          );
        })}
        {emptyRows.map((emptyRow) => {
          return (
            <ExpensesPanelTableRow
              expense={emptyRow}
              key={emptyRow.id}
              expenseStates={expenseStates}></ExpensesPanelTableRow>
          );
        })}
        <CanRenderChildren permissionName="canSeeTotalAmountExpenseSectionPanel">
          <TableRow>
            <TableCell align="right" colSpan={2} style={{ height: "50px", fontWeight: "bold" }}>
              Total
            </TableCell>
            <TableCell className="expensetable__total" align="right">
              <NumberFormat
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={"$ "}
                value={expensesTotal}
                thousandSeparator={true}
                displayType={"text"}
                style={{ fontWeight: "bold" }}
              />
            </TableCell>
          </TableRow>
        </CanRenderChildren>
      </TableBody>
    </Table>
  );
};

export default ExpensesPanelTable;
