import React from "react";
import TableContainer from "@mui/material/TableContainer";
import MaterialTable from "material-table";
import PropTypes from "prop-types";
import { SetColumns, SetRowData } from "./AnalyticHelper";

const DataTableContent = ({ tableInfo }) => {
  return (
    <TableContainer sx={{ height: "30vh" }}>
      {tableInfo && (
        <MaterialTable
          style={{ fontSize: 14 }}
          columns={SetColumns(tableInfo)}
          data={SetRowData(tableInfo)}
          options={{
            sorting: true,
            filtering: false,
            showTitle: false,
            search: false,
            toolbar: false,
            paging: false,
            header: Boolean(tableInfo?.header),
          }}
        ></MaterialTable>
      )}
    </TableContainer>
  );
};

DataTableContent.propTypes = {
  tableInfo: PropTypes.shape({
    columns: PropTypes.array,
    header: PropTypes.object,
    rows: PropTypes.array,
  }),
};

export default DataTableContent;
