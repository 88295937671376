export const downloadBlob = async (blob: Blob, filename: string): Promise<void> => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);

  link.click();

  URL.revokeObjectURL(url);
  link.remove();
};

/**
 * Convert base64 to Blob
 * @param base64 Base64 data to convert into the Blob
 */
export function convertBase64ToBlob(base64: string): Blob {
  const decodedData = window.atob(base64);
  const uInt8Array = new Uint8Array(decodedData.length);

  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  return new Blob([uInt8Array]);
}

export function getFileType(file: File) {
  return file.name.split(".")[1].toLowerCase() === "heic" ? "image/heic" : file.type;
}

export async function parseErrorResponse(
  error: Response,
  defaultMessage = "There was an unexpected error. Please try again later."
): Promise<string> {
  let errorMessage = defaultMessage;

  if (error.status === 400) {
    try {
      const cloneErrorForJson = error.clone();
      const response = await cloneErrorForJson.json();

      if (Array.isArray(response.errors) && response.errors.length > 0) {
        const errors = response.errors.join("\n");
        errorMessage = errors;
      } else if (response.message) {
        errorMessage = response.message;
      } else if (response.error) {
        errorMessage = response.error;
      } else if (typeof response === "string" && String(response).length > 0) {
        errorMessage = String(response);
      } else if (Array.isArray(response) && response.length > 0) {
        errorMessage = response[0];
      }
    } catch (parseError) {
      const cloneErrorForText = error.clone();
      const errorText = await cloneErrorForText.text();
      console.log(parseError);
      console.log(errorText);
    }
  }

  return errorMessage;
}
