import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { daysOfWeek } from "types/constants";
import { FacilityDetail } from "types/facility/facilityDetail";
import { FormErrors } from "types/facility/facilityFormErrors";

type TimecardForwardingProps = {
  formErrors: FormErrors;
  facility: FacilityDetail;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => void;
  handleToggle: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const TimecardForwarding = ({
  formErrors,
  facility,
  handleChange,
  handleToggle,
}: TimecardForwardingProps) => {
  return (
    <Box mt={3}>
      <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <TextField
          error={formErrors.emailHasError}
          id="email"
          name="email"
          label="Email"
          type="email"
          variant="outlined"
          value={facility.email || ""}
          onChange={handleChange}
          disabled={!facility.timecardForwarding}
          fullWidth
          helperText={formErrors.emailHasError ? "Email is required and must be valid." : ""}
          style={{ width: "30%" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={facility.lateForwarding || false}
              onChange={handleToggle}
              name="lateForwarding"
              color="primary"
            />
          }
          label="Late Forwarding"
          style={{ marginLeft: 30 }}
        />
      </Box>
      <Box mt={2}>
        <FormLabel component="legend">Due Date</FormLabel>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 15 }}>
          <FormControl variant="outlined" error={formErrors.dayHasError} style={{ width: "30%" }}>
            <InputLabel id="imecard-due-date-day">Day</InputLabel>
            <Select
              labelId="timecard-duedate-day-label"
              id="timecard-duedate-day"
              value={facility.timecardDueDateDay}
              onChange={handleChange}
              label="Day"
              fullWidth
              name="timecardDueDateDay">
              {daysOfWeek.map((day) => (
                <MenuItem key={day.value} value={day.value}>
                  {day.text}
                </MenuItem>
              ))}
            </Select>

            {formErrors.dayHasError && <FormHelperText>Day is required.</FormHelperText>}
          </FormControl>

          <TextField
            error={formErrors.timeHasError}
            id="timecard-due-date-time"
            label="Time"
            type="time"
            name="timecardDueDateTime"
            fullWidth
            variant="outlined"
            value={facility.timecardDueDateTime}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            helperText={formErrors.timeHasError ? "Valid Time Required" : ""}
            style={{ marginLeft: 30, width: 150 }}
          />
        </Box>
      </Box>
    </Box>
  );
};
