import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { isWeekend, isSaturday, isSunday } from "date-fns";
import { DateRangePicker, Range, createStaticRanges } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Popper from "@mui/material/Popper";
import { Button, ButtonGroup, ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useFeatureFlags } from "../../commons/Features";
import ClearIcon from "@mui/icons-material/Clear";

type PayPeriodRangeSelectorProps = {
  enqueueSnackbar?: (message: string) => void;
  setAnchorEl: (value: null | HTMLElement) => void;
  anchorEl: null | HTMLElement;
  title: string;
  onHandleChange: (startDate?: string, endDate?: string) => void;
  startDate: string;
  endDate: string;
  buttonPickerVariant?: "contained" | "text" | "outlined";
  nullableComponent?: boolean;
  lookupFutureDate?: boolean;
};

const PayPeriodRangeSelector = ({
  setAnchorEl,
  anchorEl,
  title,
  onHandleChange,
  startDate,
  endDate,
  buttonPickerVariant: variant = "contained",
  nullableComponent = false,
  lookupFutureDate = false,
}: PayPeriodRangeSelectorProps) => {
  const snackbar = useSnackbar();

  const headerStartDate = useSelector((state: RootState) => state.header.startDate);
  const headerEndDate = useSelector((state: RootState) => state.header.endDate);
  const savedDateRange: Range = {
    startDate: nullableComponent && !startDate ? undefined : new Date(startDate),
    endDate: nullableComponent && !endDate ? undefined : new Date(endDate),
    color: "#615996",
    key: "selection",
  };

  const { firstTimesheetPeriodStartDate } = useFeatureFlags();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const futureDate = lookupFutureDate
    ? moment(headerEndDate).add(6, "months").day(6)
    : headerEndDate;

  const sideBarOptions = [
    {
      label: "Current Period",
      range: () => ({
        startDate: new Date(headerStartDate),
        endDate: new Date(headerEndDate.toLocaleString()),
      }),
    },
    {
      label: "All Periods",
      range: () => ({
        startDate: new Date(firstTimesheetPeriodStartDate ?? new Date()),
        endDate: new Date(futureDate.toLocaleString()),
      }),
    },
    {
      label: "Previous 4 Pay Periods",
      range: () => ({
        startDate: new Date(moment(headerStartDate).subtract(4, "weeks").toDate()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
    {
      label: "Previous 13 Pay Periods",
      range: () => ({
        startDate: new Date(moment(headerStartDate).subtract(13, "weeks").toDate()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
    {
      label: "Previous 52 Pay Periods",
      range: () => ({
        startDate: new Date(moment(headerStartDate).subtract(52, "weeks").toDate()),
        endDate: new Date(moment(headerEndDate).subtract(1, "weeks").toDate()),
      }),
    },
  ];

  const staticRanges = [...createStaticRanges(sideBarOptions)];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const clickAwayHandler = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    if (moment(event.selection.startDate).isValid() && !isSunday(event.selection.startDate)) {
      snackbar.enqueueSnackbar("Pay period start date must be a Sunday", {
        variant: "error",
      });
      return;
    } else if (moment(event.selection.endDate).isValid() && !isSaturday(event.selection.endDate)) {
      snackbar.enqueueSnackbar("Pay period end date must be a Saturday", {
        variant: "error",
      });
      return;
    }

    onHandleChange(
      moment(event.selection.startDate).isValid()
        ? new Date(event.selection.startDate).toISOString()
        : undefined,
      moment(event.selection.endDate).isValid()
        ? new Date(event.selection.endDate).toISOString()
        : undefined
    );
  };

  const isPeriodStartOrEnd = (day: number | Date) => {
    return !isWeekend(day);
  };

  const clearInformation = () => {
    onHandleChange(undefined, undefined);
  };

  const componentLabel =
    savedDateRange.startDate && savedDateRange.endDate
      ? ` ${title}:
  ${moment(savedDateRange.startDate).format("MM/DD/YYYY")} - ${moment(
          savedDateRange.endDate
        ).format("MM/DD/YYYY")}
            `
      : "Select Date Range";

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div>
        <ButtonGroup variant={variant} aria-label="Basic button group">
          <Button
            size="large"
            aria-describedby={id}
            variant={variant}
            onClick={handleClick}
            startIcon={<DateRangeIcon />}>
            {componentLabel}
          </Button>
          {nullableComponent && (
            <Button onClick={clearInformation} size="small">
              <ClearIcon />
            </Button>
          )}
        </ButtonGroup>

        <Popper
          id={id}
          sx={{
            zIndex: "10000",
          }}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start">
          <div
            style={{
              boxShadow: "0px 10px 20px #00000030",
            }}>
            <DateRangePicker
              onChange={handleChange}
              rangeColors={["#50478b"]}
              moveRangeOnFirstSelection={false}
              months={2}
              minDate={new Date(firstTimesheetPeriodStartDate ?? new Date())}
              maxDate={new Date(futureDate.toLocaleString())}
              disabledDay={isPeriodStartOrEnd}
              retainEndDateOnFirstSelection={true}
              ranges={[savedDateRange]}
              direction="horizontal"
              staticRanges={staticRanges}
              inputRanges={[]}
            />
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default PayPeriodRangeSelector;
