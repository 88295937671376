import { msalApiFetch } from "../commons/authConfig";
import { LockPeriodStatus } from "types/payPeriod/lockPayPeriodStatus";
import { LockType } from "types/payPeriod/lockType";
import { LockStatus } from "types/payPeriod/lockStatus";

const baseUrl = `${process.env.REACT_APP_API_URL}/payroll-locks`;

export default {
  async GetPayrollLockStatus(): Promise<LockPeriodStatus[]> {
    const url = baseUrl;
    return msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw res;
    });
  },
  async GetPayrollLockStatusByType(lockType: LockType): Promise<LockPeriodStatus> {
    const url = `${baseUrl}/${lockType}`;
    const res = await msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (res.ok) {
      return res.json();
    }
    throw res;
  },
  async UpdatePayrollLockStatus(lockType: LockType, lockStatus: LockStatus) {
    const url = `${baseUrl}/${lockType}/status`;
    return msalApiFetch(url, {
      method: "PATCH",
      headers: { "Content-Type": "text/plain" },
      body: lockStatus,
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }
    });
  },
};
