import React from "react";

type NumberContentProps = {
  number: number;
};

const NumberContent = ({ number }: NumberContentProps) => {

  return (
    <div
      style={{
        maxHeight: "15vh",
        height: "15vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fafafa",
      }}>
      <em
        style={{
          fontSize: "4em",
          fontStyle: "normal",
          fontWeight: "bold",
        }}>
        {number}
      </em>
    </div>
  );
};

export default NumberContent;
