import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "candidates";

export default {
  // Api to get All Candidates
  getAllCandidates(nameFilter) {
    const url = `${baseUrl}/${endpoint}/all`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Name: nameFilter, MaxResults: 20 }),
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getAllCandidatesByRecuiter(nameFilter, recruiterId) {
    const url = `${baseUrl}/recruiter/${recruiterId}/${endpoint}/all`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Name: nameFilter, MaxResults: 20 }),
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  downloadCandidatesAtSameFacilityReport() {
    const url = `${baseUrl}/${endpoint}/TravelersAtSameFacilityReport`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((response) => {
      if (!response.ok) throw response;
      return response.blob();
    });
  },
  downloadCandidatesWithWorkWeekChangedReport() {
    const url = `${baseUrl}/${endpoint}/TravelersWorkWeekChangesReport`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((response) => {
      if (!response.ok) throw response;
      return response.blob();
    });
  },
  downloadTimecardSourceReport() {
    const url = `${baseUrl}/${endpoint}/TimecardUploadSourceReport`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((response) => {
      if (!response.ok) throw response;
      return response.blob();
    });
  },
};
