import {
  ADVANCED_SEARCH_CLEAR_SELECTS,
  ADVANCED_SEARCH_UPDATE_DETAILS,
  ADVANCED_SEARCH_UPDATE_FACILITIES,
  ADVANCED_SEARCH_UPDATE_PAYPERIOD,
  ADVANCED_SEARCH_UPDATE_RECRUITER,
  ADVANCED_SEARCH_UPDATE_STATES,
  ADVANCED_SEARCH_UPDATE_TRAVELER,
} from "redux/actions/actionTypes";
import { RecruiterState } from "types/recruiter";
import { TravelerState } from "types/traveler";

export type FacilitiesState = {
  facilityName: string;
  facilitiesId: string[];
};

type AdvancedSearch = {
  startDate: string;
  endDate: string;
  traveler?: TravelerState;
  recruiter?: RecruiterState;
  facilities?: FacilitiesState[];
  details: string[];
  states: string[];
};

const initialState: AdvancedSearch = {
  startDate: "",
  endDate: "",
  traveler: undefined,
  recruiter: undefined,
  facilities: undefined,
  details: [],
  states: [],
};

type AdvancedSearchAction = {
  startDate: string;
  endDate: string;
  traveler: TravelerState;
  recruiter: RecruiterState;
  facilities: FacilitiesState[];
  details: string[];
  states: string[];
  type: string;
};

export function advancedSearchReducer(state = initialState, action: AdvancedSearchAction) {
  switch (action.type) {
    case ADVANCED_SEARCH_UPDATE_PAYPERIOD: {
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    }
    case ADVANCED_SEARCH_UPDATE_RECRUITER: {
      return { ...state, recruiter: action.recruiter };
    }
    case ADVANCED_SEARCH_UPDATE_DETAILS: {
      return { ...state, details: action.details };
    }
    case ADVANCED_SEARCH_UPDATE_TRAVELER: {
      return { ...state, traveler: action.traveler };
    }
    case ADVANCED_SEARCH_UPDATE_STATES: {
      return { ...state, states: action.states };
    }
    case ADVANCED_SEARCH_UPDATE_FACILITIES: {
      return { ...state, facilities: action.facilities };
    }
    case ADVANCED_SEARCH_CLEAR_SELECTS: {
      return { ...state, facilities: [], details: [], states: [] };
    }
    default:
      return state;
  }
}
