import React from "react";
import { useAppSelector } from "hooks";
import { Tooltip, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DealsheetValue } from "types/timesheets";

const getTooltipText = (field: DealsheetValue) => {
  if (field == null) {
    return "Dealsheet value missing";
  }

  if (field.name == "Mileage" && field.rate2 != null) {
    return `Dealsheet value: ${field.rate} + ${field.rate2} = ${field.rate + field.rate2}`;
  }

  return `Dealsheet value: ${field.rate}`;
};

type DealsheetValueIconProps = {
  fieldName: string;
  arrayName:
    | "timecardDealsheetTypes"
    | "taxableDealsheetTypes"
    | "nonTaxableDealsheetTypes"
    | "billingDealsheetTypes";
};

const DealsheetValueIcon = (props: DealsheetValueIconProps) => {
  const { fieldName, arrayName } = props;
  const timesheetSelected = useAppSelector(
    (state) => state.missedShiftCalculation.timesheetSelected
  );

  if (timesheetSelected == null) {
    return null;
  }

  const arrayType = timesheetSelected[arrayName];

  const field =
    arrayType && Array.isArray(arrayType)
      ? arrayType.find((t) => t.name == fieldName && t.rate != null)
      : null;

  return (
    <Tooltip
      sx={{
        maxWidth: "none",
      }}
      placement={"left"}
      data-testid="dealsheet-value-icon"
      title={<>{getTooltipText(field)}</>}>
      <Box
        sx={{
          backgroundColor: "#50478b",
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "5px",
          verticalAlign: "bottom",
          marginLeft: "5px",
          borderRadius: 99,
          width: 21,
          height: 21,
        }}>
        <InfoOutlinedIcon
          sx={{
            color: "white",
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default DealsheetValueIcon;
