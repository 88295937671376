import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PropTypes from "prop-types";
import TimesheetApi from "../../../api/TimesheetApi";
import CandidatesApi from "api/CandidatesApi";
import { downloadBlob } from "../../../commons/utils";

type WidgetActionsDropdownProps = {
  dataItem: { actions: string[]; description: string; name: string; type: string; value: any };
};

type anchorElement = {
  [x: string]: Element;
};

const WidgetActionsDropdown = ({ dataItem }: WidgetActionsDropdownProps): JSX.Element => {

  const [anchorEl, setAnchorEl] = useState<anchorElement | null>(null);

  const actionHandler = async (actionName: string): Promise<void> => {
    switch (actionName) {
      case "Duplicate Timecards":
        {
          try {
            const blobResponse = await TimesheetApi.downloadTimesheetsDuplicatedSSNReport();
            await downloadBlob(new Blob([blobResponse]), "Duplicate Timecards.csv");
          } catch (error) {
            console.error(error);
          }
        }
        break;

      case "Travelers At Same Facility Over 1 Year":
        {
          try {
            const blobResponse = await CandidatesApi.downloadCandidatesAtSameFacilityReport();
            await downloadBlob(
              new Blob([blobResponse]),
              "Travelers At Same Facility Over 1 Year.csv"
            );
          } catch (error) {
            console.error(error);
          }
        }
        break;

      case "Timecard Source Report":
        {
          try {
            const blobResponse = await CandidatesApi.downloadTimecardSourceReport();
            await downloadBlob(new Blob([blobResponse]), "TimecardSourceReport.csv");
          } catch (error) {
            console.error(error);
          }
        }
        break;

      case "Travelers With Work Week Changed":
        {
          try {
            const blobResponse = await CandidatesApi.downloadCandidatesWithWorkWeekChangedReport();
            await downloadBlob(new Blob([blobResponse]), "Travelers With Work Week Changed.csv");
          } catch (error) {
            console.error(error);
          }
        }
        break;

      case "Timecards Missing Manager Signature":
        {
          try {
            const blobResponse = await TimesheetApi.downloadTimesheetsWithoutManagerSignatureReport();
            await downloadBlob(new Blob([blobResponse]), "Timecards Missing Manager Signature.csv");
          } catch (error) {
            console.error(error);
          }
        }
        break;

      case "Edit requests":
        {
          try {
            const blobResponse = await TimesheetApi.downloadTimesheetsPendingEditRequest();
            await downloadBlob(new Blob([blobResponse]), "Timecards Pending Request Edition.csv");
          } catch (error) {
            console.error(error);
          }
        }
        break;

        case "Export Dealsheet and Timecard Rate Differences":
          {
            try {
              const blobResponse = await TimesheetApi.downloadDealsheetAndTimecardDifferences();
              await downloadBlob(new Blob([blobResponse]), "Dealsheet and Timecard Rate Differences.csv");
            } catch (error) {
              console.error(error);
            }
          }
          break;

      default:
        break;
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    setAnchorEl({ [id]: event.currentTarget });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {dataItem?.actions && dataItem.actions.length > 0 && (
        <>
          <Button onClick={(e) => openMenu(e, dataItem.name)}>
            <SettingsIcon sx={{ color: "#474486" }} />
          </Button>
          <Menu
            anchorEl={anchorEl?.[dataItem.name]}
            open={Boolean(anchorEl?.[dataItem.name])}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}>
            {dataItem.actions.map((action) => {
              return (
                <MenuItem
                  key={action}
                  onClick={(): void => {
                    actionHandler(action).catch((e) => console.error(e));
                    handleMenuClose();
                  }}>
                  {action}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </>
  );
};

WidgetActionsDropdown.propTypes = {
  dataItem: PropTypes.object,
};

export default WidgetActionsDropdown;
