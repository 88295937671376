import { HistoryTableState } from "types/history/History";
import * as actionTypes from "../actions/actionTypes";

type HistoryTableUpdatedAction = {
  type: string;
  history: HistoryTableState;
};

export default function historyTableReducer(
  state = {} as HistoryTableState,
  action: HistoryTableUpdatedAction
) {
  switch (action.type) {
    case actionTypes.HISTORY_TABLE_CHANGED:
      return action.history;
    default:
      return state;
  }
}
