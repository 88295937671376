import { Box, Button, FormControl, FormControlLabel, Switch, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import validateManager from "shared/validations/validateManager";
import { Manager } from "types/facility/managers";

type AddUpdateManagerProps = {
  handleOnClose: () => void;
  entityId: string;
  oldManagerData?: Manager;
  refreshManagers: () => Promise<void>;
  createManager: (entityId: string, manager: Manager) => Promise<any>;
  updateManager: (entityId: string, manager: Manager, oldManagerEmail: string) => Promise<any>;
};

type ManagerError = Manager & {
  error?: { name?: boolean; email?: boolean };
};

const defaultManager: Manager = {
  email: "",
  name: "",
  lastSelectedDate: "",
  confirmed: false,
  enabled: true,
};

const AddUpdateManagerContent = ({
  handleOnClose,
  entityId,
  oldManagerData,
  refreshManagers,
  updateManager,
  createManager,
}: AddUpdateManagerProps) => {
  const isUpdateManager = !!oldManagerData;
  const [newManager, setNewManager] = useState<ManagerError>(oldManagerData ?? defaultManager);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const disabledSubmitNew =
      newManager?.error?.name ||
      newManager?.error?.email ||
      !newManager?.name ||
      !newManager?.email;
    setDisabledSubmit(disabledSubmitNew);
  }, [newManager]);

  const buttonColor = disabledSubmit ? "#E0E0E0" : "#514689";

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (disabledSubmit) return;

    try {
      setDisabledSubmit(true);
      let response;
      if (isUpdateManager) {
        response = await updateManager(entityId, newManager, oldManagerData.email);
      } else {
        response = await createManager(entityId, newManager);
      }

      if (response?.status > 201) {
        const message = await response.text();
        enqueueSnackbar(message ?? "Something went wrong");
        return;
      }
      const message = `Manager ${isUpdateManager ? "updated" : "added"} successfully`;

      enqueueSnackbar(message);

      handleOnClose();
      refreshManagers();
    } finally {
      setDisabledSubmit(false);
    }
  };

  const handleBlur = () => {
    const { name, email } = validateManager(newManager);
    setNewManager({ ...newManager, error: { name, email } });
  };

  return (
    <Box minWidth={"40vh"}>
      <form onSubmit={handleSubmit}>
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={newManager.enabled}
                onChange={(event) =>
                  setNewManager({ ...newManager, enabled: event.target.checked })
                }
                name="activeManager"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={"Active"}
          />
          <FormControl fullWidth margin="dense">
            <TextField
              id="managerName"
              label="MANAGER'S NAME"
              variant="outlined"
              value={newManager?.name}
              onChange={(event) => setNewManager({ ...newManager, name: event.target.value })}
              error={newManager?.error?.name}
              helperText={newManager?.error?.name && "Please enter a valid name"}
              onBlur={handleBlur}
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              id="managerEmail"
              label="MANAGER'S EMAIL"
              variant="outlined"
              value={newManager?.email}
              onChange={(event) => setNewManager({ ...newManager, email: event.target.value })}
              error={newManager?.error?.email}
              helperText={newManager?.error?.email && "Please enter a valid email"}
              onBlur={handleBlur}
            />
          </FormControl>
        </Box>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"} paddingTop={2}>
          <Button
            size="large"
            variant="outlined"
            style={{ color: "#514689", borderColor: "#514689" }}
            onClick={() => handleOnClose()}>
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            style={{
              color: disabledSubmit ? "inherit" : "white",
              borderColor: buttonColor,
              backgroundColor: buttonColor,
            }}
            onClick={handleSubmit}
            disabled={disabledSubmit}>
            {isUpdateManager ? "Update" : "Confirm"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddUpdateManagerContent;
