export function SetRowData(data) {
  let rows = [];
  for (let rowValueI = 0; rowValueI < data?.rows.length; rowValueI++) {
    let valuesInRow = {};
    if (data?.header) {
      for (let i = 0; i < data?.header?.columnTitles.length; i++) {
        valuesInRow[data?.columns[i]?.name] = data?.rows[rowValueI].values[i];
      }
    } else {
      for (let i = 0; i < data?.rows[rowValueI].values.length; i++) {
        valuesInRow[`column${[i]}`] = data?.rows[rowValueI].values[i];
        valuesInRow["indent"] = data?.rows[rowValueI].indent;
      }
    }
    rows.push(valuesInRow);
  }
  return rows;
}

export function SetColumns(tableInfo) {
  let columnTables = [];
  if (tableInfo?.header) {
    for (let i = 0; i < tableInfo?.columns.length; i++) {
      const column = {
        title: tableInfo?.header?.columnTitles[i],
        field: tableInfo?.columns[i]?.name,
      };
      columnTables.push(column);
    }
  } else {
    for (let rowValueI = 0; rowValueI < tableInfo?.rows[0]?.values.length; rowValueI++) {
      const column = {
        field: `column${[rowValueI]}`,
      };
      columnTables.push(column);
    }
  }
  return columnTables;
}
