import BPPTable, { Column, SaveType } from "components/shared/components/bppTable/BPPTable";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BPPUSERS_TABLE_CHANGED } from "redux/actions/actionTypes";
import { UsersTableItem, UserType } from "types/users";
import { UserSearch } from "./UserSearch";
import { getBPPUsers } from "api/UsersApi";
import { bppActionsFormat, dateFormat } from "./tableFormatters";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import BPPUserModalDetail from "./BPPUserModalDetail";

type UsersDataIds = "name" | "email" | "createdOrModifiedDate" | "type" | "actions";

export const BPPUsers = () => {
  const [data, setData] = useState<UsersTableItem[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UsersTableItem>();
  const recruiters = data.filter(
    (user) => user.type === UserType.Recruiter || user.type === UserType.RecruiterAssistant
  );
  const bppUsers = useSelector((state: RootState) => state.bppUsers);

  const handleSearch = async () => {
    try {
      setLoadingData(true);
      const users = await getBPPUsers();
      setData(users.map((x) => ({ ...x, id: x.candidateId })));
    } catch (error) {
      enqueueSnackbar("Error fetching users", { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  };

  const handleChange = (newValue: SaveType<UsersDataIds>): void => {
    dispatch({ type: BPPUSERS_TABLE_CHANGED, filters: newValue });
  };

  const openDialog = (row: UsersTableItem) => {
    setSelectedUser(row);
    setModalOpen(!modalOpen);
  };

  const closeDialog = () => {
    setModalOpen(!modalOpen);
  };

  const onConfirm = async () => {
    setModalOpen(!modalOpen);
    await handleSearch();
  };

  const columns: Column<UsersDataIds, UsersTableItem>[] = [
    {
      id: "name",
      label: "Name",
      format(value) {
        return (
          <Typography fontSize={13} sx={{ textTransform: "capitalize" }}>
            {value as string}
          </Typography>
        );
      },
    },
    { id: "email", label: "Email" },
    {
      id: "createdOrModifiedDate",
      label: "Date",
      format: dateFormat,
    },
    {
      id: "actions",
      label: "Actions",
      disableFilter: true,
      format: bppActionsFormat(openDialog),
    },
  ];

  const handleClear = () => {
    handleSearch();
  };

  return (
    <>
      <BPPTable<UsersTableItem, UsersDataIds>
        columns={columns}
        data={data}
        loading={loadingData}
        handleSave={handleChange}
        handleClear={handleClear}
        defaultOrderingBy="name"
        savedData={bppUsers?.filters}
        customSearch={<UserSearch handleSearch={handleSearch} disabled={loadingData} />}
      />

      <ConfirmDialog
        centerTitle
        dividerEnabled
        displayCancelButton={false}
        displayConfirmButton={false}
        isOpen={modalOpen}
        maxWidth={"xl"}
        content={
          <BPPUserModalDetail
            onClose={closeDialog}
            onConfirm={onConfirm}
            user={selectedUser}
            open={modalOpen}
            recruiters={recruiters}
          />
        }
        dialogTitle={selectedUser?.name ?? ""}
      />
    </>
  );
};
