import React from "react";
import PropTypes from "prop-types";
import FileUpdateAndDownload from "../shared/components/FileUpdateAndDownload";

type ExpenseFileProps = {
  expenseFileId?: string;
  onFileUploaded: (files: FileList) => void;
  onDelete: () => void;
  onFileUpdate: (files: FileList, id: string) => void;
  uploadFileId: string;
  isReadOnly: boolean;
};

function ExpenseFile(props: ExpenseFileProps) {
  let downloadUrl = null;

  if (props.expenseFileId) {
    downloadUrl = `${process.env.REACT_APP_API_URL}/expenses/download-file/${props.expenseFileId}`;
  }

  return (
    <FileUpdateAndDownload
      downloadUrl={downloadUrl}
      noFileMessage="No timecard file has been uploaded"
      fileName="Expense File"
      icon="description"
      {...props}
    />
  );
}

ExpenseFile.propTypes = {
  expenseFileId: PropTypes.string,
};

export default ExpenseFile;
