import { Manager } from "types/facility/managers";

const validateManager = (manager: Manager) => {
  const isValidName = manager.name.trim().split(" ").length > 1;
  const regex = new RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

  return { name: !isValidName, email: !regex.test(manager.email) };
};

export default validateManager;
