import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TimesheetApi from "../../api/TimesheetApi";
import { HistoryExpense } from "types/expenses/Expense";
import { useSnackbar } from "notistack";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

type TimecardExpensesDialogProps = {
  expenseLinkOpen: { open: boolean; timesheetId: string };
  toggleExpenseLink: (val: string) => void;
};

export const TimecardExpensesDialog = ({
  expenseLinkOpen,
  toggleExpenseLink,
}: TimecardExpensesDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [expenses, setExpenses] = useState<HistoryExpense[]>();

  const snackbar = useSnackbar();

  const handleClose = () => {
    toggleExpenseLink("");
  };

  const handleClick = (expenseId: string) => {
    window.open(`/expenseDetails/${expenseId}`, "_blank");
  };

  const getExpenses = async () => {
    setLoading(true);
    try {
      const result = await TimesheetApi.getExpensesByTimesheetId(expenseLinkOpen.timesheetId);
      setExpenses(result);
    } catch (error) {
      snackbar.enqueueSnackbar("Error getting expenses", { variant: "error" });
      handleClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (expenseLinkOpen.open) {
      getExpenses();
    }
  }, [expenseLinkOpen]);

  return (
    <Dialog open={expenseLinkOpen.open} onClose={handleClose}>
      <DialogTitle>{"Timecard's Expenses"}</DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center", minWidth: 550 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Expense Type</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                    hover
                    onClick={() => handleClick(row.id)}>
                    <TableCell>
                      <Tooltip title="Open in a new tab">
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          {row.type}
                          <OpenInNewOutlinedIcon color="primary" fontSize="small" sx={{ mx: 1 }} />
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>${row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
