import { MISSED_SHIFT_CALCULATION_RESULTS } from "../../redux/actions/actionTypes";
import store from "../../redux/configureStore";

const missedShiftHoursFields = [
  "RegularHours",
  "Overtime8",
  "Overtime40",
  "Overtime48",
  "DoubleTime",
  "CallBack",
  "Holiday",
  "Guaranteed",
];

/**
 * @param {number} scheduledHours
 * @param {number} numberOfShifts
 * @param {number} workedHours
 * @param {Array} nonTaxableExpenses
 */
const calculateMissedShiftDeduct = (
  scheduledHours,
  numberOfShifts,
  workedHours,
  nonTaxableExpenses
) => {
  if (numberOfShifts == null || numberOfShifts == 0) {
    throw new Error("Number of shifts is unavailable");
  }

  const floatScheduledHours = parseFloat(scheduledHours);

  const hoursPerShift = floatScheduledHours / numberOfShifts;

  const missedHrs = () => {
    let localWorkedHours = parseFloat(workedHours ?? 0.0);

    if (localWorkedHours < 0) {
      localWorkedHours = 0.0;
    }

    const missedHours = floatScheduledHours - localWorkedHours;

    if (missedHours < 0) {
      return 0;
    }

    if (missedHours % hoursPerShift === 0) {
      return missedHours;
    }

    let current = 0;
    let next = 0;
    while (current + hoursPerShift <= scheduledHours) {
      next = Math.ceil(current + hoursPerShift);
      if (next > missedHours) {
        return current;
      }
      current = next;
    }
  };

  const missedHours = missedHrs();

  const missedShifts = Math.floor(missedHours / hoursPerShift);

  let perDiemGross = nonTaxableExpenses.find((e) => e.name == "PerDiem").gross;
  let housingStipendGross = nonTaxableExpenses.find((e) => e.name == "HousingStipend").gross;

  if (store.getState().missedShiftCalculation.timesheetSelected.isAdjustment) {
    perDiemGross = nonTaxableExpenses.find((e) => e.name == "PerDiem").paid;
    housingStipendGross = nonTaxableExpenses.find((e) => e.name == "HousingStipend").paid;
  }

  const hourlyRate = (perDiemGross + housingStipendGross) / floatScheduledHours;

  const deduct = missedShifts * hoursPerShift * hourlyRate;

  const negativeDeduct = deduct * -1;

  store.dispatch({
    type: MISSED_SHIFT_CALCULATION_RESULTS,
    results: {
      scheduledHours,
      numberOfShifts,
      hoursPerShift,
      workedHours,
      missedHours,
      missedShifts,
      perDiemGross,
      housingStipendGross,
      hourlyRate,
      deduct: negativeDeduct,
    },
  });

  if (deduct < 0) {
    return 0;
  } else {
    return negativeDeduct;
  }
};

/**
 * @param {Object} nominator
 * @param {number} scheduledHours
 * @param {number} numberOfShifts
 * @param {boolean} isInitialLoad
 * @param {Array} nonTaxableExpenses
 * @param {boolean} isFirstWeek
 * @param {boolean} isLastWeek
 */
const mapNonTaxableExpenses = (
  nominator,
  scheduledHours,
  numberOfShifts,
  isInitialLoad,
  nonTaxableExpenses,
  timecardAdjustmentTypes,
  isFirstWeek,
  isLastWeek
) => {
  const dto = {
    calculationMade: true,
    expensesMapped: [],
  };

  nonTaxableExpenses.forEach((nonTaxableExpense) => {
    const { missedShiftDeductCalculationMade, ...newExpense } = mapNonTaxableExpense(
      nominator,
      scheduledHours,
      numberOfShifts,
      nonTaxableExpense,
      isInitialLoad,
      nonTaxableExpenses,
      timecardAdjustmentTypes,
      isFirstWeek,
      isLastWeek
    );

    if (missedShiftDeductCalculationMade === false) {
      dto.calculationMade = false;
    }

    dto.expensesMapped.push(newExpense);
  });

  return dto;
};

/**
 * @param {Object} nominator
 * @param {number} scheduledHours
 * @param {number} numberOfShifts
 * @param {Object} nonTaxableExpense
 * @param {boolean} isInitialLoad
 * @param {Array} nonTaxableExpenses
 * @param {boolean} isFirstWeek
 * @param {boolean} isLastWeek
 */
const mapNonTaxableExpense = (
  nominator,
  scheduledHours,
  numberOfShifts,
  nonTaxableExpense,
  isInitialLoad,
  nonTaxableExpenses,
  timecardAdjustmentTypes,
  isFirstWeek,
  isLastWeek
) => {
  const isAdjustmentTimecard =
    store.getState().missedShiftCalculation.timesheetSelected.isAdjustment;
  if (nonTaxableExpense.name == "MissedShiftDeduct") {
    let workedHoursValue =
      (nominator.regularHours || 0) +
      (nominator.overtime8 || 0) +
      (nominator.overtime40 || 0) +
      (nominator.overtime48 || 0) +
      (nominator.doubleTime || 0) +
      (nominator.callback || 0) +
      (nominator.holiday || 0) +
      (nominator.guaranteed || 0);

    if (isAdjustmentTimecard) {
      const adjustmentHoursValue = timecardAdjustmentTypes.reduce((acc, obj) => {
        return missedShiftHoursFields.includes(obj.name) ? acc + obj.hours : acc;
      }, 0);
      workedHoursValue = workedHoursValue + adjustmentHoursValue;
    }

    const totalWorkedHrsSyncBroken =
      (isInitialLoad &&
        nonTaxableExpense.totalWorkedHrs != null &&
        nonTaxableExpense.totalWorkedHrs !== workedHoursValue) ||
      nonTaxableExpense.proratedSyncBroken;

    if (isInitialLoad && nonTaxableExpense.totalWorkedHrs == null) {
      workedHoursValue = 0.0;
    } else if (totalWorkedHrsSyncBroken) {
      workedHoursValue = nonTaxableExpense.totalWorkedHrs;
    }
    let paidValue;
    let missedShiftDeductCalculationMade = true;
    try {
      paidValue = calculateMissedShiftDeduct(
        scheduledHours,
        numberOfShifts,
        workedHoursValue,
        nonTaxableExpenses
      );
    } catch (error) {
      paidValue = 0;
      missedShiftDeductCalculationMade = false;
    }

    return {
      ...nonTaxableExpense,
      paid: paidValue,
      totalWorkedHrs: workedHoursValue,
      proratedSyncBroken: totalWorkedHrsSyncBroken,
      missedShiftDeductCalculationMade,
    };
  } else {
    let proratedSyncBroken = false;
    let paidValue = null;
    const expensesPaidOnFirstLastPayPeriod = ["Mileage", "TravelReimbursement"];
    if (
      nonTaxableExpense.name == "Mileage" ||
      nonTaxableExpense.name == "MileageReimbursement" ||
      (isAdjustmentTimecard &&
        (nonTaxableExpense.name == "PerDiem" || nonTaxableExpense.name == "HousingStipend"))
    ) {
      proratedSyncBroken =
        (isInitialLoad && nonTaxableExpense.paid != null) ||
        nonTaxableExpense.gross == null ||
        nonTaxableExpense.proratedSyncBroken;

      if (isInitialLoad && nonTaxableExpense.paid == null && nonTaxableExpense.gross != null) {
        if (nonTaxableExpense.name == "Mileage" && !isFirstWeek && !isLastWeek) {
          paidValue = 0.0;
        } else {
          paidValue = nonTaxableExpense.gross;
        }
      } else if (!proratedSyncBroken) {
        if (nonTaxableExpense.name == "Mileage" && !isFirstWeek && !isLastWeek) {
          paidValue = 0.0;
        } else {
          paidValue = nonTaxableExpense.gross;
        }
      } else {
        paidValue = nonTaxableExpense.paid == null ? 0.0 : nonTaxableExpense.paid;
      }
    } else if (isInitialLoad && nonTaxableExpense.paid == null && nonTaxableExpense.gross != null) {
      if (
        expensesPaidOnFirstLastPayPeriod.includes(nonTaxableExpense.name) &&
        !isFirstWeek &&
        !isLastWeek
      ) {
        paidValue = 0.0;
      } else {
        paidValue = nonTaxableExpense.gross || 0.0;
      }
    } else {
      paidValue = nonTaxableExpense.gross || 0.0;
    }

    return {
      ...nonTaxableExpense,
      paid: paidValue,
      proratedSyncBroken,
    };
  }
};

export { mapNonTaxableExpenses };
