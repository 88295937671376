import { msalApiFetch } from "commons/authConfig";
import moment from "moment";
import { Holiday } from "types/holidays";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "holidays";

export function fetchHolidays(): Promise<Holiday[]> {
  const url = `${baseUrl}/${endpoint}`;
  return msalApiFetch(url, {
    method: "GET",
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}

export function saveHolidays(holidayId: string, newDateTime: Date) {
  const url = `${baseUrl}/${endpoint}/${holidayId}`;
  return msalApiFetch(url, {
    method: "POST",
    body: JSON.stringify({ NewDate: moment(newDateTime).format("YYYY-MM-DD") }),
  });
}

export async function checkNextYearHolidays() {
  const url = `${baseUrl}/${endpoint}/checkNextYearHolidays`;
  return await msalApiFetch(url, {
    method: "GET",
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}