import { msalApiFetch } from "../commons/authConfig";
import { NoteType } from "commons/Enums";
import { Note } from "types/notes";

type Note2 = {
  NoteId: string;
  NoteType: string;
  Contents: string;
  SubContents: string;
  CreatedByFirstName: string;
  CreatedByLastName: string;
  CreatedDateUtc: string;
  UserType: string;
};

const mapNote = (n: Note2): Note => {
  return {
    noteId: n.NoteId,
    noteType: n.NoteType,
    contents: n.Contents,
    subContents: n.SubContents,
    createdByFirstName: n.CreatedByFirstName,
    createdByLastName: n.CreatedByLastName,
    createdDateUtc: n.CreatedDateUtc,
    userType: n.UserType,
  };
};

const urlBase = `${process.env.REACT_APP_API_URL}/job-assignments`;

export async function AddJobAssignmentNote(jobAssignmentId: string, contents: string): Promise<void> {
    const url = `${urlBase}/${jobAssignmentId}/notes`;

    const res = await msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contents: contents,
        noteType: NoteType.Assignment,
        isSystemGenerated: false,
        notifyToTraveler: false,
      }),
    });

    if (!res.ok) {
      throw res;
    }
  };

export async function GetAssignmentNotes(jobAssignmentId: string): Promise<Note[]> {
  const url = `${urlBase}/${jobAssignmentId}/notes`;

  const res = await msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  if (res.ok) {
      const notes = await res.json() as Note2[];
      return notes.map(mapNote);
  }

  throw res;
};
