import React from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { OptionType } from "types/expenses/Expense";
import { FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import MultiSelectChip from "components/history/MultiSelectChip";
import { EXPENSE_FILTERS_CHANGED_TYPES } from "redux/actions/actionTypes";

type ExpenseTypeSearchProps = {
  types: OptionType[];
};

const ExpenseTypeSearch = ({ types }: ExpenseTypeSearchProps) => {
  const dispatch = useAppDispatch();

  const selectedTypes = useAppSelector((state) => state.expensesState.expenseTypes);

  const handleChangeDetails = (event: SelectChangeEvent<string[]>) => {
    const newSelectedTypes = types.filter((t) => event.target.value.includes(t.name));

    dispatch({
      type: EXPENSE_FILTERS_CHANGED_TYPES,
      expenseTypes: newSelectedTypes,
    });
  };

  const handleDeleteDetails = (selectedValue: string) => {
    const newOptionsSelected = selectedTypes.filter((d: OptionType) => d.name !== selectedValue);

    dispatch({
      type: EXPENSE_FILTERS_CHANGED_TYPES,
      expenseTypes: newOptionsSelected,
    });
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>Expense Types</InputLabel>
      <MultiSelectChip
        label="Expense Types"
        onOptionSelected={handleChangeDetails}
        onOptionDeletedChip={handleDeleteDetails}
        options={types.map((x) => x.name)}
        optionsSelected={selectedTypes.map((x) => x.name)}
      />
    </FormControl>
  );
};

export default ExpenseTypeSearch;
