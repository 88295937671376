import React, { useRef } from "react";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import PayrollApi from "api/PayrollApi";
import { downloadBlob, getFileType } from "commons/utils";
import { usePermissions } from "hooks";
import { Box, Typography } from "@mui/material";

const defaultAcceptedFiles = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/heic",
  "image/tiff",
  "application/pdf",
];

type FileUpdateAndDownloadProps = {
  onFileUploaded: (files: FileList) => void;
  downloadUrl: string | null;
  fileName: string;
  noFileMessage: string;
  icon: string;
  /**
   * Array of accepted file types. Default to ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/heic", "image/tiff", "application/pdf"].
   */
  acceptedFiles?: string[];
  /**
   * Max file size in bytes. Default to 0 (no limit).
   */
  maxFileSize?: number;
  onFilesAdded?: (files: FileList) => void;
  onFileUpdate?: (files: FileList, id: string) => void;
  onDelete?: () => void;
  uploadFileId?: string;
  readonly?: boolean;
  isReadOnly?: boolean;
  uploading?: number;
  confirmationPageUploading?: boolean;
  isDigitalTimecardEntry?: boolean;
  isFacilityReport?: boolean;
};

function FileUpdateAndDownload(props: FileUpdateAndDownloadProps) {
  const acceptedFiles = props.acceptedFiles || defaultAcceptedFiles;
  const acceptedFilesString = acceptedFiles.join(",");
  const maxFileSize = props.maxFileSize ?? 0;
  const maxFileSizeInMb = maxFileSize / 1024 / 1024;
  const inputElement = useRef<HTMLInputElement | null>();
  const inputMultipleElement = useRef<HTMLInputElement | null>();

  const snackbar = useSnackbar();
  const { canAddFilesToFacilityReport } = usePermissions();

  function handleSave(files: FileList | null) {
    if (!files || !files[0]) return;

    const file = files[0];

    if (maxFileSize > 0) {
      if (file.size > maxFileSize) {
        snackbar.enqueueSnackbar(
          `Max file size is ${maxFileSizeInMb}mb. Please choose a smaller file!`
        );
        return;
      }
    }

    const currentFileType = getFileType(file);

    const fileType = acceptedFiles.find(function (element) {
      return element === currentFileType;
    });

    if (!fileType) {
      snackbar.enqueueSnackbar("File type not allowed. Select an image or pdf file.");
      return;
    }

    if (props.downloadUrl && props.onFileUpdate && props.uploadFileId) {
      props.onFileUpdate(files, props.uploadFileId);
    } else {
      props.onFileUploaded(files);
    }
  }

  function handleAddFiles(files: FileList | null) {
    if (!files || !files[0]) return;

    const filesArray = Array.from(files);

    if (maxFileSize > 0) {
      const filesArraySize = filesArray.reduce((acc, file) => acc + file.size, 0);

      if (filesArraySize > maxFileSize) {
        snackbar.enqueueSnackbar(
          `Max file size is ${maxFileSizeInMb}mb. Please choose smaller files!`
        );
        return;
      }
    }

    const hasInvalidFile = filesArray.some((file) => {
      const currentFileType = getFileType(file);

      const fileType = acceptedFiles.find(function (element) {
        return element === currentFileType;
      });

      return !fileType;
    });

    if (hasInvalidFile) {
      snackbar.enqueueSnackbar("File type not allowed. Select an image or pdf file.");
      return;
    }

    if (props.onFilesAdded) {
      props.onFilesAdded(files);
    }
  }

  const handleClickInputFile = () => {
    inputElement.current?.click();
  };

  const handleClickInputFileMultiple = () => {
    inputMultipleElement.current?.click();
  };

  const downloadFile = async () => {
    if (!props.downloadUrl) {
      return;
    }

    try {
      const blob = await PayrollApi.downloadFile(props.downloadUrl);
      const extention = blob.type.split("/")[1];
      await downloadBlob(new Blob([blob]), `${props.fileName}.${extention}`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar("There was an error downloading the file");
    }
  };

  const isUploading = (props.uploading ?? 0) > 0;
  const isConfirmationUploading = props.confirmationPageUploading;
  const hasDownloadUrl = props.downloadUrl && props.downloadUrl !== "";
  const canUploadFile = !props.readonly;
  const noFileUploaded =
    !isUploading && !isConfirmationUploading && !hasDownloadUrl && canUploadFile;

  const canDownload = !isUploading && !isConfirmationUploading && hasDownloadUrl;

  const canDelete =
    !isUploading &&
    hasDownloadUrl &&
    !props.isReadOnly &&
    props.onDelete &&
    !props.isDigitalTimecardEntry;

  const canUpload =
    !isUploading &&
    !isConfirmationUploading &&
    !props.isReadOnly &&
    !hasDownloadUrl &&
    canUploadFile;

  const canUpdate =
    !isUploading &&
    !isConfirmationUploading &&
    !props.isReadOnly &&
    hasDownloadUrl &&
    canUploadFile &&
    !props.isDigitalTimecardEntry;

  const canAddFilesFacilityReport =
    !!props.isFacilityReport &&
    canAddFilesToFacilityReport &&
    !isUploading &&
    !isConfirmationUploading &&
    !props.isReadOnly &&
    hasDownloadUrl &&
    canUploadFile;

  return (
    <>
      <Paper
        sx={{
          minWidth: 500,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          margin: 1.5,
          padding: 2,
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {noFileUploaded ? (
              <>
                <Typography fontWeight={500} color="#414141">
                  {props.noFileMessage}
                </Typography>
              </>
            ) : (
              <>
                {props.icon && <i className="material-icons-outlined"> {props.icon}</i>}
                <Typography>{props.fileName}</Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography color="#414141" fontSize={14}>
              Drag and drop files or{" "}
            </Typography>
            {(isUploading || isConfirmationUploading) && <CircularProgress />}
            {canDownload && (
              <Button onClick={downloadFile} sx={{ marginLeft: 0.5, paddin: 0.5 }}>
                Download
              </Button>
            )}
            {canDelete && (
              <Button sx={{ padding: 0.5 }} onClick={props.onDelete}>
                Delete
              </Button>
            )}
            {canUpload && (
              <>
                <Button sx={{ padding: 0.5 }} onClick={handleClickInputFile}>
                  upload
                </Button>
                <input
                  style={{ display: "none" }}
                  ref={(input) => (inputElement.current = input)}
                  type="file"
                  aria-label="Upload file"
                  accept={acceptedFilesString}
                  onChange={(e) => handleSave(e.target.files)}
                />
              </>
            )}
            {canAddFilesFacilityReport && (
              <>
                <Button sx={{ padding: 0.5 }} onClick={handleClickInputFileMultiple}>
                  Add Files
                </Button>
                <input
                  style={{ display: "none" }}
                  ref={(input) => (inputMultipleElement.current = input)}
                  type="file"
                  aria-label="Add files"
                  multiple={true}
                  accept={acceptedFilesString}
                  onChange={(e) => handleAddFiles(e.target.files)}
                />
              </>
            )}
            {canUpdate && (
              <>
                <Button sx={{ padding: 0.5 }} onClick={handleClickInputFile}>
                  Update
                </Button>
                <input
                  style={{ display: "none" }}
                  ref={(input) => (inputElement.current = input)}
                  type="file"
                  aria-label="Update file"
                  accept={acceptedFilesString}
                  onChange={(e) => handleSave(e.target.files)}
                />
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default FileUpdateAndDownload;
