import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Input } from "@mui/material";
import NumberFormat from "react-number-format";
import BillingCommons from "../billing/BillingCommons";
import PropTypes from "prop-types";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import DealsheetValueIcon from "./DealsheetValueIcon";

function NoData() {
  return "--";
}

function TaxableExpenses(props) {
  const expensesPaidOnFirstLastPayPeriod = ["BonusPay", "Mileage", "TravelReimbursement"];

  if (!props.expenses) return "";

  const handlePaidChange = (name, value) => {
    const newExpenses = props.expenses.map((r) => {
      return r.name === name ? { ...r, paid: value, proratedSyncBroken: true } : r;
    });
    props.onChange(newExpenses);
  };

  return (
    <Table
      sx={{
        minWidth: 650,
      }}
      className={" timecard-hours-entry-table"}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell align="right">Gross</TableCell>
          <TableCell align="right">Prorated</TableCell>
          <TableCell align="right">Paid</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.expenses.map((row) => {
          return (
            <TableRow key={row.name}>
              <TableCell
                component="th"
                scope="row"
                className={
                  expensesPaidOnFirstLastPayPeriod.includes(row.name) &&
                  (props.isFirstWeek || props.isLastWeek)
                    ? "timecard-expense-highlight"
                    : ""
                }
              >
                {row.title}
              </TableCell>

              <TableCell align="right">
                {row.gross === null ? (
                  <NoData />
                ) : (
                  <React.Fragment>
                    <NumberFormat
                      fixedDecimalScale={2}
                      decimalScale={2}
                      value={row.gross}
                      thousandSeparator={true}
                      prefix={"$"}
                      displayType={"text"}
                    />
                    <DealsheetValueIcon fieldName={row.name} arrayName="taxableDealsheetTypes" />
                  </React.Fragment>
                )}
              </TableCell>
              <TableCell align="right">
                {row.prorated === null ? (
                  <NoData />
                ) : (
                  <NumberFormat
                    fixedDecimalScale={2}
                    decimalScale={2}
                    value={row.prorated}
                    thousandSeparator={true}
                    prefix={"$"}
                    displayType={"text"}
                  />
                )}
              </TableCell>

              <TableCell align="right">
                {!row.isLocked ? (
                  <NumberFormat
                    className={
                      !row.proratedSyncBroken && row.paid > 0
                        ? "timecard-expense-syncing"
                        : !row.proratedSyncBroken && row.paid < 0
                        ? "timecard-expense-deduction"
                        : ""
                    }
                    customInput={Input}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    value={row.paid}
                    onChange={(e) => {
                      const val = parseFloat(e.target.value.replace(/[^0-9.-]+/g, ""));
                      if (!isNaN(val)) {
                        handlePaidChange(row.name, val);
                      }
                    }}
                    prefix={"$"}
                    thousandSeparator={true}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        handlePaidChange(row.name, 0);
                      }
                    }}
                    onClick={(e) => {
                      e.target.select();
                    }}
                  />
                ) : (
                  <NumberFormat
                    className={
                      !row.proratedSyncBroken && row.paid > 0
                        ? "timecard-expense-syncing"
                        : !row.proratedSyncBroken && row.paid < 0
                        ? "timecard-expense-deduction"
                        : ""
                    }
                    customInput={Input}
                    fixedDecimalScale={2}
                    decimalScale={2}
                    value={row.paid}
                    prefix={"$"}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                )}
                {row.gross === null && (
                  <DealsheetValueIcon fieldName={row.name} arrayName="taxableDealsheetTypes" />
                )}
              </TableCell>
            </TableRow>
          );
        })}
        <CanRenderChildren permissionName="canSeeTotalAmountTaxableExpense">
          <TableRow>
            <TableCell align="right" colSpan={3} style={{ height: "50px", fontWeight: "bold" }}>
              Total
            </TableCell>
            <TableCell align="right">
              <NumberFormat
                fixedDecimalScale={2}
                decimalScale={2}
                prefix={"$ "}
                value={BillingCommons.getExpensesTotal(props.expenses)}
                thousandSeparator={true}
                displayType={"text"}
                style={{ fontWeight: "bold" }}
              />
            </TableCell>
          </TableRow>
        </CanRenderChildren>
      </TableBody>
    </Table>
  );
}

TaxableExpenses.propTypes = {
  expenses: PropTypes.array,
  onChange: PropTypes.func,
  isFirstWeek: PropTypes.bool,
  isLastWeek: PropTypes.bool,
  isNonTaxable: PropTypes.bool,
};

export default TaxableExpenses;
