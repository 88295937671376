import { TITLE_CHANGED, DATES_CHANGED } from "../actions/actionTypes";

type Header = {
  title: string;
  startDate: string;
  endDate: string;
  dateToCompare: string;
};

const initialState: Header = {
  title: "",
  startDate: "",
  endDate: "",
  dateToCompare: new Date().toUTCString(),
};

interface HeaderActionTitleChanged {
  type: typeof TITLE_CHANGED;
  title: string;
}

interface HeaderActionDatesChanged {
  type: typeof DATES_CHANGED;
  startDate: string;
  endDate: string;
  dateToCompare: string;
}

type HeaderAction = HeaderActionTitleChanged | HeaderActionDatesChanged;

export default function headerReducer(state = initialState, action: HeaderAction): Header {
  switch (action.type) {
    case TITLE_CHANGED: {
      const newState = {
        ...state,
        title: action.title,
      };
      return newState;
    }
    case DATES_CHANGED: {
      const newState = {
        ...state,
        dateToCompare: new Date(Date.now()).toUTCString(),
        startDate: action.startDate,
        endDate: action.endDate,
      };
      return newState;
    }
    default:
      return state;
  }
}
