import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PayrollLockType } from "../../../commons/Enums";
import { LockType } from "../../../types/payPeriod/lockType";
import PayrollLocksApi from "../../../api/PayrollLocksApi";
import { LockPeriodStatus } from "types/payPeriod/lockPayPeriodStatus";
import { PayrollLockStatusTimecardsWithExpenses } from "types/payPeriod/PayrollLockStatusTimecardsWithExpenses";
import LockPayrollTimecardsWithExpensesDialogContent from "./LockPayrollTimecardsWithExpensesDialogContent";

type LockPayrollDialogProps = {
  open: boolean;
  onClose: () => void;
  onPayrollReport: () => void;
  onNoFinalReport: () => void;
  lockType: LockType;
};

const LockPayrollDialog = ({
  open,
  onClose,
  onPayrollReport,
  onNoFinalReport,
  lockType,
}: LockPayrollDialogProps) => {
  const initialMessage = "Is this the final payroll export?";
  const initialTitle = "Lock timecards";
  const unableTitle = "Unable to export final report";

  const [displayMainButtons, setDisplayMainButtons] = useState(true);
  const [mainTitle, setMainTitle] = useState(initialTitle);
  const [mainMessage, setMainMessage] = useState(initialMessage);
  const [isLocked, setIsLocked] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [showTimecardsWithExpenses, setShowTimecardsWithExpenses] = useState(false);
  const [timecardsWithExpenses, setTimecardsWithExpenses] = useState<PayrollLockStatusTimecardsWithExpenses[]>([]);

  const setPopup = (lockType: number) => {
    setMainTitle(initialTitle);
    const mainMessage =
      initialMessage +
      (lockType === PayrollLockType.RegularTimecard
        ? " You will mark all timecards in pending late."
        : ' You will mark all late timecards in pending as "Did Not Submit"');
    setMainMessage(mainMessage);
    setDisplayMainButtons(true);
  };

  const setUnablePopup = (message: string | object) => {
    setMainTitle(unableTitle);
    if (typeof message === "string") {
      setMainMessage(message);
    } else {
      setMainMessage("");
    }
    setDisplayMainButtons(false);
  };

  const validateLockedPayPeriod = async () => {
    try {
      const result: LockPeriodStatus = await PayrollLocksApi.GetPayrollLockStatusByType(lockType);
      setIsLocked(result.IsLocked);
      setIsloading(false);
      if (!result.IsLocked) {
        setPopup(lockType);
      }      
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setIsloading(true);
    validateLockedPayPeriod();
  }, [open]);

  const validateReport = async () => {
    try {
      const status: LockPeriodStatus[] = await PayrollLocksApi.GetPayrollLockStatus();
      const lockStatus = status.find((s) => s.LockType === lockType);

      if (!lockStatus || !lockStatus.CanLock) {
        setUnablePopup(lockStatus?.Message ?? "");
        setShowTimecardsWithExpenses(lockStatus?.HasTimesheetsWithExpenses ?? false);
        setTimecardsWithExpenses(lockStatus?.TimesheetsWithExpenses.map<PayrollLockStatusTimecardsWithExpenses>(x => ({ ...x, id: x.TimesheetId })) ?? []);
      } else {
        setIsLocked(true);
        onClose();
        onPayrollReport();
      }
    } catch (error: string | object | any) {
      setUnablePopup(error);
    }
  };

  const handleClose = () => {
    onClose();
    setShowTimecardsWithExpenses(false);
    setTimecardsWithExpenses([]);
  };

  const handlePayrollReport = () => {
    validateReport();
  };

  const handleOnNoFinalReport = () => {
    onNoFinalReport();
    onClose();
    setShowTimecardsWithExpenses(false);
    setTimecardsWithExpenses([]);
  };

  return (
    <>
      {isloading ? null : isLocked ? (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Timecards locked"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Everything has been locked
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleOnNoFinalReport} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{mainTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{mainMessage}</DialogContentText>
              {showTimecardsWithExpenses && (
                <LockPayrollTimecardsWithExpensesDialogContent
                timecardsWithExpenses={timecardsWithExpenses}
                />            
              )}
              </DialogContent>             
            {displayMainButtons ? (
              <DialogActions>
                <Button onClick={handleOnNoFinalReport} color="primary">
                  No
                </Button>
                <Button onClick={handlePayrollReport} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button onClick={handleOnNoFinalReport} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </div>
      )}
    </>
  );
};

export default LockPayrollDialog;
