import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import { daysOfWeek } from "types/constants";
import { FacilityDetail } from "types/facility/facilityDetail";

type WorkWeekStartProps = {
  facility: FacilityDetail;
  handleChange: (
    e: SelectChangeEvent<string | number | null>
  ) => void;
  showAcknowledge: boolean;
  isAcknowledgeWorkWeekChange: boolean;
  formErrors: {
    acknowledgeHasError: boolean;
  };
  handleChangeAcknowledge: (changed: boolean) => void;
};

export const WorkWeekStart = ({
  facility,
  showAcknowledge,
  formErrors,
  isAcknowledgeWorkWeekChange,
  handleChange,
  handleChangeAcknowledge,
}: WorkWeekStartProps) => {
  const handleAcknowledge = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleChangeAcknowledge(checked);
  };

  return (
    <Box>
      <FormControl variant="outlined" style={{ width: 250 }}>
        <InputLabel id="timecard-work-week-start-label">Work Week Start</InputLabel>
        <Select
          labelId="timecard-work-week-start-label"
          id="timecard-work-week-start"
          value={facility.workWeekStart}
          label="Work Week Start"
          fullWidth
          onChange={handleChange}
          name="workWeekStart">
          {daysOfWeek.map((day) => (
            <MenuItem key={day.value} value={day.value}>
              {day.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {showAcknowledge && (
        <Box style={{ position: "absolute" }}>
          <FormControl error={formErrors.acknowledgeHasError}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAcknowledgeWorkWeekChange}
                  onChange={handleAcknowledge}
                  name="acknowledgeWorkWeekChange"
                  color="primary"
                  style={{
                    transform: "scale(0.9)",
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: 14 }}>
                  Changing this alternate work week start date will have downstream impacts to
                  timecards for travelers in this facility
                </Typography>
              }
            />
            {!isAcknowledgeWorkWeekChange && formErrors.acknowledgeHasError && (
              <FormHelperText>Please select to acknowledge the changes</FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    </Box>
  );
};
