export default {
  getInvoiceSubTotalForPayroll(tableRows, adjustmentRows) {
    const timecardEntriesSubTotal = tableRows.reduce(function (a, row) {
      return a + row.rate * row.hours;
    }, 0);

    const adjustmentsSubTotal =
      adjustmentRows == undefined || adjustmentRows.length == 0
        ? 0
        : adjustmentRows.reduce(function (a, row) {
            return a + row.rate * row.hours;
          }, 0);

    return timecardEntriesSubTotal + adjustmentsSubTotal;
  },
  //Get invoice subtotal value
  getInvoiceSubTotalForBilling(tableRows, isFirstWeek, adjustmentRows) {
    if (isFirstWeek) {
      const timecardEntriesSubTotal = tableRows
        .filter((x) => x.name !== "RegularHours")
        .reduce(function (a, row) {
          return a + row.rate * row.hours;
        }, 0);

      const adjustmentsSubTotal =
        adjustmentRows == undefined || adjustmentRows.length == 0
          ? 0
          : adjustmentRows.reduce(function (a, row) {
              return a + row.rate * row.hours;
            }, 0);

      return (
        timecardEntriesSubTotal +
        adjustmentsSubTotal +
        this.getBillableHoursValue(tableRows) * this.getRegularHourRate(tableRows)
      );
    } else {
      return this.getInvoiceSubTotalForPayroll(tableRows, adjustmentRows);
    }
  },

  getRegularHourRate(table1Rows) {
    const regularHours =
      table1Rows.filter((x) => x.name === "RegularHours").length > 0
        ? table1Rows.filter((x) => x.name === "RegularHours")[0].hours
        : 0;
    const regularHoursRate =
      table1Rows.filter((x) => x.name === "RegularHours").length > 0
        ? table1Rows.filter((x) => x.name === "RegularHours")[0].rate
        : 0;
    const billableOrientationHours =
      table1Rows.filter((x) => x.name === "BillableOrientation").length > 0
        ? table1Rows.filter((x) => x.name === "BillableOrientation")[0].hours
        : 0;
    const billableOrientationRate =
      table1Rows.filter((x) => x.name === "BillableOrientation").length > 0
        ? table1Rows.filter((x) => x.name === "BillableOrientation")[0].rate
        : 0;

    const numerator =
      regularHours * regularHoursRate + billableOrientationHours * billableOrientationRate;

    const denominator = billableOrientationRate
      ? regularHours + billableOrientationHours
      : regularHours;

    return denominator ? numerator / denominator : 0;
  },

  //Total values for table3 rows
  getBillableHoursValue(table1Rows) {
    const regularHours =
      table1Rows.filter((x) => x.name === "RegularHours").length > 0
        ? table1Rows.filter((x) => x.name === "RegularHours")[0].hours
        : 0;
    const billableOrientationHours =
      table1Rows.filter((x) => x.name === "BillableOrientation").length > 0
        ? table1Rows.filter((x) => x.name === "BillableOrientation")[0].hours
        : 0;
    const billableOrientationRate =
      table1Rows.filter((x) => x.name === "BillableOrientation").length > 0
        ? table1Rows.filter((x) => x.name === "BillableOrientation")[0].rate
        : 0;
    const nonBillableOrientationHours =
      table1Rows.filter((x) => x.name === "NonBillableOrientation").length > 0
        ? table1Rows.filter((x) => x.name === "NonBillableOrientation")[0].hours
        : 0;

    return billableOrientationRate
      ? regularHours + billableOrientationHours - nonBillableOrientationHours
      : regularHours - nonBillableOrientationHours;
  },

  //Total discount value
  getDiscountValue(table1Rows, table2Rows, isFirstWeek, adjustments) {
    //Total values for table2 rows
    const discountSum = table2Rows.reduce(function (a, row) {
      return a + row.rate;
    }, 0);
    return (
      (this.getInvoiceSubTotalForBilling(table1Rows, isFirstWeek, adjustments) * discountSum) / 100
    );
  },

  //Total values for table3 rows
  getReimbursementValue(table3Rows) {
    return table3Rows.reduce(function (a, row) {
      return a + row.hours;
    }, 0);
  },

  //Total values for table4 rows
  getMilageTotal(table4Rows) {
    return table4Rows.reduce(function (a, row) {
      return a + row.rate * row.hours;
    }, 0);
  },

  getInvoiceTotal(table1Rows, table2Rows, table3Rows, table4Rows, isFirstWeek, adjustments) {
    return (
      this.getInvoiceSubTotalForBilling(table1Rows, isFirstWeek, adjustments) +
      this.getReimbursementValue(table3Rows) +
      this.getMilageTotal(table4Rows) -
      this.getDiscountValue(table1Rows, table2Rows, isFirstWeek)
    );
  },

  getGrandTotalPayrollSection(
    nonTaxableExpenses,
    taxableExpenses,
    timecardEntries,
    bonusExpenses,
    adjustments,
    expenses,
    nonTaxableAdjustments
  ) {
    nonTaxableAdjustments = nonTaxableAdjustments || [];
    const bonusTotal = bonusExpenses === undefined ? 0 : this.getExpensesTotal(bonusExpenses);
    const taxable = taxableExpenses === undefined ? 0 : this.getExpensesTotal(taxableExpenses);
    const nonTaxable =
      nonTaxableExpenses === undefined
        ? 0
        : this.getExpensesTotal(nonTaxableExpenses, nonTaxableAdjustments);
    const timeCard =
      timecardEntries === undefined
        ? 0
        : this.getInvoiceSubTotalForBilling(timecardEntries, false, adjustments);

    const expensesTotal =
      expenses === undefined ? 0 : this.getExpensesInJobAssignmentTotal(expenses);

    return taxable + nonTaxable + timeCard + bonusTotal + expensesTotal;
  },

  /**
   * @param {Array} expenseRows
   * @param {Array} nonTaxableAdjustments
   */
  getExpensesTotal(expenseRows, nonTaxableAdjustments) {
    nonTaxableAdjustments = nonTaxableAdjustments || [];

    const nonTaxableTotal = expenseRows.reduce(function (a, row) {
      return a + row.paid;
    }, 0);

    const adjustmentsTotal = nonTaxableAdjustments.reduce(function (a, row) {
      return a + row.paid;
    }, 0);

    return nonTaxableTotal + adjustmentsTotal;
  },

  getExpensesInJobAssignmentTotal(expenseRows) {
    return expenseRows.reduce(function (a, row) {
      return a + row.amount;
    }, 0);
  },
};
