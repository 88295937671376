import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropLoading = (): JSX.Element => {
  return (
    <Backdrop
      sx={{
        zIndex: 1201,
        color: "#fff",
      }}
      open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
