import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ExpensesPanelTable from "./ExpensesPanelTable";

type ExpensesPanelProps = {
  expenses: any[];
  legend?: string;
};

const ExpensesPanel = (props: ExpensesPanelProps) => {
  const { expenses, legend } = props;

  if (legend) {
    return (
      <Paper className="timecard-readonly-paper">
        <Typography sx={{ padding: "10px" }}>{legend}</Typography>
        <ExpensesPanelTable expenses={expenses}></ExpensesPanelTable>
      </Paper>
    );
  } else {
    return <ExpensesPanelTable expenses={expenses}></ExpensesPanelTable>;
  }
};

export default ExpensesPanel;
