export type Holiday = {
  id: string;
  type: number;
  name: string;
  description: string;
  date: Date;
};

export enum HolidayType {
  Static,
  Dynamic,
}
