import React from "react";
import { Avatar, Tooltip } from "@mui/material";

type HighLightIconProps = {
  showTooltip: boolean;
  iconColor?: string;
  tooltipText?: string;
};

const HightlightIcon = (props: HighLightIconProps) => {
  return props.showTooltip && props.tooltipText ? (
    <Tooltip title={props.tooltipText} placement="top">
      <Avatar
        sx={{
          width: 4.5,
          height: 4.5,
          backgroundColor: props.iconColor ?? "#f44336",
        }}>
        <i className="material-icons-outlined">error_outline</i>
      </Avatar>
    </Tooltip>
  ) : (
    <Avatar
      sx={{
        width: 4.5,
        height: 4.5,
        backgroundColor: "#f3f3f3",
      }}>
      <i className="material-icons-outlined" style={{ color: "#b8b5b5" }}>
        error_outline
      </i>
    </Avatar>
  );
};

export default HightlightIcon;
