import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BillingCommons from "../billing/BillingCommons";

type TotalSummaryDetailProps = {
  nonTaxableExpenses?: any[];
  taxableExpenses?: any[];
  timecardEntries?: any[];
  bonusExpenses?: any[];
  adjustments?: any[];
  legend: string;
  expenses?: any[];
  nonTaxableAdjustments?: any[];
};

const TotalSummaryDetail = (props: TotalSummaryDetailProps) => {
  const {
    nonTaxableExpenses,
    taxableExpenses,
    timecardEntries,
    bonusExpenses,
    legend,
    adjustments,
    expenses,
    nonTaxableAdjustments,
  } = props;
  return (
    <>
      <Paper className="timecard-readonly-paper">
        <Typography sx={{ padding: "10px" }}></Typography>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>
            <TableRow>
              <TableCell
                align="right"
                colSpan={6}
                sx={{
                  width: "550px",
                  height: "50px",
                  fontWeight: "bold",
                }}>
                {legend}
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"$ "}
                  value={BillingCommons.getGrandTotalPayrollSection(
                    nonTaxableExpenses,
                    taxableExpenses,
                    timecardEntries,
                    bonusExpenses,
                    adjustments,
                    expenses,
                    nonTaxableAdjustments
                  )}
                  thousandSeparator={true}
                  displayType={"text"}
                  style={{ fontWeight: "bold" }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default TotalSummaryDetail;
