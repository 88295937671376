import React from "react";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";

type CustomTabsProps = {
  tabItems: TabItem[];
};

type TabItem = {
  title: string;
  content: JSX.Element;
};

export default function CustomTabs({ tabItems }: CustomTabsProps): JSX.Element {
  const [value, setValue] = React.useState("0");

  const handleChange = (_event: React.ChangeEvent<any>, newValue: string): void => {
    setValue(newValue);
  };

  return (
    <div>
      <TabContext value={value}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          {tabItems.map((tabItem, index) => {
            const key = `${index}`;
            return <Tab key={key} value={key} label={tabItem.title} />;
          })}
        </Tabs>
        {tabItems.map((tabItem, index) => {
          const key = `${index}`;
          return (
            <TabPanel key={key} value={key}>
              {tabItem.content}
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
}
