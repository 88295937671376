import React, { useState, useEffect } from "react";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Note } from "types/notes";
import CustomTabs from "components/shared/components/CustomTabs";
import { NotesUserType } from "commons/Enums";
import useGroupedNotes from "hooks/useGroupedNotes";
import NotesPanelContent from "./NotesPanelContent";

type NotesPanelProps = {
  title: string;
  notes: Note[];
  notesFilter: string;
  isExpanded: boolean;
};

const NotesPanel = ({ title, notes, notesFilter, isExpanded }: NotesPanelProps): JSX.Element => {
  const filteredNotes = notes.filter((note) => note.noteType === notesFilter);
  const { allNotes, systemNotes, internalNotes, travelerNotes } = useGroupedNotes(filteredNotes);
  const [expanded, setExpanded] = useState(isExpanded);

  const tabItems = [
    {
      title: NotesUserType.All,
      content: <NotesPanelContent notesList={allNotes} />,
    },
    {
      title: NotesUserType.System,
      content: <NotesPanelContent notesList={systemNotes} />,
    },
    {
      title: NotesUserType.Internal,
      content: <NotesPanelContent notesList={internalNotes} />,
    },
  ];

  if (title === "Payroll") {
    tabItems.push({
      title: NotesUserType.Traveler,
      content: <NotesPanelContent notesList={travelerNotes} />,
    });
  }

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion style={{ marginBottom: "20px" }} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-content"
        id="panel-header">
        <Typography style={{ fontWeight: "600", fontSize: "17px" }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        <CustomTabs tabItems={tabItems} />
      </AccordionDetails>
    </Accordion>
  );
};

export default NotesPanel;
