import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import { Divider, ListItemSecondaryAction } from "@mui/material";
import { SetRowData } from "./AnalyticHelper";

const DataListContent = ({ data }) => {
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      sx={{
        width: "100%",
        maxWidth: 500,
        height: "30vh",
      }}
    >
      {data &&
        SetRowData(data).map((row) => (
          <div key={row.column0}>
            <ListItem sx={{ paddingLeft: row.indent ? 6 : 0, fontSize: 14 }}>
              <ListItemText disableTypography primary={row.column0} />
              <ListItemSecondaryAction>{row.column1}</ListItemSecondaryAction>
            </ListItem>
            <Divider variant="fullWidth" />
          </div>
        ))}
    </List>
  );
};

DataListContent.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    header: PropTypes.object,
    rows: PropTypes.array,
  }),
};

export default DataListContent;
