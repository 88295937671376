import React, { SyntheticEvent, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useFilterAdvancedSearch from "hooks/useFilterAdvancedSearch";
import { getTravelers } from "api/advancedSearchApi";
import { TravelerState } from "types/traveler";

type TravelerSearchProps = {
  currentTraveler?: TravelerState;
  handleChange: (event: SyntheticEvent<Element, Event>, newValue: TravelerState) => void;
};

const TravelerSearch = ({ currentTraveler, handleChange }: TravelerSearchProps) => {
  const [entities, loading, handleInputChange, isOptionSelected] = useFilterAdvancedSearch(
    getTravelers,
    (option, value) =>
      `${option.firstName}|${option.lastName}|${option.ssn}|${option.travelerIds?.join("|")}` ===
      `${value.firstName}|${option.lastName}|${option.ssn}|${value.travelerIds?.join("|")}`
  );

  const getOptionLabel = (option: TravelerState) => {
    return option ? `${option.firstName} ${option.lastName} - ${option.ssn}` : "";
  };

  const renderInput = useCallback(
    (params) => {
      return (
        <TextField
          {...params}
          label="Traveler"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [loading]
  );

  return (
    <Autocomplete
      id="travelerSearch"
      size="small"
      value={currentTraveler ?? null}
      getOptionLabel={getOptionLabel}
      getOptionKey={(option) => option.travelerIds.join("|")}
      options={entities}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderInput={renderInput}
      isOptionEqualToValue={isOptionSelected}
    />
  );
};

export default TravelerSearch;
