import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import PayrollApi from "../../../api/PayrollApi";
import { TimecardUploadSource } from "commons/Enums";

const TimecardFileIcon = (props) => {
  const { timecardUploadId, uploadSource } = props;
  const [fillColor, setFillColor] = useState("");

  useEffect(() => {
    let newClass = "";
    if (timecardUploadId && uploadSource !== null) {
      switch (uploadSource) {
        case TimecardUploadSource.Unknown:
        case TimecardUploadSource.AutoAttaching:
          newClass = "auto-attaching";
          break;
        case TimecardUploadSource.Manual:
          newClass = "manual";
          break;
        case TimecardUploadSource.DigitalTimecards:
          newClass = "digital-timecards";
          break;
        case TimecardUploadSource.Spreadsheet:
          newClass = "spreadsheet";
          break;

        default:
          break;
      }
    }

    setFillColor(newClass);
  }, [timecardUploadId, uploadSource]);

  const onIconClick = () => {
    if (timecardUploadId) {
      PayrollApi.downloadTimecardFile(timecardUploadId, "Timecard File");
    }
  };

  return (
    <IconButton
      disabled={!timecardUploadId}
      sx={{
        padding: "0!important",
        margin: "0",
      }}
      onClick={onIconClick}
      size="large"
      data-testid="click-to-download">
      <Tooltip title={"Click to Download"} placement="top">
        <Avatar className={`file-attached-indicator ${fillColor}`}>
          <i className={`material-icons-outlined`}>description</i>
        </Avatar>
      </Tooltip>
    </IconButton>
  );
};

TimecardFileIcon.propTypes = {
  timecardUploadId: PropTypes.string,
  uploadSource: PropTypes.number,
};

export default TimecardFileIcon;
