import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { MTableToolbar } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE_TABLE_SWITCH_NETSUITE_CHANGED } from "../../../redux/actions/actionTypes";
import CanRenderChildren from "../../shared/functions/CanRenderChildren";

const ProfileToolbar = (props) => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);

  const handleSwitchChange = () => {
    dispatch({
      type: PROFILE_TABLE_SWITCH_NETSUITE_CHANGED,
      switchState: !profileState.switchState,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <CanRenderChildren permissionName="canRetryNetSuite">
        <FormControlLabel
          sx={{
            display: "block",
          }}
          control={
            <Switch
              checked={profileState.switchState}
              onChange={handleSwitchChange}
              name="netSuiteFilter"
              color="primary"
            />
          }
          label="Failed NetSuite Uploads"
        />
      </CanRenderChildren>
      <MTableToolbar {...props} />
    </div>
  );
};

export default ProfileToolbar;
