import { Container, Grid } from "@mui/material";
import { Alert, AlertTitle } from "@mui/lab";
import { getRoles, msalInstance } from "commons/authConfig";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import { useHistory } from "react-router";
import { TITLE_CHANGED } from "redux/actions/actionTypes";

const Unauthorized = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Unauthorized" });
    const userRoles = getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles);

    if (userRoles.length > 0) {
      dispatch({ type: TITLE_CHANGED, title: "" });
      history.replace("/");
    }
  }, []);

  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer as NodeJS.Timeout);
  }, [counter]);

  useEffect(() => {
    if (counter === 0) {
      msalInstance.logoutRedirect({ account: msalInstance.getActiveAccount() }).catch((e) => {
        console.error(e);
      });
    }
  }, [counter]);

  return (
    <div className="view-container">
      <Container maxWidth="md" sx={{ marginTop: 20 }}>
        <Grid container>
          <Grid item xs={12}>
            <Alert
              severity="warning"
              sx={{
                flexGrow: 1,
                textAlign: "center",
              }}>
              <AlertTitle sx={{ textAlign: "center" }}>Attention</AlertTitle>
              <p>Your don&apos;t have permission to access this page, please contact Dave Mohr.</p>
              <p>You&apos;re getting logout in {counter} seconds...</p>
            </Alert>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Unauthorized;
