import { Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";

type ReferralsDocumentsDialogContentProps = {
  updatedEmail: string;
  setUpdatedEmail: (value: string) => void;
  updateEmail: boolean;
  setUpdateEmail: (value: boolean) => void;
  hasError: boolean;
  explanation: string;
  checkboxLabel: string;
  referrerEmail: string;
};

const ReferralsDocumentsDialogContent = ({
  setUpdatedEmail,
  updatedEmail,
  updateEmail,
  setUpdateEmail,
  hasError,
  explanation,
  checkboxLabel,
  referrerEmail,
}: ReferralsDocumentsDialogContentProps) => {
  function handleToggleChange(event: ChangeEvent<HTMLInputElement>) {
    setUpdateEmail(event.target.checked);
  }

  return (
    <>
      <Typography>{explanation}</Typography>
      <Typography>The email will be sent to {referrerEmail}</Typography>
      <FormControlLabel
        control={<Checkbox checked={updateEmail} onChange={handleToggleChange} color="primary" />}
        label={checkboxLabel}
      />
      <TextField
        label="New Email Address"
        variant="outlined"
        fullWidth
        sx={{ mt: 1 }}
        disabled={!updateEmail}
        type="email"
        value={updatedEmail}
        onChange={(e) => setUpdatedEmail(e.target.value)}
      />
      {hasError && <Typography color="error">Please enter a valid email address</Typography>}
    </>
  );
};

export default ReferralsDocumentsDialogContent;
