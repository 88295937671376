import { Expense, OptionType } from "types/expenses/Expense";
import { msalApiFetch } from "../commons/authConfig";
import ExpenseFiltersRequest from "types/expenses/ExpenseFiltersRequest";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "expenses";

export default {
  async fileUpload(file: Blob, expenseId: string) {
    const url = `${baseUrl}/${endpoint}/${expenseId}/file-upload`;

    return msalApiFetch(url, {
      method: "POST",
      body: file,
    }).then((result) => {
      if (!result.ok) throw result;
      return result.text();
    });
  },

  fileDelete(fileName: string) {
    const url = `${baseUrl}/${endpoint}/delete-file/${fileName}`;

    return msalApiFetch(url, {
      method: "DELETE",
    }).then((result) => {
      if (!result.ok) throw result;
      return result;
    });
  },

  fileUpdate(file: Blob, oldFileName: string) {
    const url = `${baseUrl}/${endpoint}/file-update/${oldFileName}`;

    return msalApiFetch(url, {
      method: "PUT",
      body: file,
    }).then((result) => {
      if (!result.ok) throw result;
      return result.text();
    });
  },

  getExpenseNotes(expenseId: string) {
    const url = `${baseUrl}/${endpoint}/${expenseId}/get-notes`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  /**
   * Entity representing a state of an expense
   * @typedef {Object} ExpenseFileDto
   * @property {string} id
   * @property {string} fileName
   */
  /**
   *
   * @param {string} expenseId
   * @returns {Promise<ExpenseFileDto[]>}
   */
  getExpenseFiles(expenseId: string) {
    const url = `${baseUrl}/${endpoint}/${expenseId}/get-files`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  /**
   * Entity representing a state of an expense
   * @typedef {Object} ExpenseState
   * @property {string} id
   * @property {string} name
   */
  /**
   * @typedef {Object} AllowAction
   * @property {ExpenseState} state
   * @property {boolean} canSave
   * @property {boolean} canSubmit
   * @property {boolean} canDelete
   * @property {boolean} canApprove
   * @property {boolean} canReject
   */
  /**
   * @param {string} [expenseId]
   * @param {string} [jobAssignmentStartDate]
   * @returns {Promise<{maxAmount:number;startPayDate:string;allowedActions:Array<AllowAction>}>}
   */
  getExpenseOptions(expenseId: string, jobAssignmentStartDate?: string) {
    const url = new URL(`${baseUrl}/expense-options/${expenseId ?? "new"}`);

    if (jobAssignmentStartDate) {
      url.searchParams.append("jobAssignmentStartDate", jobAssignmentStartDate);
    }

    return msalApiFetch(url.href, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  getExpenseTypes() {
    const url = `${baseUrl}/expense-types`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  getExpenseStates(): Promise<{ expenseStates: OptionType[] }> {
    const url = `${baseUrl}/expense-states`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  updateExpenseData(expenseId: string, expenseData: any) {
    const url = `${baseUrl}/${endpoint}/${expenseId}`;
    return msalApiFetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(expenseData),
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  addExpense(expenseData: any) {
    const url = `${baseUrl}/${endpoint}`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(expenseData),
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  addExpenseStateChange(expenseStateChangeData: any) {
    const url = `${baseUrl}/${endpoint}-statechange`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(expenseStateChangeData),
    }).then((result) => {
      if (!result.ok) throw result;
      return result;
    });
  },

  async getExpenses(expenseRequest: ExpenseFiltersRequest): Promise<Expense[]> {
    const url = `${baseUrl}/${endpoint}/search`;
    const result = await msalApiFetch(url, {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(expenseRequest),
      method: "POST",
    });
    if (!result.ok) throw result;
    return await result.json();
  },

  getExpenseById(expenseId: string) {
    const url = `${baseUrl}/${endpoint}/${expenseId}`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.json();
    });
  },

  deleteExpense(expenseId: string) {
    const url = `${baseUrl}/${endpoint}/${expenseId}`;
    return msalApiFetch(url, {
      method: "DELETE",
    }).then((result) => {
      if (!result.ok) throw result;
    });
  },

  /**
   * Downloads the files of an expense
   * @param expenseId Id of the expense
   */
  downloadExpenseFiles(expenseId: string): Promise<Blob> {
    const url = `${baseUrl}/${endpoint}/${expenseId}/downloadFiles`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((result) => {
      if (!result.ok) throw result;
      return result.blob();
    });
  },
};
