import React from "react";
import { Button } from "@mui/material";
import { useState } from "react";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import { ReferralsFAQDialogContent } from "./ReferralsFAQDialogContent";

export  const ReferralsFAQDialog = () => {
  const [open, setOpen] = useState(false);
    
  const handleClickOpen = () => {
    setOpen(true);
  };
    
  const handleClose = () => {
    setOpen(false);
  };
    
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        F.A.Q.
      </Button>
      <ConfirmDialog 
        isOpen={open} 
        onClose={handleClose} 
        onConfirm={handleClose} 
        displayConfirmButton={false} 
        cancelText="Close" 
        dialogTitle="Frequently Asked Questions" 
        content={<ReferralsFAQDialogContent />} />
    </div>
  );
};
