import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BillingCommons from "../billing/BillingCommons";
import DealsheetValueIcon from "./DealsheetValueIcon";

function NoData() {
  return "--";
}

const TaxableExpensesReadonly = (props) => {
  const { expenses, legend, isFirstWeek, isLastWeek } = props;
  const expensesPaidOnFirstLastPayPeriod = ["BonusPay", "Mileage", "TravelReimbursement"];

  if (!expenses) return "";

  return (
    <>
      <Paper className="timecard-readonly-paper">
        <Typography
          style={{
            padding: "10px",
          }}
        >
          {legend}
        </Typography>
        <Table
          style={{
            minWidth: 650,
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">Gross</TableCell>
              <TableCell align="right">Prorated</TableCell>
              <TableCell align="right">Paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  className={
                    expensesPaidOnFirstLastPayPeriod.includes(row.name) &&
                    (isFirstWeek || isLastWeek)
                      ? "timecard-expense-highlight"
                      : ""
                  }
                >
                  {row.title}
                </TableCell>
                <TableCell align="right">
                  {row.gross === null ? (
                    <NoData />
                  ) : (
                    <React.Fragment>
                      <NumberFormat
                        fixedDecimalScale={2}
                        decimalScale={2}
                        value={row.gross}
                        thousandSeparator={true}
                        prefix={"$"}
                        displayType={"text"}
                      />
                      <DealsheetValueIcon fieldName={row.name} arrayName="taxableDealsheetTypes" />
                    </React.Fragment>
                  )}
                </TableCell>
                <TableCell align="right">
                  {row.prorated === null ? (
                    <NoData />
                  ) : (
                    <NumberFormat
                      fixedDecimalScale={2}
                      decimalScale={2}
                      value={row.prorated}
                      thousandSeparator={true}
                      prefix={"$"}
                      displayType={"text"}
                    />
                  )}
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale={2}
                    decimalScale={2}
                    value={row.paid}
                    thousandSeparator={true}
                    prefix={"$"}
                    displayType={"text"}
                  />
                  {row.gross === null && (
                    <DealsheetValueIcon fieldName={row.name} arrayName="taxableDealsheetTypes" />
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right" colSpan={3} style={{ height: "50px", fontWeight: "bold" }}>
                Total
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  fixedDecimalScale={2}
                  decimalScale={2}
                  prefix={"$ "}
                  value={BillingCommons.getExpensesTotal(expenses)}
                  thousandSeparator={true}
                  displayType={"text"}
                  style={{ fontWeight: "bold" }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

TaxableExpensesReadonly.propTypes = {
  expenses: PropTypes.array,
  legend: PropTypes.string.isRequired,
  isFirstWeek: PropTypes.bool,
  isLastWeek: PropTypes.bool,
};

export default TaxableExpensesReadonly;
