import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "jobAssignments";

export default {
  getAll() {
    const url = `${baseUrl}/${endpoint}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByTimecardPeriod(startDate, endDate) {
    const url = `${baseUrl}/${endpoint}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getRecruiterAssignments(recruiterId) {
    const url = `${baseUrl}/${endpoint}/recruiter/${recruiterId}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByTeamLeadTimecardPeriod(teamLeadId, startDate, endDate) {
    const url = `${baseUrl}/${endpoint}/teamlead/${teamLeadId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByAssistantTimecardPeriod(assistantId, startDate, endDate) {
    const url = `${baseUrl}/${endpoint}/assistant?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getByRecruiterTimecardPeriod(recruiterId, startDate, endDate) {
    const url = `${baseUrl}/${endpoint}/recruiter/${recruiterId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getHistoryByRecruiterTimecardPeriod(recruiterId, startDate, endDate) {
    const url = `${baseUrl}/${endpoint}/recruiter-history/${recruiterId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getBySalesRepTimecardPeriod(salesRepId, startDate, endDate) {
    const url = `${baseUrl}/${endpoint}/salesrep/${salesRepId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getHistoryBySalesRepTimecardPeriod(recruiterId, startDate, endDate) {
    const url = `${baseUrl}/${endpoint}/salesrep-history/${recruiterId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  saveNote(jobId, messageBody) {
    const url = `${baseUrl}/jobassignment/${jobId}/notes/save`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: messageBody,
    });
  },
  //Api to upload confirmation page
  fileUpload(type, jobId, files) {
    const extension = files[0].name.split(".").pop();
    const url = `${baseUrl}/${endpoint}/${type}/${jobId}.${extension}`;
    return msalApiFetch(url, {
      method: "POST",
      body: files[0],
    });
  },
  getDealSheetTilesForCandidate(candidateId) {
    const url = `${baseUrl}/dealSheets/${candidateId}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getDealSheetTilesForCandidateAndRecruiter(candidateId, recruiterId) {
    const url = `${baseUrl}/recruiter/${recruiterId}/dealSheets/${candidateId}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  getByVmsTimecardPeriod(vmsId, startDate, endDate) {
    const url = `${baseUrl}/timesheets/vms/${vmsId}?startDate=${startDate}&endDate=${endDate}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
  getDealsheetsTilesByCandidateName(candidateName, maxResults) {
    const url = `${baseUrl}/dealSheets?filter=${candidateName}&maxResults=${maxResults}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  async getJobAssignmentsForwardingHistory(timesheetId) {
    const url = `${baseUrl}/${endpoint}/${timesheetId}/ForwardingHistory`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  getJobAssignmentsByRecruiter(recruiterId, filter) {
    return msalApiFetch(`${baseUrl}/recruiter/${recruiterId}/assignments?filter=${filter}`).then(
      (data) => {
        if (!data.ok) throw data;
        return data.json();
      }
    );
  },
  getJobAssignmentById(jobId) {
    const url = `${baseUrl}/jobassignment/${jobId}`;
    return msalApiFetch(url).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
};
