import React, { useEffect, useState } from "react";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import ResetDidNotWorkButton from "components/shared/components/ResetDidNotWorkButton";
import { useSelector } from "react-redux";
import moment from "moment";
import PayrollLocksApi from "api/PayrollLocksApi";
import { TimesheetSubStatusLabel } from "commons/Enums";
import { LockPeriodStatus } from "types/payPeriod/lockPayPeriodStatus";

type TimecardDidNotWorkProps = {
  timesheet?: any;
  resetCallback?: () => void;
};

const TimecardDidNotWork = (props: TimecardDidNotWorkProps) => {
  const { timesheet, resetCallback } = props;
  const startDate = useSelector((state: RootState) => state.header.startDate);

  const [showReset, setShowReset] = useState(true);
  const isCurrentPayPeriodTimesheet = !!(
    timesheet &&
    startDate &&
    moment(startDate).isSame(timesheet.timesheetStartDate)
  );

  const setPayrollStatus = async () => {
    try {
      const response: LockPeriodStatus[] = await PayrollLocksApi.GetPayrollLockStatus();
      const mainLock = response[0];
      const lateLock = response[1];

      const isMainLocked = mainLock.Status === "Locked";
      const isLateLocked = lateLock.Status === "Locked";

      if (!isCurrentPayPeriodTimesheet) {
        setShowReset(false);
        return;
      }

      if (isMainLocked && isLateLocked) {
        setShowReset(false);
        return;
      }

      if (timesheet.uploadedToNetSuite) {
        setShowReset(false);
        return;
      }

      if (!timesheet.isLate && isMainLocked) {
        setShowReset(false);
        return;
      }

      setShowReset(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setPayrollStatus();
  }, [isCurrentPayPeriodTimesheet, timesheet]);

  const action = timesheet?.subStatus === TimesheetSubStatusLabel.DidNotWork ? "work" : "submit";

  return (
    <div style={{ padding: 15, paddingLeft: 30 }} className="timecard-did-not-work">
      This traveler did not {action}, and did not have any expenses{" "}
      {showReset && (
        <CanRenderChildren permissionName="canResetDidNotWork">
          {timesheet && (
            <ResetDidNotWorkButton
              timesheetId={timesheet.id}
              resetCallback={resetCallback}></ResetDidNotWorkButton>
          )}
        </CanRenderChildren>
      )}
    </div>
  );
};

export default TimecardDidNotWork;
