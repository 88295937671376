import * as actionTypes from "../actions/actionTypes";

export function dtUsersTableReducer(state: any = null, action: any) {
  switch (action.type) {
    case actionTypes.DTUSERS_TABLE_CHANGED:
      return { ...state, filters: action.filters };
    case actionTypes.DTUSERS_EMAIL_CHANGED:
      return { ...state, email: action.value };
    case actionTypes.DTUSERS_NAME_CHANGED:
      return { ...state, name: action.value };
    case actionTypes.DTUSERS_CHBXPAYPERIOD_CHANGED:
      return { ...state, searchByPayPeriod: action.value };
    default:
      return { ...state };
  }
}
