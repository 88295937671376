import React, { useCallback, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import TimesheetApi from "api/TimesheetApi";
import ConfirmDialog from "components/shared/components/ConfirmDialog";

type ResetDidNotWorkButtonProps = {
  timesheetId: string;
  resetCallback?: () => void;
  style?: React.CSSProperties;
};

const ResetDidNotWorkButton = (props: ResetDidNotWorkButtonProps): JSX.Element => {
  const { timesheetId, resetCallback, style } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dialogText, setDialogText] = useState("Are you sure you want to reset this timecard?");
  const [isLoading, setIsLoading] = useState(false);

  const getRecruiterNetSuiteUploadStatus = (): void => {
    setIsLoading(true);
    TimesheetApi.getRecruiterNetSuiteLockStatus(timesheetId)
      .then((result) => {
        if (result) {
          setDialogText(dialogText + " It will be marked as a late timecard");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onConfirmation = useCallback(() => {
    TimesheetApi.resetDidNotWork(timesheetId)
      .then(() => {
        if (typeof resetCallback === "function") {
          resetCallback();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onButtonClicked = useCallback(() => {
    setIsModalOpen(true);
    getRecruiterNetSuiteUploadStatus();
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <IconButton
        style={style}
        sx={{
          padding: "0!important",
          margin: "0",
        }}
        onClick={onButtonClicked}
        size="large">
        <Tooltip title={"Reset to Pending"} placement="top">
          <RotateLeftOutlinedIcon />
        </Tooltip>
      </IconButton>
      <ConfirmDialog
        dialogTitle="Timecard Status Reset"
        content={dialogText}
        isOpen={isModalOpen}
        onConfirm={onConfirmation}
        onClose={closeModal}
        isLoading={isLoading}></ConfirmDialog>
    </>
  );
};

export default ResetDidNotWorkButton;
