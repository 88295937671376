import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextareaAutosize,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import TimesheetApi from "api/TimesheetApi";

type AcknowledgeRateChangesProps = {
  timesheetId: string;
  onRateChangesAcknowledged: () => void;
};

export const AcknowledgeRateChanges = ({
  timesheetId,
  onRateChangesAcknowledged,
}: AcknowledgeRateChangesProps) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const toggleModal = () => {
    setConfirmOpen(!confirmOpen);
    setText("");
  };

  const handleNoteChange = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    const newText = event.currentTarget.value;
    setText(newText);
  };

  const handleAcknowledgeRates = async () => {
    setLoading(true);
    try {
      await TimesheetApi.acknowledgeRatesChanged(timesheetId, text);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      toggleModal();
      onRateChangesAcknowledged();
    }
  };

  return (
    <>
      <Alert
        severity="warning"
        sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <Box>
            <Typography fontSize={13}>Some rates for this timecard have been updated.</Typography>{" "}
            <Typography fontSize={13}>Please review and confirm the rate values.</Typography>
          </Box>
          <Button variant="text" sx={{ ml: 2 }} onClick={toggleModal}>
            Acknowledge
          </Button>
        </Box>
      </Alert>

      <Dialog open={confirmOpen} onClose={toggleModal}>
        <DialogTitle sx={{ textAlign: "center", textTransform: "capitalize" }}>
          Acknowledge Updated Rates
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText fontSize={16} textAlign="center">
            You are about to confirm that the updated rates for this timecard are valid. Please add
            a brief note addressing the rates.
          </DialogContentText>
          <Box mx={5} my={2}>
            <TextareaAutosize
              value={text}
              onChange={handleNoteChange}
              style={{ width: "100%", resize: "none" }}
              minRows={6}
              maxRows={8}
              placeholder="Enter note here..."
              maxLength={250}
            />
          </Box>
          <DialogActions>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-around"}
              paddingTop={2}
              px={6}
              width="100%">
              <Button
                size="medium"
                fullWidth
                variant="outlined"
                style={{ marginRight: 20 }}
                onClick={toggleModal}>
                Cancel
              </Button>
              <LoadingButton
                size="medium"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAcknowledgeRates}
                loading={loading}>
                Confirm
              </LoadingButton>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
