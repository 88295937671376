import React from "react";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

export default function TimeCardAdjusmentsIcon(props) {
  const icon = "build";
  let toolTipText = "";
  if (props.isAdjustment) {
    toolTipText = "This is an adjustment timecard.";
  } else if (props.hasAdjustment) {
    toolTipText = "This timecard contains adjusted items.";
  }

  const renderAvatar = () => {
    if (props.isAdjustment) {
      return (
        <Avatar
          sx={{
            backgroundColor: "rgb(255, 145, 0)!important",
          }}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    } else if (props.hasAdjustment) {
      return (
        <Avatar
          sx={{
            backgroundColor: "rgb(134, 195, 78)!important",
          }}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    } else {
      return (
        <Avatar className={`file-attached-indicator`}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    }
  };

  return (
    <Tooltip title={toolTipText} placement="top">
      {renderAvatar()}
    </Tooltip>
  );
}

TimeCardAdjusmentsIcon.propTypes = {
  isAdjustment: PropTypes.bool,
  hasAdjustment: PropTypes.bool,
};
