import { msalApiFetch } from "commons/authConfig";
import { ReferralTableItem } from "types/referrals";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "referrals";
const jsonContentType = { "Content-Type": "application/json" };

export async function getReferrals(
  includeZeroHours: boolean,
  includeCanceled: boolean,
  showOnlyErrors: boolean
): Promise<ReferralTableItem[]> {
  const url = `${baseUrl}/${endpoint}`;
  return msalApiFetch(url, {
    method: "POST",
    headers: jsonContentType,
    body: JSON.stringify({ includeZeroHours, includeCanceled, showOnlyErrors }),
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}

export async function getReferralById(referralId: string): Promise<ReferralTableItem> {
  const url = `${baseUrl}/${endpoint}/${referralId}`;
  return msalApiFetch(url, {
    method: "GET",
    headers: jsonContentType,
  }).then((result: Response) => {
    if (!result.ok) throw result;
    return result.json();
  });
}

export async function sendDocumentsToReferrer(dealId: string, email: string): Promise<void> {
  const url = `${baseUrl}/${endpoint}/sendPaperwork`;

  const trimmedEmail = email.trim();

  const body = { dealId, ...(trimmedEmail && { email: trimmedEmail }) };

  return msalApiFetch(url, {
    method: "POST",
    headers: jsonContentType,
    body: JSON.stringify(body),
  });
}

export async function markReferralAsToBePaid(dealId: string): Promise<string> {
  const url = `${baseUrl}/${endpoint}/${dealId}/updateToBePaid`;
  const result = await msalApiFetch(url, {
    method: "POST",
    headers: jsonContentType,
  });
  let error = "";

  await result.json().then((data: string[]) => {
    error = data?.[0] ?? "";
  });

  return error;
}

export async function sendEmailReminderToReferrer(dealId: string, email: string): Promise<void> {
  const url = `${baseUrl}/${endpoint}/email-reminder`;

  const trimmedEmail = email.trim();

  const body = { dealId, ...(trimmedEmail && { email: trimmedEmail }) };

  return msalApiFetch(url, {
    method: "POST",
    headers: jsonContentType,
    body: JSON.stringify(body),
  });
}

export async function syncDeal(dealId: string): Promise<void> {
  const url = `${baseUrl}/${endpoint}/sync/${dealId}`;

  return msalApiFetch(url, {
    method: "POST",
    headers: jsonContentType,
  });
}
