import moment from "moment";
import { FilterType, Order } from "./Types";

export type OrderComparator = "default" | "date";

export const descendingComparator = <T>(
  a: T,
  b: T,
  orderBy: keyof T,
  comparator: OrderComparator
) => {
  if (!a[orderBy] || a[orderBy] === "N/A") return 1;
  if (!b[orderBy] || b[orderBy] === "N/A") return -1;

  if (comparator === "date") {
    return moment(a[orderBy] as string).isBefore(b[orderBy] as string) ? 1 : -1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
  comparator: OrderComparator = "default"
): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, comparator)
    : (a, b) => -descendingComparator(a, b, orderBy, comparator);
};

export const stableSort = <T extends { id: string } | never>(
  array: T[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comparator?: (a: any, b: any) => number
) => {
  if (!comparator) return array;

  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const filterData = <T, U extends string>(
  data: T[],
  filters: FilterType<U>,
  searchText = "",
  disabledIds: string[] = [],
  format: (((value: unknown, data: T) => string | JSX.Element) | undefined)[],
  customFilter: (((value: unknown, data: T) => boolean) | undefined)[]
): T[] => {
  return data.filter((row) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (disabledIds.includes(String((row as any).id))) {
      return false;
    }

    if (searchText !== "") {
      const lowerSearchText = searchText.toLowerCase();
      if (
        !Object.values(row as never).some((value) =>
          String(value).toLowerCase().includes(lowerSearchText)
        )
      ) {
        return false;
      }
    }

    return Object.entries(filters).every(([key, filterValue], idx) => {
      if (filterValue === null || filterValue === "") {
        return true;
      }

      const customFilterFunction = customFilter[idx];
      if (customFilterFunction) {
        return customFilterFunction(filterValue, row);
      }

      const rowValue = row[key as keyof typeof row];

      const rowValueFormatted = format[idx]?.(rowValue, row) ?? rowValue ?? "";

      if (typeof rowValueFormatted !== "string") {
        const rowValueString = String(
          (rowValueFormatted as JSX.Element).props.children
        ).toLowerCase();

        if (Array.isArray(filterValue)) {
          return (
            filterValue.length === 0 ||
            filterValue.map((x) => String(x).toLowerCase()).includes(rowValueString)
          );
        }

        return rowValueString.includes(String(filterValue).toLowerCase());
      }

      if (Array.isArray(filterValue)) {
        return filterValue.length === 0 || filterValue.includes(String(rowValueFormatted));
      }

      return String(rowValueFormatted).toLowerCase().includes(String(filterValue).toLowerCase());
    });
  });
};
