import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import BillingCommons from "../billing/BillingCommons";
import InvoiceDeliveryMethodLabel from "./InvoiceDeliveryMethodLabel";
import { useFeatureFlags } from "../../commons/Features";
import DealsheetValueIcon from "./DealsheetValueIcon";
import TimecardRowInformation from "components/shared/components/TimecardRowInformation";
import { AcknowledgeRateChanges } from "./AcknowledgeRateChanges";
import { Box } from "@mui/system";

const TimecardHoursReadonly = (props) => {
  const { entries, adjustments, missedLunchHours, isDigitalTimecardEntry } = props;
  const { isAdjustmentsOn } = useFeatureFlags();
  if (!entries) return "";

  const labelProps = {
    value: props.invoiceDelivery,
    disabled: false,
    readOnly: true,
  };

  const shouldHighlight = (row) => {
    if (row.hours === 0) return false;

    switch (row.name) {
      case "FlatIncentiveBonus":
      case "IncentiveBonus":
        return row.rate !== 0;
      case "Holiday":
        return props.isExceptionState;
    }

    return false;
  };

  return (
    <>
      <Paper className="timecard-readonly-paper">
        <Typography
          sx={{
            padding: "10px",
          }}>
          Timecard
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mb={2} mx={2}>
          {props.hasToAcknowledgeRateChange ? (
            <AcknowledgeRateChanges
              timesheetId={props.timesheetId}
              onRateChangesAcknowledged={props.onRateChangesAcknowledged}
            />
          ) : (
            <Box />
          )}
          <div style={{ textAlign: "right", paddingBottom: "10px" }}>
            <InvoiceDeliveryMethodLabel {...labelProps} />
          </div>
        </Box>

        <Table
          sx={{
            minWidth: 650,
          }}
          size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">Rate</TableCell>
              <TableCell align="right">Hours</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((row) => {
              const shouldDisplayDTHours =
                isDigitalTimecardEntry &&
                (row.name === "RegularHours" ||
                  row.name === "OnCall" ||
                  row.name === "CallBack" ||
                  row.name === "Overtime8" ||
                  row.name === "Overtime40" ||
                  row.name === "DoubleTime" ||
                  row.name === "Holiday" ||
                  row.name === "PreceptCharge") &&
                row.originalHours !== null &&
                row?.originalHours !== row.hours;

              return (
                <Fragment key={`fragment_${row.name}`}>
                  <TableRow
                    key={row.name}
                    className={shouldHighlight(row) ? "timecard-expense-highlight" : ""}>
                    <TableCell component="th" scope="row">
                      {row.title}
                      {missedLunchHours > 0 && row.name == "RegularHours" && (
                        <TimecardRowInformation
                          informationText="Missed Lunch Hours:"
                          informationValue={`${missedLunchHours}`}
                        />
                      )}
                      {shouldDisplayDTHours && (
                        <TimecardRowInformation
                          informationText="Digital Timecard original hours:"
                          informationValue={row?.originalHours}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={"$"}
                        value={row.rate}
                        thousandSeparator={true}
                        displayType={"text"}
                      />
                      <DealsheetValueIcon fieldName={row.name} arrayName="timecardDealsheetTypes" />
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        value={row.hours}
                        thousandSeparator={true}
                        displayType={"text"}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        fixedDecimalScale={2}
                        decimalScale={2}
                        prefix={"$"}
                        value={row.hours * row.rate}
                        thousandSeparator={true}
                        displayType={"text"}
                      />
                    </TableCell>
                  </TableRow>
                  {isAdjustmentsOn &&
                    adjustments
                      .filter((a) => a.name == row.name)
                      .map((a, index) => (
                        <TableRow
                          key={`adjustment_${a.name}_${index}`}
                          className={
                            (a.name == "FlatIncentiveBonus" || a.name == "IncentiveBonus") &&
                            a.rate != 0
                              ? "timecard-expense-highlight"
                              : ""
                          }>
                          <TableCell component="th" scope="row" style={{ paddingLeft: 30 }}>
                            {a.title}
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={"$"}
                              value={a.rate}
                              thousandSeparator={true}
                              displayType={"text"}
                            />
                            <DealsheetValueIcon
                              fieldName={a.name}
                              arrayName="timecardDealsheetTypes"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              value={a.hours}
                              thousandSeparator={true}
                              displayType={"text"}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={"$"}
                              value={a.hours * a.rate}
                              thousandSeparator={true}
                              displayType={"text"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </Fragment>
              );
            })}
            <TableRow>
              <TableCell align="right" colSpan={3} style={{ height: "50px", fontWeight: "bold" }}>
                Total
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"$ "}
                  value={
                    isAdjustmentsOn
                      ? BillingCommons.getInvoiceSubTotalForPayroll(entries, adjustments)
                      : BillingCommons.getInvoiceSubTotalForPayroll(entries)
                  }
                  thousandSeparator={true}
                  displayType={"text"}
                  style={{ fontWeight: "bold" }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

TimecardHoursReadonly.propTypes = {
  timesheetId: PropTypes.string,
  entries: PropTypes.array,
  adjustments: PropTypes.array,
  invoiceDelivery: PropTypes.string,
  missedLunchHours: PropTypes.number,
  isDigitalTimecardEntry: PropTypes.bool,
  isExceptionState: PropTypes.bool,
  hasToAcknowledgeRateChange: PropTypes.bool,
  onRateChangesAcknowledged: PropTypes.func,
};

export default TimecardHoursReadonly;
