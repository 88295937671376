import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;

export default {
  GetActivePayPeriod() {
    const url = `${baseUrl}/timesheet-periods/active`;
    return msalApiFetch(url, {
      timeout: 600000,
      method: "GET",
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
  SwitchPayPeriodPayPeriod() {
    const url = `${baseUrl}/timesheet-periods`;
    return msalApiFetch(url, {
      timeout: 600000,
      method: "POST",
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
};
