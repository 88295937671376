import { useSelector } from "react-redux";
import { AppSettings } from "redux/reducers/appSettingsReducer";

function useAppSettings(): AppSettings {
  const appSettings = useSelector((state: RootState) => state.appSettings);

  return appSettings;
}

export default useAppSettings;
