import React, { useState } from "react";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import TimesheetApi from "api/TimesheetApi";
import PendingSignatureDialog from "./PendingSignatureDialog";
import ReassignManagerDialog from "./ReassignManagerDialog";
import { SignatureStatus } from "commons/Enums";
import { usePermissions } from "hooks";

type SignatureIconProps = {
  timesheetId: string;
  facilityId: string;
  refreshData: (...args: any[]) => void;
  signatureStatus: SignatureStatus;
  hassEditRequest?: boolean;
};

const SignatureIcon = ({
  timesheetId,
  hassEditRequest: isEditRequest,
  refreshData,
  facilityId,
  signatureStatus,
}: SignatureIconProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [reassignManagerDialogOpen, setReassignManagerDialogOpen] = useState<boolean>(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [completeLoading, setCompleteLoading] = useState<boolean>(false);
  const { canRequestSignature } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const toolTipText = `Pending manager signature ${
    canRequestSignature && SignatureStatus.PendingSignature ? ", click for options" : ""
  }`;

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const openManagerDialog = () => {
    setDialogOpen(false);
    setReassignManagerDialogOpen(true);
  };

  const closeManagerDialog = () => {
    setReassignManagerDialogOpen(false);
  };

  const onResend = () => {
    setResendLoading(true);
    TimesheetApi.notifyManager(timesheetId)
      .then(async (response) => {
        if (!response.ok) throw response;
        enqueueSnackbar("Manager has been notified successfully.");
        toggleDialog();
      })
      .catch(() => {
        enqueueSnackbar("An error occurred while notified manager.");
      })
      .finally(() => {
        setResendLoading(false);
      });
  };

  const onComplete = () => {
    setCompleteLoading(true);
    TimesheetApi.completeManagerRequest(timesheetId)
      .then(async (response) => {
        if (!response.ok) throw response;
        enqueueSnackbar("Manager signature request completed successfully.");
        toggleDialog();
        refreshData();
      })
      .catch(() => {
        enqueueSnackbar("An error occurred while completing manager signature request.");
      })
      .finally(() => {
        setCompleteLoading(false);
      });
  };

  let buttonBackgroundColor;

  if (isEditRequest) {
    buttonBackgroundColor = "";
  } else {
    switch (signatureStatus) {
      case SignatureStatus.Declined:
        buttonBackgroundColor = "signatureDeclined";
        break;
      case SignatureStatus.Signed:
      case SignatureStatus.MarkAsCompletedBpp:
        buttonBackgroundColor = "timecardSigned";
        break;
      case SignatureStatus.PendingSignature:
        buttonBackgroundColor = "spreadsheet";
        break;
      default:
        buttonBackgroundColor = "";
    }
  }

  const buttonColor =
    !!isEditRequest || signatureStatus === SignatureStatus.NotRequired || !signatureStatus
      ? ""
      : "white";

  return (
    <>
      <IconButton
        sx={{
          padding: "0!important",
          margin: "0",
        }}
        disabled={isEditRequest || signatureStatus !== SignatureStatus.PendingSignature}
        onClick={
          !isEditRequest &&
          canRequestSignature &&
          signatureStatus === SignatureStatus.PendingSignature
            ? toggleDialog
            : undefined
        }
        data-testid="pending-manager-signature-icon">
        <Tooltip title={toolTipText} placement="top">
          <Avatar
            className={`file-attached-indicator ${buttonBackgroundColor}`}>
            <i className={`material-icons-outlined`} style={{ color: buttonColor }}>
              gesture
            </i>
          </Avatar>
        </Tooltip>
      </IconButton>
      <PendingSignatureDialog
        open={dialogOpen}
        onClose={toggleDialog}
        timesheetId={timesheetId}
        onResend={onResend}
        onComplete={onComplete}
        onReassignManager={openManagerDialog}
        resendLoading={resendLoading}
        completeLoading={completeLoading}
      />
      <ReassignManagerDialog
        open={reassignManagerDialogOpen}
        onClose={closeManagerDialog}
        timesheetId={timesheetId}
        facilityId={facilityId}
      />
    </>
  );
};

export default SignatureIcon;
