import React from "react";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";

type SearchBarProps = {
  disabled: boolean;
  onCancelSearch: () => void;
  onChange: (_: unknown) => void;
  onRequestSearch: (_: unknown) => void;
  placeholder: string;
  value: string;
};

const SearchBar = ({
  disabled,
  onCancelSearch,
  onRequestSearch,
  placeholder,
  ...inputProps
}: Partial<SearchBarProps>) => {
  const [value, setValue] = React.useState(inputProps.value ?? "");

  React.useEffect(() => {
    setValue(inputProps.value ?? "");
  }, [inputProps.value]);

  const handleInput = React.useCallback(
    (e) => {
      setValue(e.target.value);
      if (inputProps.onChange) {
        inputProps.onChange(e.target.value);
      }
    },
    [inputProps.onChange]
  );

  const handleCancel = React.useCallback(() => {
    setValue("");
    if (onCancelSearch) {
      onCancelSearch();
    }
  }, [onCancelSearch]);

  const handleRequestSearch = React.useCallback(() => {
    if (onRequestSearch) {
      onRequestSearch(value);
    }
  }, [onRequestSearch, value]);

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}>
      <TextField
        type="text"
        size="small"
        variant='outlined'
        value={value}
        onChange={handleInput}
        fullWidth
        disabled={disabled}
        sx={{ border: "none", width: "100%" }}
        placeholder={placeholder}
        InputProps={{
          endAdornment: !value ? (
            <IconButton onClick={handleRequestSearch} disabled={disabled} size="small">
              <SearchIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleCancel} disabled={disabled} size="small">
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Paper>
  );
};

export default SearchBar;
