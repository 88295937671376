import { LastAdfRun } from "types/payPeriod/lastRunAdf";
import { msalApiFetch } from "../commons/authConfig";
import { AdfRunData } from "types/adf/adfRunData";

const baseUrl = process.env.REACT_APP_API_URL;

export default {
  GetLastAdfRun(): Promise<LastAdfRun> {
    const url = `${baseUrl}/adf/get-last-snowflake-run`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },

  StartAdfRun() {
    const url = `${baseUrl}/adf/start-snowflake-run`;
    return msalApiFetch(url, {
      method: "POST",
    }).then((data) => {
      if (!data.ok) throw data;
      return data.text();
    });
  },

  getAdfRunLogs(runId: string): Promise<{
    runDateTime: string | null;
    data: AdfRunData[] | null;
  }> {
    const url = `${baseUrl}/adf-runs/${runId}`;
    return msalApiFetch(url, {
      method: "GET",
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
};
