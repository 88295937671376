import { UpdateFacility } from "types/facility/updateFacility";
import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "facilities";
const jsonContentType = "application/json";

async function getFacilities() {
  const url = `${baseUrl}/${endpoint}`;
  return msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  }).then((data) => {
    if (!data.ok) throw data;
    return data.json();
  });
}

function getFacilityById(facilityId: string) {
  const url = `${baseUrl}/${endpoint}/${facilityId}`;
  return msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  }).then((data) => {
    if (!data.ok) throw data;
    return data.json();
  });
}

async function updateFacility(facilityId: string, facility: UpdateFacility) {
  const url = `${baseUrl}/${endpoint}/${facilityId}`;
  const res = await msalApiFetch(url, {
    method: "PATCH",
    headers: { "Content-Type": jsonContentType },
    body: JSON.stringify(facility),
  });
  if (res.ok) {
    return res.json();
  }
  throw res.statusText;
}

async function getDocuments(): Promise<{ id: string; name: string }[]> {
  const url = `${baseUrl}/documentType`;
  const res = await msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  });
  if (!res.ok) throw res;
  return res.json();
}

async function updateDocuments(
  documents: { id: string | null; name: string }[]
): Promise<{ documents: { id: string; name: string }[]; validationError: string[] }> {
  const url = `${baseUrl}/documentType/update`;
  const res = await msalApiFetch(url, {
    method: "POST",
    headers: { "Content-Type": jsonContentType },
    body: JSON.stringify(documents),
  });
  if (!res.ok) throw res;
  return res.json();
}

async function validateUpdateDocuments(
  documents: { id: string | null; name: string }[]
): Promise<{ isValid: boolean; errors: string[] }> {
  const url = `${baseUrl}/documentType/validateUpdate`;
  const res = await msalApiFetch(url, {
    method: "POST",
    headers: { "Content-Type": jsonContentType },
    body: JSON.stringify(documents),
  });
  if (!res.ok) throw res;
  return res.json();
}

function downloadTimesheets(facilityId: string, startDate: string, endDate: string) {
  const url = `${baseUrl}/timesheet/facility/${facilityId}/downloadTimecard?startDate=${startDate}&endDate=${endDate}`;
  return msalApiFetch(url, {
    method: "GET",
  }).then((response) => {
    if (!response.ok) throw response;
    return response.blob();
  });
}

export {
  getFacilities,
  getFacilityById,
  updateFacility,
  updateDocuments,
  downloadTimesheets,
  validateUpdateDocuments,
  getDocuments,
};
