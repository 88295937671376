import React, { useState, useEffect } from "react";
import { getFacilities as getAllFacilities } from "../../api/FacilitiesApi";
import moment from "moment";
import { Button, CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { Link } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import { FacilityTable } from "types/facility/facilityTable";
import { AdditionalDocumentsManageDialog } from "./additionalDocuments/AdditionalDocumentsManageDialog";
import { Box } from "@mui/system";
import BPPTable, { Column } from "components/shared/components/bppTable/BPPTable";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

type FacilityTableIds =
  | "id"
  | "name"
  | "timecardForwarding"
  | "email"
  | "timecardDueDate"
  | "lateForwarding"
  | "additionalDocuments"
  | "managerSignature"
  | "workWeekStart"
  | "documentType";

const Facilities = () => {
  const [facilities, setFacilities] = useState<FacilityTable[]>([]);
  const [recentlyAddedFacilities, setRecentlyAddedFacilities] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [nameColumn, setNameColumn] = useState<Column<FacilityTableIds, FacilityTable>>({
    label: "Facility Name",
    id: "name",
  });
  const { canSeeFacilitiesColumnLink, canSeeFacilitiesRecentlyAddedToggle } = usePermissions();
  const dispatch = useDispatch();
  const [manageDocumentDialogOpen, setManageDocumentDialogOpen] = useState(false);

  useEffect(() => {
    getFacilities();
    setColumnLink();
  }, []);

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Facilities" });
  }, []);

  const handleToggleRecentlyAddedFacilities = () => {
    const newRecentlyAddedFacilities = !recentlyAddedFacilities;
    setRecentlyAddedFacilities(newRecentlyAddedFacilities);
  };

  const getFacilities = async () => {
    try {
      const facilities = await getAllFacilities();
      setFacilities(setDueDate(facilities));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  function setColumnLink() {
    if (canSeeFacilitiesColumnLink) {
      setNameColumn({
        ...nameColumn,
        format: (value, data) => <Link to={"/facilities/" + data.id}>{data.name} </Link>,
      });
    }
  }

  function setDueDate(facilities: FacilityTable[]) {
    return facilities.map((facility) => ({
      ...facility,
      lateForwarding: facility.lateForwarding ?? false,
      timecardForwarding: facility.timecardForwarding ?? false,
      timecardRequiresManagerSignature: facility.timecardRequiresManagerSignature ?? false,
      additionalDocumentsRequired: facility.additionalDocumentsRequired ?? false,
      timecardDueDateUTC: extractDueDate(facility.timecardDueDateUTC),
    }));
  }

  function extractDueDate(timecardDueDateUTC: string | null) {
    if (timecardDueDateUTC) {
      const localTime = moment.utc(timecardDueDateUTC).local().format("MM/DD/YY h:mm:ss a");
      const day = moment(localTime).format("dddd");
      const time = moment(localTime).format("h:mm a");
      return `${day}s at ${time}`;
    }
    return "";
  }

  const filteredFacilities = recentlyAddedFacilities
    ? facilities.filter((facility) =>
        moment(facility.dateCreatedUtc).isAfter(moment().subtract(2, "week"))
      )
    : facilities;

  function getColumns(): Column<FacilityTableIds, FacilityTable>[] {
    return [
      nameColumn,
      {
        id: "timecardForwarding",
        label: "Timecard Forwarding",
        lookup: {
          Yes: "Yes",
          No: "No",
        },
        format: (value) => (value ? "Yes" : "No"),
        width: "5%",
      },
      { label: "Email", id: "email", width: "15%" },
      {
        label: "Timecard Due Date",
        id: "timecardDueDate",
        format: (value, data) => data.timecardDueDateUTC ?? "",
        width: "15%",
      },
      {
        label: "Late Forwarding",
        id: "lateForwarding",
        lookup: {
          Yes: "Yes",
          No: "No",
        },
        format: (value, data) => (data.lateForwarding ? "Yes" : "No"),
        width: "5%",
      },
      {
        id: "documentType",
        label: "Additional Documents",
        width: "15%",
      },
      {
        id: "managerSignature",
        label: "Manager Signature",
        lookup: {
          Yes: "Yes",
          No: "No",
        },
        format: (value, data) => (data.timecardRequiresManagerSignature ? "Yes" : "No"),
        width: "5%",
      },
      {
        id: "workWeekStart",
        label: "Work Week Start",
        lookup: {
          Sunday: "Sunday",
          Monday: "Monday",
          Tuesday: "Tuesday",
          Wednesday: "Wednesday",
          Thursday: "Thursday",
          Friday: "Friday",
          Saturday: "Saturday",
        },
        format: (value, data) => {
          switch (data.workWeekStart) {
            case 0:
              return "Sunday";
            case 1:
              return "Monday";
            case 2:
              return "Tuesday";
            case 3:
              return "Wednesday";
            case 4:
              return "Thursday";
            case 5:
              return "Friday";
            case 6:
              return "Saturday";
            default:
              return "Sunday";
          }
        },
        width: "6%",
      },
    ];
  }

  function handleOnCloseManageDocumentDialog() {
    setManageDocumentDialogOpen(false);
    getFacilities();
  }

  return (
    <div className="view-container">
      <>
        <AdditionalDocumentsManageDialog
          isOpen={manageDocumentDialogOpen}
          handleClose={() => handleOnCloseManageDocumentDialog()}
        />
        <BPPTable<FacilityTable, FacilityTableIds>
          data={filteredFacilities}
          columns={getColumns()}
          loading={isLoading}
          customSearch={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 3,
              }}>
              <CanRenderChildren permissionName="canManageFacilitiesDocuments">
                <Button
                  variant="contained"
                  onClick={() => setManageDocumentDialogOpen(true)}
                  sx={{ mr: 2 }}>
                  Manage Documents
                </Button>
              </CanRenderChildren>
              <CanRenderChildren permissionName="canSeeFacilitiesRecentlyAddedToggle">
                <FormControlLabel
                  control={
                    <Switch
                      checked={recentlyAddedFacilities}
                      onChange={handleToggleRecentlyAddedFacilities}
                      name="recentlyAddedFacilities"
                      color="primary"
                    />
                  }
                  label="Recently Added Facilities"
                />
              </CanRenderChildren>
            </Box>
          }
        />
      </>
    </div>
  );
};

export default Facilities;
