import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import "./InvoiceDeliveryMethodLabel.css";

const InvoiceDeliveryMethodLabel = (props) => {
  const minWidth = "200px";

  function calculatePixels(word) {
    if (word) {
      return `${word.length * 8}px`;
    }
    return minWidth;
  }

  const divStyle = {
    width: calculatePixels(props.value),
    minWidth,
  };

  const [invoiceValue, setInvoiceValue] = useState(props.value);
  const [style, setStyle] = useState(divStyle);

  useEffect(() => {
    setInvoiceValue(props.value);
  }, [props.value]);

  function handleChange(event) {
    const letters = event.target.value;
    setInvoiceValue(letters);
    divStyle.width = calculatePixels(letters);
    setStyle(divStyle);

    if (!props.disabled) {
      props.handleTextFieldChange("InvoiceDeliveryMethod", event);
    }
  }
  return (
    <div className="idm-container">
      <div style={style}>
        <TextField
          onChange={handleChange}
          disabled={props.disabled}
          id="invoiceDeliveryMethod"
          label="Invoice Delivery Method"
          placeholder="Enter IDM"
          fullWidth
          InputProps={{
            readOnly: props.readOnly,
          }}
          value={invoiceValue || ""}
          InputLabelProps={{
            shrink: true,
          }}
          size={props.size}
        />
      </div>
    </div>
  );
};

export default InvoiceDeliveryMethodLabel;
