import { NotesUserType } from "commons/Enums";
import { useEffect, useState } from "react";
import { Note } from "types/notes";

type GroupedNotesResult = {
  allNotes: Note[][];
  systemNotes: Note[][];
  internalNotes: Note[][];
  travelerNotes: Note[][];
};

// Helper function to combine consecutive groups with the same name
const combineConsecutiveGroups = (groups: Note[][]): Note[][] => {
  const combinedGroups: Note[][] = [];

  for (const group of groups) {
    if (combinedGroups.length === 0) {
      combinedGroups.push(group);
    } else {
      const previousGroup = combinedGroups[combinedGroups.length - 1];
      const isConsecutive =
        previousGroup[previousGroup.length - 1].createdByFirstName ===
          group[0].createdByFirstName &&
        previousGroup[previousGroup.length - 1].createdByLastName === group[0].createdByLastName;

      if (isConsecutive) {
        previousGroup.push(...group);
      } else {
        combinedGroups.push(group);
      }
    }
  }

  return combinedGroups;
};

const sortAllNotes = (notes: Note[]): Note[][] => {
  // Sort notes by createdDate in descending order
  const sortedNotes = [...notes].sort(
    (a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime()
  );

  // Helper variables to track the current group
  let currentGroup: Note[] = [];
  let currentKey: string | null = null;
  let currentUserType: string | null = null;

  const newAllNotes: Note[][] = [];

  // Group notes by createdByFirstName and createdByLastName
  for (const note of sortedNotes) {
    const key = `${note.createdByFirstName} ${note.createdByLastName}`;
    const userType = note.userType;

    if (currentKey !== null && (currentKey !== key || currentUserType !== userType)) {
      newAllNotes.push(currentGroup);
      currentGroup = [];
    }

    currentGroup.push(note);
    currentKey = key;
    currentUserType = userType;
  }

  if (currentGroup.length > 0) {
    newAllNotes.push(currentGroup);
  }
  return newAllNotes;
};

const useGroupedNotes = (notes: Note[]): GroupedNotesResult => {
  const [allNotes, setAllNotes] = useState<Note[][]>([]);
  const [systemNotes, setSystemNotes] = useState<Note[][]>([]);
  const [internalNotes, setInternalNotes] = useState<Note[][]>([]);
  const [travelerNotes, setTravelerNotes] = useState<Note[][]>([]);

  useEffect(() => {
    if(notes.length !== 0 && allNotes.length === 0){
      const sorttedNotes = sortAllNotes(notes);
      setAllNotes(sorttedNotes);
  
      // Separate the notes into respective lists based on userType
      setSystemNotes(
        combineConsecutiveGroups(
          sortAllNotes(notes).filter((group) => group[0].userType === NotesUserType.System)
        )
      );
      setInternalNotes(
        combineConsecutiveGroups(
          sortAllNotes(notes).filter((group) => group[0].userType === NotesUserType.Internal)
        )
      );
      setTravelerNotes(
        combineConsecutiveGroups(
          sortAllNotes(notes).filter((group) => group[0].userType === NotesUserType.Traveler)
        )
      );
    }
  }, [notes]);

  return { allNotes, systemNotes, internalNotes, travelerNotes };
};

export default useGroupedNotes;
