import React, { useState } from "react";
import Textarea from "react-textarea-autosize";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ApproveIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import { useSnackbar } from "notistack";
import { AddJobAssignmentNote } from "api/NotesApi";
import { parseErrorResponse } from "commons/utils";

type NewNoteInputProps = {
  jobAssignmentId: string;
  noteSaved: () => void;
};

const ERROR_SAVING_NOTE = "An error occurred while saving the assignment note.";

const SAVING_NOTE_SUCCESS = "The assignment note was saved.";

export const NewNoteInput = ({ jobAssignmentId, noteSaved }: NewNoteInputProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [savingNote, setSavingNote] = useState(false);

  const [text, setText] = useState("");

  const handleSave = async () => {
    setSavingNote(true);

    try {
      await AddJobAssignmentNote(jobAssignmentId, text);

      enqueueSnackbar(SAVING_NOTE_SUCCESS, { variant: "success" });
      setText("");
      noteSaved();
    } catch (error: any) {
      const errorMessage = await parseErrorResponse(error, ERROR_SAVING_NOTE);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setSavingNote(false);
    }
  };

  return (
    <div className="note-section-textarea-container" style={{ width: "100%", resize: "none" }}>
      <Box>
        <Textarea
          value={text}
          style={{ width: "100%",  resize: "none" }}
          onChange={(e) => setText(e.target.value)}
          minRows={4}
          maxRows={8}
          placeholder="Job Assignment Note"
          maxLength={250}
        />
      </Box>

      <Box>
        <LoadingButton
          disabled={!text}
          onClick={handleSave}
          loading={savingNote}
          variant="contained"
          size="small"
          className={"timecard-detail-bottombar-approve-button"}>
          <ApproveIcon className={"timecard-detail-bottombar-icon"} />
          Save
        </LoadingButton>
      </Box>
    </div>
  );
};
