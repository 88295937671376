import React, { ReactElement, isValidElement } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton } from "@mui/material";

type ConfirmDialogProps = {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  content?: string | ReactElement;
  dialogTitle: string;
  confirmText?: string;
  cancelText?: string;
  onCancel?: () => void;
  isLoading?: boolean;
  displayCancelButton?: boolean;
  displayConfirmButton?: boolean;
  displayCloseButton?: boolean;
  maxWidth?: "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  fullWidth?: boolean;
  centerTitle?: boolean;
  dividerEnabled?: boolean;
};

const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  content = "Are you sure?",
  dialogTitle,
  confirmText = "Yes",
  cancelText = "No",
  onCancel,
  isLoading = false,
  displayCancelButton = true,
  displayConfirmButton = true,
  maxWidth = "sm",
  fullWidth = false,
  centerTitle = false,
  dividerEnabled = false,
  displayCloseButton = false,
}: ConfirmDialogProps): JSX.Element => {
  const handleClose = (): void => {
    onClose?.();
  };

  const handleCancel = (): void => {
    onCancel ? onCancel() : handleClose();
  };

  const handleConfirm = (): void => {
    onConfirm?.();
    onClose?.();
  };

  const isContentReactElement = isValidElement(content);

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: centerTitle ? "center" : "inherit" }}>
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
          {displayCloseButton && (
            <IconButton
              onClick={handleCancel}
              color="primary"
              style={{ position: "absolute", right: 15, padding: 2.5 }}
              size="large">
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        {dividerEnabled && <Divider />}
        <DialogContent>
          {isLoading && <CircularProgress />}
          {!isLoading && isContentReactElement && content}
          {!isLoading && !isContentReactElement && (
            <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {displayCancelButton && (
            <Button onClick={handleCancel} color="primary" disabled={isLoading}>
              {cancelText}
            </Button>
          )}
          {displayConfirmButton && (
            <Button onClick={handleConfirm} color="primary" autoFocus disabled={isLoading}>
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
