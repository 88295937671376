import React from "react";
import { Avatar, Box, Chip, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type TimecardDetailTopbarCallOffsProps = {
  hospitalCallOffs: number;
  personalCallOffs: number;
  sickCallOffs: number;
  otherCallOffs: number;
  showInfo?: boolean;
};

const TimecardDetailTopbarCallOffs = ({
  hospitalCallOffs,
  otherCallOffs,
  personalCallOffs,
  sickCallOffs,
  showInfo = false,
}: TimecardDetailTopbarCallOffsProps) => {
  return (
    <Box my={1} display={"flex"} flexDirection={"row"} gap={1}>
      <Chip avatar={<Avatar>{hospitalCallOffs}</Avatar>} label="Hospital" size="small" />
      <Chip avatar={<Avatar>{personalCallOffs}</Avatar>} label="Personal" size="small" />
      <Chip avatar={<Avatar>{sickCallOffs}</Avatar>} label="Sick" size="small" />
      <Chip avatar={<Avatar>{otherCallOffs}</Avatar>} label="Other" size="small" />
      {showInfo && (
        <Tooltip title="Call off totals will update when approved" placement="top" sx={{ m: 0 }}>
          <InfoOutlinedIcon />
        </Tooltip>
      )}
    </Box>
  );
};

export default TimecardDetailTopbarCallOffs;
