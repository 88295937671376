import React, { useEffect, useState } from "react";
import MaterialTable, { Column, Filter } from "material-table";
import { CircularProgress, Button } from "@mui/material";
import { Link } from "react-router-dom";
import LoggedUser from "commons/LoggedUser";
import JobAssignmentApi from "api/JobAssignmentApi";
import PayPeriodSelector from "components/shared/components/PayPeriodSelector";
import FileFordwardedIcon from "components/shared/components/FileFordwardedIcon";
import { getWeekDays } from "components/shared/functions/PayPeriodDateHelper";
import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import TimeCardAdjusmentsIcon from "components/shared/components/TimeCardAdjusmentsIcon";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { useFeatureFlags } from "commons/Features";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import SwitchPayPeriodApi from "api/SwitchPayPeriodApi";
import TimecardFileIcon from "components/shared/components/TimecardFileIcon";
import TimecardIsLateIcon from "components/shared/components/TimecardIsLateIcon";
import SignatureIcon from "components/shared/components/SignatureIcon";
import { TimesheetStatus, SignatureStatus, TimesheetSubStatusMap } from "commons/Enums";
import { HistoryTableState, JobAssignmentHistory } from "types/history/History";
import { useAppDispatch, useAppSelector } from "hooks";
import FacilityReportAttachedIcon from "components/shared/components/FacilityReportAttachedIcon";
import { downloadBlob } from "commons/utils";

const getExportableStatus = (rowStatus: string, rowSubStatus = 0) => {
  if (rowStatus === TimesheetStatus.NOTIMECARD) {
    return TimesheetSubStatusMap[rowSubStatus as never] as string;
  }
  return rowStatus;
};

const HistoryTable = () => {
  const [jobAssignments, setJobAssignments] = useState<JobAssignmentHistory[]>();
  const [selectedDays, setSelectedDays] = useState<Date[]>();
  const [lookUpValuesTimeCardUpload, setlookUpValuesTimeCardUpload] = useState({ null: "No" });
  const [lookUpValuesfacilityReportUpload, setlookUpValuesfacilityReportUpload] = useState({
    null: "No",
  });
  const { isAdjustmentsOn } = useFeatureFlags();

  const history = useAppSelector((state: RootState) => state.history);

  const dispatch = useAppDispatch();

  const loadJobAssignments = () => {
    if (history.selectedDays) {
      setJobAssignmentsFromSelectedDays(history.selectedDays);
    } else {
      SwitchPayPeriodApi.GetActivePayPeriod()
        .then((response) => {
          setJobAssignmentsAndPayPeriod(
            moment(response.periodStartDate).format("MM/DD/YYYY"),
            moment(response.periodEndDate).format("MM/DD/YYYY")
          );
        })
        .catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "History" });
    loadJobAssignments();
  }, []);

  function setJobAssignmentsFromSelectedDays(selectedDays: Date[]) {
    setJobAssignmentsAndPayPeriod(
      moment(selectedDays[0]).format("MM/DD/YYYY"),
      moment(selectedDays[6]).format("MM/DD/YYYY")
    );
  }

  const handleHistoryChange = (history: HistoryTableState) => {
    dispatch({ type: "HISTORY_TABLE_CHANGED", history });
  };

  const handleSearchChange = (newText: string) => {
    handleHistoryChange({ ...history, searchText: newText });
  };

  const handleFilterChange = (newFilters: Filter<JobAssignmentHistory>[]) => {
    handleHistoryChange({ ...history, filters: newFilters });
  };

  const handleOrderChange = (orderBy: number, orderDirection: "desc" | "asc") => {
    handleHistoryChange({ ...history, orderBy, orderDirection });
  };

  function setJobAssignmentsAndPayPeriod(start: string, end: string) {
    setJobAssignments(undefined);
    const isRecruiterTeamLead = LoggedUser.isRecruiterTeamLead();
    const isAssistant = LoggedUser.isAssistant();
    const isRecruiter = LoggedUser.isRecruiter();
    const isSalesRep = LoggedUser.isSalesRep();

    switch (true) {
      case isAssistant:
        JobAssignmentApi.getByAssistantTimecardPeriod(LoggedUser.getOid(), start, end)
          .then((jobAssignments) => {
            setLookupValueToExport(jobAssignments);
            setJobAssignments(jobAssignments);
          })
          .catch((e) => console.error(e));
        break;
      case isRecruiterTeamLead:
        JobAssignmentApi.getByTeamLeadTimecardPeriod(LoggedUser.getOid(), start, end)
          .then((jobAssignments) => {
            setLookupValueToExport(jobAssignments);
            setJobAssignments(jobAssignments);
          })
          .catch((e) => console.error(e));
        break;

      case isRecruiter:
        JobAssignmentApi.getHistoryByRecruiterTimecardPeriod(LoggedUser.getOid(), start, end)
          .then((jobAssignments) => {
            setLookupValueToExport(jobAssignments);
            setJobAssignments(jobAssignments);
          })
          .catch((e) => console.error(e));
        break;

      case isSalesRep:
        JobAssignmentApi.getHistoryBySalesRepTimecardPeriod(LoggedUser.getOid(), start, end)
          .then((jobAssignments) => {
            setLookupValueToExport(jobAssignments);
            setJobAssignments(jobAssignments);
          })
          .catch((e) => console.error(e));
        break;

      default:
        JobAssignmentApi.getByTimecardPeriod(start, end)
          .then((jobAssignments) => {
            setLookupValueToExport(jobAssignments);
            setJobAssignments(jobAssignments);
          })
          .catch((e) => console.error(e));
        break;
    }

    const weekDays = getWeekDays(start) as Date[];

    setSelectedDays(weekDays);

    return weekDays;
  }

  function setLookupValueToExport(jobAssignments: JobAssignmentHistory[]) {
    const timeCardsUpload = jobAssignments
      .filter((j: JobAssignmentHistory) => j.timecardUploadId)
      .map((x: JobAssignmentHistory) => ({
        [x.timecardUploadId!]: "Yes",
      }));
    setlookUpValuesTimeCardUpload(Object.assign({ null: "No" }, ...timeCardsUpload));

    const facilityReportUpload = jobAssignments
      .filter((j: JobAssignmentHistory) => j.facilityReportUploadId)
      .map((x: JobAssignmentHistory) => ({
        [x.facilityReportUploadId!]: "Yes",
      }));
    setlookUpValuesfacilityReportUpload(Object.assign({ null: "No" }, ...facilityReportUpload));
  }

  function updateJobAssignments(start: string, end: string) {
    const weekDays = setJobAssignmentsAndPayPeriod(start, end);
    handleHistoryChange({ ...history, selectedDays: weekDays });
  }

  const attachmentsSearch = (terms: string[], rowData: JobAssignmentHistory) => {
    return (
      terms.length === 0 ||
      (terms.includes("LateTimecard") && rowData.isLate) ||
      (terms.includes("PendingSignature") &&
        rowData.managerSignatureStatus === SignatureStatus.PendingSignature) ||
      (terms.includes("NotRequired") &&
        rowData.managerSignatureStatus === SignatureStatus.NotRequired) ||
      (terms.includes("Signed") && rowData.managerSignatureStatus === SignatureStatus.Signed) ||
      (terms.includes("Declined") && rowData.managerSignatureStatus === SignatureStatus.Declined) ||
      (terms.includes("MarkAsCompletedBpp") &&
        rowData.managerSignatureStatus === SignatureStatus.MarkAsCompletedBpp) ||
      (terms.includes("HasEditRequest") && rowData.hasEditRequest) ||
      terms.includes(rowData.forwardingStatus ?? "")
    );
  };

  const getDefaultFilter = (fieldName: string) => {
    return history.filters?.find((x) => x.column.field === fieldName)?.value ?? "";
  };

  function loadColumns() {
    const columns: Column<JobAssignmentHistory>[] = [
      {
        title: "First Name",
        field: "candidateFirstName",
        defaultFilter: getDefaultFilter("candidateFirstName"),
        render: (row: JobAssignmentHistory) => (
          <Link
            to={
              "/home/" +
              row.recruiterId +
              "/" +
              row.id +
              "/" +
              moment(selectedDays?.[0]).format("MM/DD/YYYY") +
              "/" +
              moment(selectedDays?.[6]).format("MM/DD/YYYY")
            }>
            {row.candidateFirstName}{" "}
          </Link>
        ),
      },
      {
        title: "Last Name",
        field: "candidateLastName",
        defaultFilter: getDefaultFilter("candidateLastName"),
        render: (row: JobAssignmentHistory) => (
          <Link
            to={
              "/home/" +
              row.recruiterId +
              "/" +
              row.id +
              "/" +
              moment(selectedDays?.[0]).format("MM/DD/YYYY") +
              "/" +
              moment(selectedDays?.[6]).format("MM/DD/YYYY")
            }>
            {row.candidateLastName}{" "}
          </Link>
        ),
      },
      {
        title: "Recruiter",
        field: "recruiterFullName",
        defaultFilter: getDefaultFilter("recruiterFullName"),
      },
      {
        title: "Sales Representative",
        field: "salesRepName",
        defaultFilter: getDefaultFilter("salesRepName"),
      },
      {
        title: "Facility",
        field: "clientName",
        defaultFilter: getDefaultFilter("clientName"),
      },
      {
        title: "Status",
        field: "status",
        lookup: {
          Draft: "Pending",
          ForReview: "Payroll",
          Billing: "Billing",
          Approved: "Approved",
          DidNotWork: "Did Not Work",
          DidNotSubmit: "Did Not Submit",
          Returned: "Returned",
          Rejected: "Rejected",
        },
        customFilterAndSearch: (terms: string[], rowData: JobAssignmentHistory) => {
          if (!terms?.length) return true;

          if (rowData?.status === TimesheetStatus.NOTIMECARD) {
            return terms.includes(
              (TimesheetSubStatusMap[rowData.subStatus as never] as string)?.replaceAll(" ", "")
            );
          }

          return !terms || terms?.includes(rowData?.status);
        },
        defaultFilter: getDefaultFilter("status"),
        render: (rowData: JobAssignmentHistory) => {
          switch (rowData.status) {
            case TimesheetStatus.DRAFT:
              return "Pending";
            case TimesheetStatus.FOR_REVIEW:
              return "Payroll";
            case TimesheetStatus.NOTIMECARD:
              return TimesheetSubStatusMap[rowData.subStatus as never] as string;
            default:
              return rowData.status;
          }
        },
      },
      {
        title: "Job ID",
        field: "laborEdgeJobId",
        defaultFilter: getDefaultFilter("laborEdgeJobId"),
      },
      {
        title: "Details",
        field: "forwardingStatus",
        export: false,
        lookup: {
          Disabled: "Disabled",
          Pending: "Pending",
          Late: "After Deadline",
          "On Time": "Before Deadline",
          LateTimecard: "Late Timecard",
          NotRequired: "No Signature Required",
          PendingSignature: "Pending Signature",
          Signed: "Signature Signed",
          Declined: "Signature Declined",
          MarkAsCompletedBpp: "Signature Completed",
          HasEditRequest: "Edit Requested",
        },
        defaultFilter: getDefaultFilter("forwardingStatus"),
        customFilterAndSearch: attachmentsSearch,
        render: (row: JobAssignmentHistory) => (
          <div className="history-table-icons-wrap">
            <FacilityReportAttachedIcon
              timesheetId={row.id}
              filled={!!row.facilityReportUploadId}
            />

            <SignatureIcon
              signatureStatus={row.managerSignatureStatus}
              timesheetId={row.id}
              hassEditRequest={row.hasEditRequest}
              refreshData={loadJobAssignments}
              facilityId={row.clientId}
            />

            <TimecardFileIcon
              timecardUploadId={row.timecardUploadId}
              uploadSource={row.timecardUploadSource}
            />

            <FileFordwardedIcon
              forwardingStatus={row.forwardingStatus ?? ""}
              icon="forward_to_inbox"
              timesheetId={row.id}
            />

            <TimecardIsLateIcon isLate={row.isLate} />

            <CanRenderChildren featureFlagName="isAdjustmentsOn">
              <TimeCardAdjusmentsIcon
                isAdjustment={row.isAdjustment}
                hasAdjustment={row.hasAdjustment}
              />
            </CanRenderChildren>
          </div>
        ),
      },
      {
        title: "Facility Report Upload",
        field: "facilityReportUploadId",
        hidden: true,
        export: true,
        lookup: lookUpValuesfacilityReportUpload,
      },
      {
        title: "Timecard Upload",
        field: "timecardUploadId",
        hidden: true,
        export: true,
        lookup: lookUpValuesTimeCardUpload,
      },
      {
        title: "Forwarding Status",
        field: "forwardingStatus",
        hidden: true,
        lookup: {
          Disabled: "Disabled",
          Pending: "Pending",
          Late: "After Deadline",
          "On Time": "Before Deadline",
          LateTimecard: "Late Timecard",
        },
        export: true,
      },
      {
        title: "Is Late",
        field: "isLate",
        hidden: true,
        lookup: { true: "Yes", false: "No" },
        export: true,
      },
      {
        title: "Is Adjustment",
        field: "isAdjustment",
        hidden: true,
        lookup: { true: "Yes", false: "No" },
        export: isAdjustmentsOn,
      },
      {
        title: "Has adjusments",
        field: "hasAdjustment",
        hidden: true,
        lookup: { true: "Yes", false: "No" },
        export: isAdjustmentsOn,
      },
    ];

    if (history.orderBy !== undefined) {
      const column = columns[history.orderBy];
      const filterOrder = { defaultSort: history.orderDirection };
      columns[history.orderBy] = { ...column, ...filterOrder };
    }

    return columns;
  }

  function handleRefreshHistoryState() {
    window.location.reload();
  }

  function canExport() {
    return LoggedUser.isAdmin() || LoggedUser.isSuperAdmin();
  }

  return (
    <div className="view-container">
      <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "10px" }}>
        {selectedDays && (
          <PayPeriodSelector
            selectedDays={selectedDays}
            onChange={updateJobAssignments}
            disabledCurrentPayPeriod={false}
            showCalendar={false}
          />
        )}
        {
          <>
            <Button onClick={handleRefreshHistoryState}>
              <RefreshIcon color="primary" />
              Reset filters
            </Button>
          </>
        }
      </div>
      {jobAssignments == null ? (
        <CircularProgress />
      ) : (
        <div style={{ display: "grid" }}>
          <MaterialTable
            style={{ overflow: "auto", fontSize: 14 }}
            onSearchChange={handleSearchChange}
            onFilterChange={handleFilterChange}
            onOrderChange={handleOrderChange}
            columns={loadColumns()}
            data={jobAssignments}
            options={{
              sorting: true,
              filtering: true,
              showTitle: false,
              maxBodyHeight: "70vh",
              pageSize: 20,
              searchText: history.searchText,
              exportButton: canExport() ? { csv: true, pdf: false } : false,
              exportCsv(columns, renderData) {
                // Turn headers into array of strings
                const headerRow = columns.map((col) => {
                  return col.title;
                });

                const data = renderData.map((row) => {
                  return {
                    "First Name": row.candidateFirstName,
                    "Last Name": row.candidateLastName,
                    Recruiter: row.recruiterFullName,
                    "Sales Representative": row.salesRepName,
                    Facility: row.clientName,
                    Status: getExportableStatus(row.status, row.subStatus),
                    "Job ID": row.laborEdgeJobId,
                    Details: row.forwardingStatus,
                    "Facility Report Upload":
                      lookUpValuesfacilityReportUpload[
                        (row.facilityReportUploadId as never) ?? ("null" as unknown)
                      ],
                    "Timecard Upload":
                      lookUpValuesTimeCardUpload[
                        (row.timecardUploadId as never) ?? ("null" as unknown)
                      ],
                    "Forwarding Status": row.forwardingStatus,
                    "Is Late": row.isLate,
                    "Is Adjustment": row.isAdjustment,
                    "Has adjusments": row.hasAdjustment,
                  };
                });

                const dataRows = data.map(({ ...row }) => Object.values(row));

                const csvContent = [headerRow, ...dataRows].map((e) => e.join(" ,")).join("\n");

                downloadBlob(new Blob([csvContent]), "historyExport.csv");
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HistoryTable;
