import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import AnalyticsApi from "api/AnalyticsApi";
import Widget from "./components/Widget";
import { useDispatch } from "react-redux";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import { AnalyticsDataItem } from "types/analytics/analyticsDataItem";

const Dashboard = () => {
  const [dataItems, setDataItems] = useState<AnalyticsDataItem[] | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "BPP Overview" });
  }, []);

  useEffect(() => {
    AnalyticsApi.getData().then((data) => {
      setDataItems(data.items);
    });
  }, []);

  return (
    <div className="view-container">
      {dataItems == null ? (
        <CircularProgress />
      ) : (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              maxHeight: "75vh",
              padding: 2
            }}>
            {dataItems.map((dataItem) => {
              const xs = dataItem.type === "Number" ? 3 : 6;
              const md = dataItem.type === "Number" ? 2 : 4;

              return (
                <Grid key={dataItem.name} item xs={xs} md={md}>
                  <Widget dataItem={dataItem} />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
};

export default Dashboard;
