import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SwitchPayPeriodApi from "../../api/SwitchPayPeriodApi";
import { downloadTimesheets } from "api/FacilitiesApi";
import { getWeekDays } from "../shared/functions/PayPeriodDateHelper";
import moment from "moment";
import PayPeriodSelector from "../shared/components/PayPeriodSelector";
import { downloadBlob } from "commons/utils";
import { useSnackbar } from "notistack";

type TimecardsDownloadDialogProps = {
  onClose: () => void;
  open: boolean;
  facilityId: string;
  toggleLoader: (a: boolean) => void;
};

const formatDate = "MM/DD/YYYY";
const TimecardsDownloadDialog = ({
  open,
  onClose,
  facilityId,
  toggleLoader,
}: TimecardsDownloadDialogProps) => {
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const snackbar = useSnackbar();

  const LoadPeriods = () => {
    SwitchPayPeriodApi.GetActivePayPeriod()
      .then((response) => {
        const periodStartDate = moment(new Date(response.periodStartDate)).startOf("day");
        const weekDays = getWeekDays(periodStartDate.add(-7, "days").format(formatDate));
        setSelectedDays(weekDays);
        setStartDate(weekDays[0]);
        setEndDate(weekDays[6]);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    LoadPeriods();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleDatesChange = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDownload = async () => {
    toggleLoader(true);
    downloadTimesheets(
      facilityId,
      moment(startDate).format(formatDate),
      moment(endDate).format(formatDate)
    )
      .then((response) => {
        downloadBlob(new Blob([response]), "FacilityTimecards.pdf");
      })
      .catch((e) => {
        if (e?.status === 404) {
          snackbar.enqueueSnackbar("No timecards found for this period.");
        }
      })
      .finally(() => {
        toggleLoader(false);
      });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={"new-timecard-dialog"}>
        <DialogTitle id="form-dialog-title">Download Timecards Files</DialogTitle>
        <DialogContent>
          <Box justifyContent={"center"} display={"flex"}>
            <PayPeriodSelector
              selectedDays={selectedDays}
              onChange={handleDatesChange}
              disabledCurrentPayPeriod={true}
              showCalendar={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownload} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimecardsDownloadDialog;
