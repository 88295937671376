import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Typography,
  Skeleton,
} from "@mui/material";
import { getManagersByFacilityAndTimesheetId } from "api/ManagersApi";
import TimesheetApi from "api/TimesheetApi";
import { useSnackbar } from "notistack";
import { ManagerForTimesheet } from "types/timesheet/timesheetManager";

type ReassignManagerDialogProps = {
  open: boolean;
  timesheetId: string;
  facilityId: string;
  onClose: () => void;
};

const ReassignManagerDialog = ({
  open,
  onClose,
  timesheetId,
  facilityId,
}: ReassignManagerDialogProps): JSX.Element => {
  const [managers, setManagers] = useState<ManagerForTimesheet[]>([]);
  const [loadingManagers, setLoadingManagers] = useState(true);
  const [selectedManager, setSelectedManager] = useState<ManagerForTimesheet>();
  const [loadingButton, setLoadingButton] = useState(false);
  const [apiSelectedManager, setApiSelectedManager] = useState<ManagerForTimesheet>();

  const { enqueueSnackbar } = useSnackbar();

  const sameManager = selectedManager === apiSelectedManager;
  const disabledSubmit = !selectedManager || loadingButton || sameManager;
  const buttonColor = disabledSubmit ? "#E0E0E0" : "#514689";

  const onChange = (event: SelectChangeEvent<string>) => {
    const email = event.target.value;
    if (email) {
      const newSelectedManager = managers.find((m) => m.email === email);
      setSelectedManager(newSelectedManager);
    }
  };

  const onConfirm = async () => {
    setLoadingButton(true);
    try {
      const response = await TimesheetApi.reasignManagerSignatureRequest(
        timesheetId,
        selectedManager
      );

      if (response.status === 400) {
        enqueueSnackbar("The manager is already assigned to this timecard.");
      } else if (response.status === 200) {
        enqueueSnackbar("Manager reassigned successfully!");
      } else {
        enqueueSnackbar("Something went wrong. Please try again later.");
      }
    } finally {
      setLoadingButton(false);
      onClose();
    }
  };

  const loadManagers = async () => {
    if (open) {
      setLoadingManagers(true);
      setSelectedManager(undefined);
      try {
        const data = await getManagersByFacilityAndTimesheetId(facilityId, timesheetId);
        setManagers(data?.filter((x) => x.enabled) ?? []);
        const selectedManager = data?.find((x) => x.isSelected);
        setSelectedManager(selectedManager);
        setApiSelectedManager(selectedManager);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingManagers(false);
      }
    }
  };

  useEffect(() => {
    loadManagers();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}>
      <DialogTitle id="alert-dialog-title">Reassign manager signature</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component={"span"}>
          <FormControl variant="outlined" sx={{ width: "100%", marginTop: "20px" }}>
            {loadingManagers ? (
              <Skeleton height={95} style={{ padding: 0 }} />
            ) : (
              <>
                <InputLabel
                  id="timecard-reassign-manager-label"
                  style={{ marginTop: 5, marginBottom: 10 }}>
                  Select Manager
                </InputLabel>
                <Select
                  labelId="timecard-reassign-manager-label"
                  id="timecard-reassign-manager"
                  value={selectedManager?.email ?? ""}
                  label="Reassign Manager"
                  fullWidth
                  onChange={onChange}
                  name="reassignManager"
                  style={{ marginTop: 5, marginBottom: 10 }}>
                  {managers.map((manager) => (
                    <MenuItem key={manager.email} value={manager.email}>
                      {manager.email}
                    </MenuItem>
                  ))}
                </Select>
                {sameManager && (
                  <Typography color="error" fontSize={13}>
                    This manager is currently assigned to this timecard.
                  </Typography>
                )}
              </>
            )}
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-around"}
              paddingTop={2}>
              <Button
                size="large"
                fullWidth
                variant="outlined"
                style={{ color: "#514689", borderColor: "#514689", marginRight: 20 }}
                onClick={onClose}>
                Cancel
              </Button>
              <Button
                size="large"
                fullWidth
                variant="contained"
                style={{
                  color: disabledSubmit ? "inherit" : "white",
                  borderColor: buttonColor,
                  backgroundColor: buttonColor,
                  marginLeft: 20,
                }}
                onClick={onConfirm}
                disabled={disabledSubmit}>
                Confirm
              </Button>
            </Box>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ReassignManagerDialog;
