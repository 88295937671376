import { msalApiFetch } from "../commons/authConfig";
import { API_URL } from "commons/environment";

export default {
  getNextPayPeriodTimesheets() {
    const url = `${API_URL}/timesheets/next-pay-period`;
    return msalApiFetch(url, {
      timeout: 600000,
      method: "GET",
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
};
