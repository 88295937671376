import React, { ReactNode, useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { BPPTableFooter } from "./components";
import {
  Column,
  DefaultFilterProps,
  FilterType,
  LookupFilterProps,
  OnChangeFilterType,
  Order,
  SaveType,
} from "./utils/Types";
import { getFilter } from "./utils/Filters";
import { stableSort, filterData, getComparator, OrderComparator } from "./utils/Misc";
import SearchBar from "../SearchBar";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { downloadBlob } from "commons/utils";
import { useSnackbar } from "notistack";

type BPPTableProps<T, U extends string> = {
  columns: Column<U, T>[];
  data: T[];
  selected?: T[];
  handleSelectedClick?: (event: React.MouseEvent<unknown>, name: string) => void;
  handleClearSelected?: () => void;
  disabledIds?: string[];
  /**
   * Redux dispatcher to save the filters, ordering and search text
   */
  handleSave?: (data: SaveType<U>) => void;
  /**
   * Redux state to save the filters, ordering and search text
   */
  savedData?: SaveType<U>;
  customSearch?: ReactNode;
  loading?: boolean;
  /**
   * Called when the clear filters button is clicked
   */
  handleClear?: () => void;
  defaultOrderingBy?: U;
  showFilters?: boolean;
  /**
   * The maximum height of the table
   * @default "78vh"
   */
  maxHeight?: string;
  clearButtonTitle?: string;
  /**
   * If true, there will be a button next to Clear Filters that will download the data with the current filters applied in a CSV format.
   * @default false
   */
  exportable?: boolean;
  /**
   * If exportable is true, this will be the name of the file that will be downloaded.
   * @default "export"
   */
  exportFileName?: string;
  /**
   * If exportable is true, this will be the maximum number of rows that will be exported.
   * @default 10_000
   */
  exportLimit?: number;
  emptyMessage?: string;
};

const BPPTable = <T extends { id: string }, U extends string>({
  columns,
  data,
  selected,
  disabledIds,
  handleSelectedClick,
  handleClearSelected,
  handleSave,
  savedData,
  customSearch,
  loading,
  handleClear,
  defaultOrderingBy,
  showFilters = true,
  maxHeight = "78vh",
  clearButtonTitle = "Clear Filters",
  exportable = false,
  exportFileName: exportableFileName = "export",
  exportLimit = 10_000,
  emptyMessage = "No records to display.",
}: BPPTableProps<T, U>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectableChecked, setSelectableChecked] = useState(false);
  const snackbar = useSnackbar();

  const getInitialFilters = (empty = true) => {
    const initialFilters = {} as FilterType<U>;
    columns.forEach((column) => {
      const value = empty ? undefined : savedData?.filters[column.id];

      initialFilters[column.id] = value ?? column.defaultFilter ?? "";
    });
    return initialFilters;
  };

  const [order, setOrder] = React.useState<"asc" | "desc">(savedData?.ordering?.direction ?? "asc");
  const [orderBy, setOrderBy] = React.useState<U | undefined>(
    (savedData?.ordering?.by as U) ?? defaultOrderingBy
  );
  const [filters, setFilters] = useState<FilterType<U>>(() => getInitialFilters(false));
  const [searchValue, setSearchValue] = useState(savedData?.searchText ?? "");
  const [comparatorValue, setComparatorValue] = useState<OrderComparator | undefined>(
    savedData?.ordering?.comparator ?? undefined
  );

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: U,
    comparator?: OrderComparator
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setComparatorValue(comparator);
  };

  const createSortHandler =
    (property: U, comparator?: OrderComparator) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property, comparator);

      const isAsc = orderBy === property && order === "asc";
      handleSave?.({
        filters: savedData?.filters ?? getInitialFilters(),
        ordering: { by: property, direction: isAsc ? "desc" : "asc", comparator: comparator },
        searchText: savedData?.searchText ?? "",
      });
    };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeFilter = (columnId: U, value: string | string[]) => {
    setPage(0);
    setFilters((prev) => {
      handleSave?.({
        filters: { ...prev, [columnId]: value },
        ordering: { by: orderBy, direction: order, comparator: comparatorValue },
        searchText: "",
      });
      return { ...prev, [columnId]: value };
    });
  };

  const handleClearFilters = () => {
    setFilters(getInitialFilters());
    setSearchValue("");
    setOrderBy(undefined);
    setOrder("asc");
    setComparatorValue(undefined);

    handleSave?.({
      filters: getInitialFilters(),
      ordering: { by: undefined, direction: "asc", comparator: undefined },
      searchText: "",
    });

    handleClear?.();

    setPage(0);
    setSelectableChecked(false);
  };

  const handleSearch = (value?: string) => {
    setSearchValue(value ?? "");
    handleSave?.({ ...savedData!, searchText: value ?? "" });
  };

  const isSelected = (id: string) => selected?.findIndex((x) => x.id === id) !== -1;

  const filteredData = filterData<T, U>(
    data,
    filters,
    searchValue,
    selectableChecked ? disabledIds : [],
    columns.map((x) => x.format),
    columns.map((x) => x.customFilter)
  );

  const exportData = () => {
    if (filteredData.length > exportLimit) {
      snackbar.enqueueSnackbar(`Your selection is too large to download.`, { variant: "error" });
      return;
    }

    const headers = columns.filter((x) => x.exportable !== false).map((column) => column.label);

    const rows = filteredData.map((row) =>
      columns
        .filter((x) => x.exportable !== false)
        .map((column) =>
          column.exportFormatter
            ? column.exportFormatter(row[column.id as keyof typeof row], row)
            : row[column.id as keyof typeof row]
        )
    );

    const blobContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const blob = new Blob([blobContent], { type: "text/csv" });

    downloadBlob(blob, `${exportableFileName}.csv`);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer style={{ maxHeight, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          {customSearch ? customSearch : <div />}
          <Box
            style={{
              margin: 10,
              flexDirection: "row",
              display: "flex",
            }}>
            <Button color="primary" style={{ marginRight: 15 }} onClick={handleClearFilters}>
              {clearButtonTitle}
            </Button>
            {exportable && (
              <IconButton
                color="primary"
                sx={{ marginRight: 2 }}
                title="Download data as CSV"
                onClick={exportData}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            )}
            {!customSearch ? (
              <SearchBar
                value={searchValue}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(newValue: any) => {
                  handleSearch(newValue);
                }}
                onCancelSearch={() => handleSearch()}
                placeholder="Search"
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {selected && (
                <TableCell padding="checkbox" style={{ backgroundColor: "white" }}>
                  <Checkbox
                    indeterminate={selected.length > 0}
                    checked={false}
                    onChange={handleClearSelected}
                    inputProps={{ "aria-label": "select all" }}
                    style={{ color: selected.length > 0 ? "#3f51b5" : "white" }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id as string}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                    backgroundColor: "white",
                  }}
                  sortDirection={orderBy === column.id ? order : false}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id, column.comparator)}>
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {selected && (
                <TableCell
                  padding="checkbox"
                  style={{
                    backgroundColor: "white",
                    zIndex: 1,
                  }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Tooltip title="Filter by exportable">
                      <Checkbox
                        onChange={() => {
                          setSelectableChecked(!selectableChecked);
                        }}
                        checked={selectableChecked}
                        inputProps={{ "aria-label": "select all" }}
                        style={{ padding: 2 }}
                      />
                    </Tooltip>
                    <Typography style={{ fontSize: 8, fontWeight: 500, padding: 0 }}>
                      Export only
                    </Typography>
                  </Box>
                </TableCell>
              )}
              {showFilters &&
                columns.map((column) => (
                  <TableCell
                    key={column.id as string}
                    style={{
                      backgroundColor: "white",
                      width: column.width,
                      zIndex: 1,
                    }}>
                    {!column.disableFilter &&
                      getFilter<U>(column, filters[column.id], handleChangeFilter)}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <></>
            ) : (
              stableSort(
                filteredData,
                orderBy ? getComparator(order, orderBy, comparatorValue) : undefined
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id + index}>
                      {selected && (
                        <TableCell padding="checkbox">
                          {!disabledIds?.find((x) => x === row.id) && (
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              style={{ color: isItemSelected ? "#3f51b5" : "" }}
                              onClick={(event) =>
                                !disabledIds?.find((x) => x === row.id) &&
                                handleSelectedClick?.(event, row.id)
                              }
                            />
                          )}
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        const value = row[column.id as keyof typeof row];

                        return (
                          <TableCell key={(row.id + column.id) as string} align={column.align}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
        {(data.length === 0 || loading) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 4,
            }}>
            {loading ? (
              <CircularProgress sx={{ position: "absolute" }} />
            ) : (
              <Typography color="#414141">{emptyMessage}</Typography>
            )}
          </Box>
        )}
      </TableContainer>
      <BPPTableFooter
        data={filteredData}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export type {
  Column,
  Order,
  FilterType,
  OnChangeFilterType,
  DefaultFilterProps,
  LookupFilterProps,
  SaveType,
};

export default BPPTable;
