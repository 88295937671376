import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TimecardForwardingDialog from "./TimecardForwardingDialog";
import JobAssignmentApi from "api/JobAssignmentApi";
import "./FileFordwardedIcon.css";
import { Tooltip } from "@mui/material";
import { FileFordwaredSatus } from "commons/Enums";

type FileFordwardedIconProps = {
  forwardingStatus: string;
  timesheetId: string;
  icon: string;
};
export default function FileFordwardedIcon(props: FileFordwardedIconProps) {
  const tooltipText =
    props.forwardingStatus === FileFordwaredSatus.Late
      ? "This timecard was uploaded after the deadline."
      : props.forwardingStatus === FileFordwaredSatus.Pending
        ? "This timecard has auto forwarding enabled."
        : props.forwardingStatus === FileFordwaredSatus.Disabled
          ? ""
          : "This timecard was uploaded before the deadline.";

  const [isTimecardForwardingStatusDialogOpen, setTimecardForwardingStatusDialogOpen] =
    useState(false);
  const [forwardingHistory, setForwardingHistory] = useState([]);

  useEffect(() => {
    setTimecardForwardingStatusDialogOpen(false);
  }, []);

  const openTimecardForwardingDialog = () => {
    setTimecardForwardingStatusDialogOpen(true);
  };

  const closeTimecardForwardingDialog = () => {
    setTimecardForwardingStatusDialogOpen(false);
  };

  const showForwardingHistory = (timesheetId: string) => {
    JobAssignmentApi.getJobAssignmentsForwardingHistory(timesheetId).then((forwardingHistory) => {
      setForwardingHistory(forwardingHistory);
      openTimecardForwardingDialog();
    });
  };

  const bodyIconButton = (
    <Tooltip title={tooltipText} placement="top">
      <Avatar
        className={`
      ${
    props.forwardingStatus === FileFordwaredSatus.Disabled
      ? "file-forwared-indicator"
      : props.forwardingStatus === FileFordwaredSatus.Pending
        ? "file-forwared-indicator pending"
        : props.forwardingStatus === FileFordwaredSatus.Late
          ? "file-forwared-indicator late"
          : "file-forwared-indicator on-time"
    }`}>
        <i className="material-icons-outlined">{props.icon}</i>
      </Avatar>
    </Tooltip>
  );

  const renderIconButton = () => {
    if (
      props.forwardingStatus === FileFordwaredSatus.Disabled ||
      props.forwardingStatus === FileFordwaredSatus.Pending
    ) {
      return (
        <IconButton className="forwarding-icon" sx={{ cursor: "default" }} size="large">
          {bodyIconButton}
        </IconButton>
      );
    }
    return (
      <IconButton
        onClick={() => showForwardingHistory(props.timesheetId)}
        className="forwarding-icon"
        size="large">
        {bodyIconButton}
      </IconButton>
    );
  };

  return (
    <div>
      {renderIconButton()}
      <TimecardForwardingDialog
        open={isTimecardForwardingStatusDialogOpen}
        onClose={closeTimecardForwardingDialog}
        forwardingHistory={forwardingHistory}
      />
    </div>
  );
}
