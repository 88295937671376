import React, { Component } from "react";
import { CircularProgress } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { withSnackbar } from "notistack";
import moment from "moment";
import Paper from "@mui/material/Paper";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import JobAssignmentApi from "../../../api/JobAssignmentApi";
import PropTypes from "prop-types";

class AssignmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = { assignments: null };
  }

  fetchAssignments(candidateId) {
    if (this.props.isAdjustment === true) {
      this.setState({ assignments: null });
      JobAssignmentApi.getDealSheetTilesForCandidateAndRecruiter(
        candidateId,
        this.props.currentRecruiterId
      )
        .then((results) => {
          this.setState({ assignments: results });
        })
        .catch((error) => {
          this.props.enqueueSnackbar("An error occurred fetching jobs for the candidate selected.");
        });
    } else {
      this.setState({ assignments: null });
      JobAssignmentApi.getDealSheetTilesForCandidate(candidateId)
        .then((results) => {
          this.setState({ assignments: results });
        })
        .catch((error) => {
          this.props.enqueueSnackbar("An error occurred fetching jobs for the candidate selected.");
        });
    }
  }

  componentDidMount() {
    this.fetchAssignments(this.props.candidateId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.candidateId !== prevProps.candidateId) {
      this.fetchAssignments(this.props.candidateId);
    }
  }

  render() {
    if (this.state.assignments == null) return <CircularProgress></CircularProgress>;

    if (this.state.assignments.length === 0)
      return (
        <Paper className="new-timecard-no-assignments">
          <AssignmentIcon></AssignmentIcon>
          <Typography>No assignments were found for {this.props.candidateName}</Typography>
        </Paper>
      );

    return (
      <>
        <Typography variant="h7">
          {this.state.assignments.length} assignment{this.state.assignments.length > 1 ? "s" : ""}{" "}
          found for {this.props.candidateName}
        </Typography>
        <List>
          {this.state.assignments.map((a) => (
            <ListItem
              key={a.dealSheet.id}
              className={
                "assignment-list-item " +
                (this.props.selectedAssignmentId === a.dealSheet.id ? "selected" : "")
              }
              alignItems="flex-start"
            >
              <Paper
                onClick={() => {
                  this.props.onAssignmentSelected(a.dealSheet.id, a.timesheetIds, a.dealSheet);
                }}
              >
                <ListItemText
                  primary={a.dealSheet.clientName}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {a.dealSheet.clientCity + ", " + a.dealSheet.clientState + " "}
                      </Typography>
                      <Typography>
                        {moment(a.dealSheet.startDate).format("MM/DD/YYYY")} -{" "}
                        {moment(a.dealSheet.endDate).format("MM/DD/YYYY")}
                      </Typography>
                    </>
                  }
                />
                <div className="assignments-list-icons">
                  {a.timesheetIds && a.timesheetIds.length > 0 && (
                    <Tooltip
                      title="This assignment already has a timecard for this pay period"
                      placement="top"
                    >
                      <Avatar>
                        <i className="material-icons-outlined">assignment</i>
                      </Avatar>
                    </Tooltip>
                  )}
                </div>
              </Paper>
            </ListItem>
          ))}
        </List>
      </>
    );
  }
}
AssignmentsList.propTypes = {
  isAdjustment: PropTypes.bool,
  currentRecruiterId: PropTypes.string,
  selectedAssignmentId: PropTypes.string,
  candidateName: PropTypes.string,
  candidateId: PropTypes.string,
  onAssignmentSelected: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(AssignmentsList);
