import React from "react";
import { FormControl, InputLabel } from "@mui/material";
import MultiSelectChip from "components/history/MultiSelectChip";

import { useAppDispatch, useAppSelector } from "hooks";
import { ADVANCED_SEARCH_UPDATE_DETAILS } from "redux/actions/actionTypes";

const DetailsSearch = () => {
  const dispatch = useAppDispatch();

  const details = useAppSelector((state) => state.advancedSearch.details);

  const detailsOptions = [
    "Pending",
    "Disable",
    "After Deadline",
    "Before Deadline",
    "Late Timecards",
    "Adjusted Timecards",
    "Adjustment Timecards",
    "No Signature Required",
    "Pending Signature",
    "Signature Signed",
    "Signature Declined",
    "Signature Completed",
    "Edit Requested",
  ];

  const handleChangeDetails = (event: any) => {
    dispatch({
      type: ADVANCED_SEARCH_UPDATE_DETAILS,
      details: event.target.value,
    });
  };

  const handleDeleteDetails = (selectedValue: any) => {
    const newOptionsSelected = details.filter((d: any) => d !== selectedValue);

    dispatch({
      type: ADVANCED_SEARCH_UPDATE_DETAILS,
      details: newOptionsSelected,
    });
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>Details</InputLabel>
      <MultiSelectChip
        label="Details"
        onOptionSelected={handleChangeDetails}
        onOptionDeletedChip={handleDeleteDetails}
        options={detailsOptions}
        optionsSelected={details}
      />
    </FormControl>
  );
};

export default DetailsSearch;
