import {
  MISSED_SHIFT_CALCULATION_MADE,
  TIMESHEET_SELECTED,
  MISSED_SHIFT_DATE_CONFIG_UPDATED,
  MISSED_SHIFT_CALCULATION_RESULTS,
} from "../actions/actionTypes";

const calculationResultsInitialValue = {
  scheduledHours: null,
  numberOfShifts: null,
  hoursPerShift: null,
  workedHours: null,
  missedHours: null,
  missedShifts: null,
  perDiemGross: null,
  housingStipendGross: null,
  hourlyRate: null,
  deduct: null,
};

const initialState = {
  calculationMade: true,
  timesheetSelected: null,
  missedShiftDeductVisible: false,
  missedShiftFeeDate: new Date(8640000000000000),
  calculationResults: calculationResultsInitialValue,
};

export default function missedShiftCalculationReducer(state = initialState, action) {
  switch (action.type) {
    case MISSED_SHIFT_CALCULATION_MADE: {
      let newState = {};
      if (action.patchedState.calculationMade === false) {
        newState = Object.assign(newState, state, {
          ...action.patchedState,
          calculationResults: calculationResultsInitialValue,
        });
      } else {
        newState = Object.assign(newState, state, { ...action.patchedState });
      }
      return newState;
    }
    case TIMESHEET_SELECTED: {
      const isTimesheetAfter =
        new Date(action.patchedState.timesheetSelected.timesheetStartDate) >=
        state.missedShiftFeeDate;
      const newState = Object.assign({}, state, {
        missedShiftDeductVisible: isTimesheetAfter,
        ...action.patchedState,
      });
      return newState;
    }
    case MISSED_SHIFT_DATE_CONFIG_UPDATED: {
      const stringDate = action.featuresFlags.find((ff) => ff.name == "missedShiftFee").configDate;
      const date = new Date(stringDate);

      const newState = Object.assign({}, state, { missedShiftFeeDate: date });
      return newState;
    }
    case MISSED_SHIFT_CALCULATION_RESULTS: {
      const newState = Object.assign({}, state, { calculationResults: action.results });
      return newState;
    }
    default:
      return state;
  }
}
