import React from "react";
import "./App.css";
import { SnackbarProvider } from "notistack";
import PersistentDrawerLeft from "./customdrawer";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { msalInstance, loginRequest, errorCodes } from "./commons/authConfig";
import { AuthError, InteractionType, RedirectRequest } from "@azure/msal-browser";
import { BackdropLoading } from "shared/backdropLoading/BackdropLoading";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "commons/appInsights/ApplicationInsightsService";
import { EventType } from "@azure/msal-browser";
import { URL_ADAL_TENANT } from "commons/environment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "styles/CustomThemeProvider";
import { ThemeProvider } from "@mui/system";

function App(): JSX.Element {
  const isEnabled = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test";
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      //@ts-ignore
      event.payload.account
    ) {
      //@ts-ignore
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
      if (event.error?.message.includes(errorCodes.forgotPassword)) {
        const authority = { authority: URL_ADAL_TENANT };
        msalInstance.loginRedirect(authority as RedirectRequest);
      }
    } else if (
      event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
      !(event.error as AuthError)?.errorCode.includes("no_network_connectivity")
    ) {
      msalInstance.logoutRedirect();
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isEnabled}
        isVerboseMode={false}
        loadingComponent={<BackdropLoading />}>
        <MsalProvider instance={msalInstance}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SnackbarProvider maxSnack={3}>
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  loadingComponent={BackdropLoading}
                  authenticationRequest={loginRequest}>
                  <AuthenticatedTemplate>
                    <PersistentDrawerLeft />
                  </AuthenticatedTemplate>
                </MsalAuthenticationTemplate>
              </SnackbarProvider>
            </LocalizationProvider>
          </AppInsightsContext.Provider>
        </MsalProvider>
      </CacheBuster>
    </ThemeProvider>
  );
}

export default App;
