import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "features";

export default {
  // Api to get all features
  getFeatures() {
    const url = `${baseUrl}/${endpoint}`;
    return msalApiFetch(url).then((results) => {
      if (!results.ok) throw results;
      return results.json();
    });
  },
};
