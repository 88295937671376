import React, { useCallback } from "react";
import MultiSelectChip from "./MultiSelectChip";
import { useDispatch } from "react-redux";
import { FormControl, InputLabel } from "@mui/material";
import { useAppSelector } from "hooks";
import { ADVANCED_SEARCH_UPDATE_STATES } from "redux/actions/actionTypes";

type StatusSearchProps = {
  states: string[];
};

export default function StatusSearch(props: StatusSearchProps) {
  const dispatch = useDispatch();
  const states = useAppSelector((state) => state.advancedSearch.states);

  const handleChange = useCallback(
    (event) => {
      dispatch({
        type: ADVANCED_SEARCH_UPDATE_STATES,
        states: event.target.value,
      });
    },
    [dispatch]
  );

  const handleDelete = (value: string) => {
    dispatch({
      type: ADVANCED_SEARCH_UPDATE_STATES,
      states: states.filter((state) => state != value),
    });
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>Status</InputLabel>
      <MultiSelectChip
        label="Status"
        options={props.states}
        optionsSelected={states}
        onOptionSelected={handleChange}
        onOptionDeletedChip={handleDelete}
      />
    </FormControl>
  );
}
