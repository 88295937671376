import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type InformationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  dialogTextList: string[];
  dialogTitle: string;
};

const InformationDialog = (props: InformationDialogProps) => {
  const { isOpen, onClose, dialogTextList, dialogTitle } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <ul>
            {dialogTextList.map((item) => (
              <li key={item}>
                <DialogContentText id="alert-dialog-description">{item}</DialogContentText>
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InformationDialog;
