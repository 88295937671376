import React from "react";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

type WidgetReloadButtonProps = {
  onReload: () => void;
};

const WidgetReloadButton = ({ onReload }: WidgetReloadButtonProps) => {

  const handleClick = () => onReload();

  return (
    <Button onClick={handleClick}>
      <RefreshIcon sx={{ color: "#474486" }} />
    </Button>
  );
};

export default WidgetReloadButton;
