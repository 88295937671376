import React from "react";
import moment from "moment";
import { Typography, List, ListItem, ListItemText, CircularProgress, Paper } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";

type AssignmentsListSelectorProps = {
  assignments: any[];
  selectedAssignment?: any;
  onAssignmentSelected: (assignment: any) => void;
  filteredName: string|null;
};

const AssignmentsListSelector = (props: AssignmentsListSelectorProps) => {

  return (
    <>
      {props.assignments === null && <CircularProgress></CircularProgress>}
      {props.assignments.length === 0 && 
                <Paper className="new-timecard-no-assignments">
                  <AssignmentIcon></AssignmentIcon>
                  <Typography>No assignments were found for {props.filteredName}</Typography>
                </Paper>
      }
      {props.assignments.length > 0 && <>
        <Typography variant="subtitle2" style={{ fontSize: "larger" }}>
          {props.assignments.length} assignment{props.assignments.length > 1 ? "s" : ""}{" "}
                    found for {props.filteredName}
        </Typography>
        <List>
          {props.assignments.map((a) => (
            <ListItem
              key={a.id}
              className={
                "assignment-list-item " +
                        (props.selectedAssignment?.id === a.id ? "selected" : "")
              }
              alignItems="flex-start">
              <Paper
                onClick={() => {
                  props.onAssignmentSelected(a);
                }}>
                <ListItemText
                  primary={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography component="span" variant="subtitle2" style={{ fontSize: "medium" }} color="textPrimary">
                        {a.candidateName}
                      </Typography>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {a.laborEdgeJobId}
                      </Typography>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {a.clientName}
                      </Typography>
                    </div>

                  }
                  secondary={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography component="span" color="textPrimary">
                        {a.clientCity + ", " + a.clientState + " "}
                      </Typography>
                      <Typography component="span">
                        {moment(a.startDate).format('MM/DD/YYYY')} -{" "}
                        {moment(a.endDate).format('MM/DD/YYYY')}
                      </Typography>
                    </div>
                  }
                />
              </Paper>
            </ListItem>
          ))}
        </List>
      </>}
    </>
  );
};

export default AssignmentsListSelector;