import { Box, CircularProgress, TextField, Button, Typography } from "@mui/material";
import "./Managers.css";
import React, { useEffect, useState } from "react";
import { Manager } from "types/facility/managers";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import { useSnackbar } from "notistack";
import { ManagerCard } from "components/facilities/managers/ManagerCard";
import AddUpdateManagerContent from "./AddUpdateManagerContent";
import { el } from "date-fns/locale";

type ManagerListProps = {
  /**
   * The entity type, either contract or facility
   */
  entity: "contract" | "facility";
  /**
   * The id of the facility or the id of the contract
   */
  entityId: string;
  /**
   * Updates an exisitng manager
   * @param entityId The id of the facility or the id of the contract
   * @param manager manager information
   * @param oldManagerEmail old manager email
   */
  updateManager: (entityId: string, manager: Manager, oldManagerEmail: string) => Promise<any>;
  getManagers: (entityId: string) => Promise<Manager[]>;
  createManager: (entityId: string, manager: Manager) => Promise<any>;
  mustHaveManager: boolean;
  setValidManagerList: (valid: boolean) => void;
};

const filterBySearchTerm = (list: Manager[], searchTerm: string) => {
  searchTerm = searchTerm.trim().toLowerCase();
  const filteredList = list.filter((item) => {
    const fullName = item.name.toLowerCase();
    const email = item.email.toLowerCase();
    return fullName.includes(searchTerm) || email.includes(searchTerm);
  });
  return filteredList;
};

export const ManagerList = ({ entity, entityId, updateManager, getManagers, createManager, mustHaveManager, setValidManagerList }: ManagerListProps) => {
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManager, setSelectedManager] = useState<Manager>();
  const [loadingManagers, setLoadingManagers] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isToggleOpen, setIsToggleOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedManager(undefined);
  };

  const closeToggle = () => {
    setIsToggleOpen(false);
    setSelectedManager(undefined);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value ?? "");
  };

  const filteredUsers = filterBySearchTerm(managers, searchText);

  const refreshManagers = async () => {
    setLoadingManagers(true);
    setManagers([]);
    getManagers(entityId)
      .then((data) => {        
        setManagers(data);
        if (data.length > 0 && mustHaveManager){
          if (data.some((manager) => manager.enabled))
          {
            setValidManagerList(true);
          }
          else
          {
            setValidManagerList(false);
          }
        }        
        if ((data.length === 0) && mustHaveManager){
          setValidManagerList(false);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoadingManagers(false);
      });
  };

  const handleEditManager = (manager: Manager) => {
    setSelectedManager(manager);
    setIsDialogOpen(true);
  };

  const handleToggleManager = (manager: Manager) => {
    setSelectedManager(manager);
    setIsToggleOpen(true);
  };

  const handleConfirmToggleManager = async () => {
    if (!selectedManager) return;
    const toggle = !selectedManager.enabled;
    const validManagerList = managers.some((manager) => manager.enabled && manager.email !== selectedManager.email);
    if (!validManagerList && !toggle && mustHaveManager)
    {
      enqueueSnackbar("At least one manager must be active");
    }
    else
    {
      const response = await updateManager(
        entityId,
        { ...selectedManager, enabled: toggle },
        selectedManager.email
      );
  
      if (response.status > 201) {
        enqueueSnackbar("Something went wrong");
        return;
      }
      enqueueSnackbar(`Manager ${toggle ? "activated" : "deactivated"} succesfully`);
      await refreshManagers();
    }    
  };

  useEffect(() => {
    refreshManagers();
  }, []);

  return (
    <div className="facility-managers-container">
      <TextField
        label="Search Manager"
        variant="outlined"
        size="small"
        value={searchText}
        onChange={onSearch}
      />

      <Button
        onClick={openDialog}
        style={{ fontWeight: "bold", color: "#514689", paddingLeft: 30, marginTop: 4 }}>
        ADD NEW MANAGER
      </Button>

      {filteredUsers.map((manager) => {
        return (
          <div key={manager.email}>
            <ManagerCard
              manager={manager}
              handleOnEdit={handleEditManager}
              handleToggleManager={handleToggleManager}
            />
          </div>
        );
      })}

      {loadingManagers && (
        <Box mt={3} alignItems="center" display="flex" ml={5}>
          <CircularProgress size={30} />
        </Box>
      )}
      {!loadingManagers && filteredUsers.length === 0 && (
        <div className="no-managers">No managers selected for this {entity} yet.</div>
      )}
      <ConfirmDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onConfirm={closeDialog}
        content={
          <AddUpdateManagerContent
            handleOnClose={closeDialog}
            entityId={entityId}
            oldManagerData={selectedManager}
            refreshManagers={refreshManagers}
            createManager={createManager}
            updateManager={updateManager}
          />
        }
        dialogTitle={!selectedManager ? "Add Manager" : "Update Manager"}
        displayCancelButton={false}
        displayConfirmButton={false}
        centerTitle={true}
        dividerEnabled
      />

      <ConfirmDialog
        isOpen={isToggleOpen}
        onClose={closeToggle}
        onConfirm={handleConfirmToggleManager}
        content={
          <Box mt={2}>
            <Typography>Are you sure?</Typography>
          </Box>
        }
        dialogTitle={selectedManager?.enabled ? "Deactivate manager" : "Activate manager"}
        centerTitle={true}
        dividerEnabled
      />
    </div>
  );
};
