import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { withSnackbar } from "notistack";
import _ from "lodash";
import CandidatesApi from "../../../api/CandidatesApi";
import PropTypes from "prop-types";

function renderInputComponent(inputProps) {
  const { inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part) => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

class CandidateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      single: "",
      suggestions: [],
    };
  }

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  handleChange =
    (name) =>
    (event, { newValue }) => {
      this.setState((state) => {
        return {
          ...state,
          [name]: newValue,
        };
      });
    };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.props.isAdjustment === true) {
      CandidatesApi.getAllCandidatesByRecuiter(value, this.props.currentRecruiterId)
        .then((results) => {
          this.setState({
            suggestions: results.map((r) => {
              return { value: r.id, label: r.firstName + " " + r.lastName };
            }),
          });
        })
        .catch((error) => {
          this.props.enqueueSnackbar("An error occurred searching for travelers.");
        });
    } else {
      CandidatesApi.getAllCandidates(value)
        .then((results) => {
          this.setState({
            suggestions: results.map((r) => {
              return { value: r.id, label: r.firstName + " " + r.lastName };
            }),
          });
        })
        .catch((error) => {
          this.props.enqueueSnackbar("An error occurred searching for travelers.");
        });
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.onSelected(suggestion);
  };

  debouncedFetch = _.debounce(this.handleSuggestionsFetchRequested, 1000);

  render() {
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.debouncedFetch,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected: this.onSuggestionSelected,
    };

    return (
      <div className={"candidate-selector-root"}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            id: "react-autosuggest-simple",
            label: "Traveler",
            placeholder: "Search a traveler by name",
            value: this.state.single,
            onChange: this.handleChange("single"),
          }}
          theme={{
            container: "candidate-selector-container",
            suggestionsContainerOpen: "candidate-selector-container-open",
            suggestionsList: "candidate-selector-suggestions-list",
            suggestion: "candidate-selector-suggestion",
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

CandidateSelector.propTypes = {
  isAdjustment: PropTypes.bool,
  currentRecruiterId: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(CandidateSelector);
