import React, { ChangeEvent } from "react";
import { Box, FormControl, TextField, Typography } from "@mui/material";

type NextPayPeriodRejectContentProps = {
  textValue: string;
  handleOnTextChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const NextPayPeriodRejectContent = ({
  textValue,
  handleOnTextChange,
}: NextPayPeriodRejectContentProps) => {
  const textInputCharactersLimit = 200;
  return (
    <Box>
      <Typography>Do you want to reject this timecard?</Typography>
      <FormControl fullWidth style={{ paddingTop: "25px" }}>
        <TextField
          label="Add notes"
          value={textValue}
          multiline
          rows={3}
          variant="outlined"
          onChange={handleOnTextChange}
          inputProps={{ maxLength: textInputCharactersLimit }}
          fullWidth
        />
      </FormControl>
    </Box>
  );
};

export default NextPayPeriodRejectContent;
