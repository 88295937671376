import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, TextareaAutosize, Typography } from "@mui/material";
import NotesPanel from "./NotesPanel";
import { Note } from "types/notes";
import NoteTypeResolver from "./NoteTypeResolver";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

type TimecardNotesProps = {
  note?: string;
  onNoteChange?: (note: string) => void;
  noteNotifyTraveler?: boolean;
  onNotifyTravelerChange?: (sendToUser: boolean) => void;
  notes?: Note[];
  inputAllowed?: boolean;
  isReadOnly?: boolean;
  noteTypeFilter: string[];
  showNotes?: boolean;
};

const TimecardNotes = ({
  note,
  onNoteChange,
  notes,
  isReadOnly,
  inputAllowed = true,
  noteTypeFilter,
  onNotifyTravelerChange,
  noteNotifyTraveler,
  showNotes = true
}: TimecardNotesProps): JSX.Element => {
  const [text, setText] = useState<string>(note || "");
  const [notifyTraveler, setNotifyTraveler] = useState(noteNotifyTraveler || false);

  useEffect(() => {
    setText(note || "");
  }, [note]);

  useEffect(() => {
    setNotifyTraveler(noteNotifyTraveler || false);
  }, [noteNotifyTraveler]);

  const handleNoteChange = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    const newText = event.currentTarget.value;
    setText(newText);
    if (typeof onNoteChange === "function") {
      onNoteChange(newText);
    }
  };

  const isExpanded = (notes: Note[] | undefined, notesFilter: string): boolean => {
    if (!notes) {
      return false;
    }
    const filteredNotes = notes.filter((note) => note.noteType === notesFilter);
    return filteredNotes.length > 0;
  };

  const handleSendToUserChange = (
    event: React.FormEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    setNotifyTraveler(checked);
    if (typeof onNotifyTravelerChange === "function") {
      onNotifyTravelerChange(checked);
    }
  };

  return (
    <>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: "20px",
          marginTop: "20px",
        }}>
        Notes{" "}
      </Typography>
      {inputAllowed && (
        <div className="note-section-textarea-container">
          <TextareaAutosize
            value={text}
            disabled={isReadOnly}
            onChange={handleNoteChange}
            style={{ width: "100%", resize: "none", marginTop: "15px" }}
            minRows={5}
            maxRows={8}
            placeholder="Personal Note"
            maxLength={250}
          />
          <p className="note-characters-left" style={{ marginBottom: 0 }}>
            {250 - (text ? text.length : 0)}
          </p>
          <CanRenderChildren permissionName="canNotifyToTraveler">
            <Box display={"flex"} justifyContent={"flex-end"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifyTraveler}
                    name="sendToUser"
                    color="primary"
                    style={{
                      transform: "scale(0.9)",
                      paddingRight: 0,
                    }}
                    onChange={handleSendToUserChange}
                  />
                }
                label={<Typography style={{ fontSize: 14 }}>Notify traveler</Typography>}
              />
            </Box>
          </CanRenderChildren>
        </div>
      )}
      {
        showNotes && (<Box display={"flex"} flexDirection={"column"}>
          {noteTypeFilter.map((noteType, index) => {
            const key = `${index}`;
            return (
              <NotesPanel
                key={key}
                title={NoteTypeResolver.getNoteSourceFromNoteType(noteType)}
                notes={notes ?? []}
                notesFilter={noteType}
                isExpanded={isExpanded(notes, noteType)}
              />
            );
          })}
        </Box>)
      }
    </>
  );
};
export default TimecardNotes;
