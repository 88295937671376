import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getFileType } from "commons/utils";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

type ActionType = "Upload" | "Update" | "Add Files";

type TimecardDetailUploadBoxProps = {
  title: string;
  disabled: boolean;
  actionType: ActionType;
  onAction: (files: File[]) => void;
  numberOfFiles: number;
  files: FileList;
  onClose: () => void;
};

const acceptedFiles = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "application/pdf",
  "image/heic",
  "image/tiff",
];

export const TimecardDetailUploadBox = ({
  title,
  disabled,
  actionType,
  onAction,
  numberOfFiles,
  files,
  onClose,
}: TimecardDetailUploadBoxProps) => {
  const actionColor = disabled ? "#53565A" : actionType !== "Upload" ? "#FF6060" : "primary";
  const [error, setError] = useState(false);

  const snackbar = useSnackbar();

  const handleClick = () => {
    if (disabled) return;

    if (files.length > numberOfFiles) {
      setError(true);
      return;
    }
    setError(false);

    for (const file of files) {
      if (file.size > 10000000) {
        snackbar.enqueueSnackbar("Maximum file size is 10MB.");
        onClose();
        return;
      }
      const currentFileType = getFileType(file);

      if (acceptedFiles.filter((format) => format === currentFileType).length < 1) {
        snackbar.enqueueSnackbar("One of your files is not allowed. Select an image or pdf file.");
        onClose();
        return;
      }
    }

    onAction(Array.from(files));

    onClose();
  };

  return (
    <Box sx={{ my: 2 }}>
      <Button
        fullWidth
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          border: "1px solid #514689",
          borderRadius: 2,
          ":hover": {
            backgroundColor: disabled ? "" : "#EEECF3",
          },
          cursor: disabled ? "" : "pointer",
          backgroundColor: disabled ? "#f0f0f0" : "",
        }}
        disabled={disabled}
        onClick={handleClick}>
        <Typography
          color={disabled ? "#53565A" : "primary"}
          fontWeight="semibold"
          sx={{ textTransform: "uppercase" }}>
          {title}
        </Typography>
        {disabled ? (
          <Typography color="#53565A" fontSize={12} sx={{ textTransform: "none" }}>
            This file is download only at this point
          </Typography>
        ) : (
          <Box />
        )}
        <Typography color={actionColor} fontWeight="semibold" sx={{ textTransform: "uppercase" }}>
          {actionType}
        </Typography>
      </Button>
      {error && (
        <Typography color="error" fontSize={11}>
          You cannot upload more than {numberOfFiles} file{numberOfFiles > 1 ? "s" : ""}
        </Typography>
      )}
    </Box>
  );
};
