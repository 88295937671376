import * as actionTypes from "../actions/actionTypes";

export function bppUsersTableReducer(state: any = null, action: any) {
  switch (action.type) {
    case actionTypes.BPPUSERS_TABLE_CHANGED:
      return { ...state, filters: action.filters };
    default:
      return { ...state };
  }
}
