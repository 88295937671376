import React, { useState } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import TimecardFileIcon from "components/shared/components/TimecardFileIcon";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { TimecardExpensesDialog } from "./TimecardExpensesDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type TravelerTimecardsHistoryProps = {
  timecards: any[];
  createNewTab: (timesheet: any) => void;
};

const TravelerTimecardsHistory = ({ timecards, createNewTab }: TravelerTimecardsHistoryProps) => {
  const [expenseLinkOpen, setExpenseLinkOpen] = useState({ open: false, timesheetId: "" });

  const toggleExpenseLink = (timesheetId: string) => {
    setExpenseLinkOpen({ open: !expenseLinkOpen.open, timesheetId });
  };

  const formatDate = (date: string) => {
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const loadColumns = [
    {
      title: "Pay Period",
      field: "payPeriod",
      render: (rowData: any) => {
        const dates = `${formatDate(rowData.timeSheetStartDate)} - ${formatDate(
          rowData.timeSheetEndDate
        )}`;
        return rowData.timecardType === "Did Not Work" ? (
          <Typography>{dates}</Typography>
        ) : (
          <Typography
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
            onClick={() => createNewTab(rowData)}>
            {dates}
          </Typography>
        );
      },
      customSort: (a: any, b: any) =>
        moment(a.timeSheetStartDate).date() - moment(b.timeSheetStartDate).date(),
    },
    {
      title: "Paid",
      field: "paid",
      type: "currency",
      align: "left",
      render: (rowData: any) => <Typography>${rowData.paid.toFixed(2)}</Typography>,
      customSort: (a: any, b: any) => a.paid - b.paid,
    },
    {
      title: "Billed",
      field: "billed",
      type: "currency",
      align: "left",
      render: (rowData: any) => <Typography>${rowData.billed.toFixed(2)}</Typography>,
      customSort: (a: any, b: any) => a.billed - b.billed,
    },
    {
      title: "Hours",
      field: "hours",
      align: "left",
      render: (rowData: any) => {
        return <Typography>{rowData.hours.toFixed(2)}</Typography>;
      },
      customSort: (a: any, b: any) => a.hours - b.hours,
    },
    {
      title: "Timecard Type",
      field: "timecardType",
      render: (rowData: any) => <Typography>{rowData.timecardType}</Typography>,
    },
    {
      title: "Expenses",
      field: "expenses",
      type: "currency",
      align: "left",
      render: (rowData: any) => {
        if (rowData.expenses > 0) {
          return (
            <Tooltip title="Expense Details">
              <Button
                variant="text"
                onClick={() => toggleExpenseLink(rowData.timesheetId)}
                sx={{ padding: 1, minWidth: 0 }}>
                ${rowData.expenses}
              </Button>
            </Tooltip>
          );
        }
        return <Typography sx={{ padding: 1 }}>${rowData.expenses}</Typography>;
      },
      customSort: (a: any, b: any) => a.expenses - b.expenses,
    },
    {
      title: "Call off hours",
      field: "callOffs",
      align: "left",
      render: (rowData: any) => {
        const callOffsDetails = (
          <>
            <Typography variant="subtitle2">Hospital: {rowData.callOffHospital}</Typography>
            <Typography variant="subtitle2">Other: {rowData.callOffOther}</Typography>
            <Typography variant="subtitle2">Personal: {rowData.callOffPersonal}</Typography>
            <Typography variant="subtitle2">Sick: {rowData.callOffSick}</Typography>
          </>
        );

        const callOffsTotal =
          rowData.callOffHospital +
          rowData.callOffOther +
          rowData.callOffPersonal +
          rowData.callOffSick;
        return (
          <Box display={"flex"} gap={1}>
            <Typography>{callOffsTotal}</Typography>
            {callOffsTotal > 0 && (
              <Tooltip title={callOffsDetails} placement="top">
                <InfoOutlinedIcon />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      title: "Timecard File",
      field: "timecardUploadId",
      sorting: false,
      filtering: false,
      align: "center",
      render: (rowData: any) => (
        <div className="history-table-icons-wrap">
          <TimecardFileIcon
            timecardUploadId={rowData.timecardUploadId}
            uploadSource={rowData.timecardUploadSource}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: "grid" }}>
        <MaterialTable
          style={{ overflow: "auto" }}
          columns={loadColumns as never[]}
          data={timecards}
          options={{
            sorting: true,
            filtering: false,
            showTitle: false,
            search: false,
            maxBodyHeight: "60vh",
            pageSize: 15,
            pageSizeOptions: [15, 30, 45],
            exportButton: false,
            toolbar: false,
          }}
        />
      </div>

      <TimecardExpensesDialog
        expenseLinkOpen={expenseLinkOpen}
        toggleExpenseLink={() => toggleExpenseLink("")}
      />
    </>
  );
};

export default TravelerTimecardsHistory;
