import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";

type UpdatedByProps = {
  name: string;
  date: string;
};

export const UpdatedBy = ({ name, date }: UpdatedByProps) => {
  return (
    <Box
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        width: '100%',
        marginTop: 5
      }}>
      <EditIcon style={{ width: "0.8rem", height: "0.8rem", color: "#7D7D7D", marginRight: 5 }} />
      <Typography style={{ fontSize: "0.7rem", color: "#414141", fontWeight: 400 }}>
        Updated by {name}: {moment(new Date(date)).format("MMM D - LT")}
      </Typography>
    </Box>
  );
};
